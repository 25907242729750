import React from "react"
import PropTypes from "prop-types"
import { Provider } from "react-redux"
import { Router } from "react-router-dom"
import Routes from "routes"

const Root = ({ store, history }) => (
  <Provider store={store}>
    <Router history={history}>
      <Routes />
    </Router>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default Root
