import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.div`
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  ${props => (props.children ? "& > *" : "&")} {
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: ${props => props.effectColor};
    background: linear-gradient(
      to right,
      ${props => props.baseColor} 8%,
      ${props => props.effectColor} 18%,
      ${props => props.baseColor} 33%
    );
    background-size: 800px 104px;
  }
`

class AnimatedBgEffects extends React.PureComponent {
  render() {
    return <Wrapper {...this.props}>{this.props.children}</Wrapper>
  }
}

export default AnimatedBgEffects

AnimatedBgEffects.propTypes = {
  baseColor: PropTypes.string,
  effectColor: PropTypes.string
}

AnimatedBgEffects.defaultProps = {
  baseColor: "#dedede",
  effectColor: "#cccccc"
}
