import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M981.333 512c0 259.206-210.128 469.333-469.333 469.333s-469.333-210.128-469.333-469.333c0-259.206 210.128-469.333 469.333-469.333s469.333 210.128 469.333 469.333z",
    "M810.667 497.338l-66.25-75.481 9.232-99.918-98.017-22.264-51.316-86.342-92.315 39.641-92.315-39.641-51.316 86.342-98.017 21.993 9.232 99.918-66.25 75.753 66.25 75.481-9.232 100.189 98.017 22.264 51.316 86.342 92.315-39.913 92.315 39.641 51.316-86.342 98.017-22.264-9.232-99.918 66.25-75.481zM539.152 633.096h-54.303v-54.303h54.303v54.303zM539.152 524.49h-54.303v-162.909h54.303v162.909z"
  ],
  a: [
    {
      fill: "rgb(255, 255, 255)",
      stroke: "rgb(86, 175, 0)",
      strokeLinejoin: "miter",
      strokeLinecap: "butt",
      strokeMiterlimit: "4",
      strokeWidth: 42.666666666666664
    },
    { fill: "rgb(86, 175, 0)" }
  ]
}
export default class Recommended extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
