import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M42.667 481.778h336.512c19.243-57.2 71.339-97.778 132.821-97.778s113.579 40.578 132.821 97.778h336.512v97.778h-336.512c-19.243 57.2-71.339 97.778-132.821 97.778s-113.579-40.578-132.821-97.778h-336.512v-97.778z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }]
}
export default class Stop extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
