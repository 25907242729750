import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M97.28 85.333l-54.613 54.187 62.72 62.72c-18.347 11.093-36.693 23.467-54.187 36.693l76.8 102.4c22.613-17.067 46.080-32 70.827-45.653l95.147 95.147c-31.573 14.507-61.867 32-89.173 52.907l76.8 102.4c33.28-24.747 70.827-43.947 110.933-56.747l108.8 107.947c-53.333 2.987-102.827 21.333-142.933 51.2l153.6 204.8 104.96-139.52 139.947 139.52 53.76-54.613-713.387-713.387zM512 85.333c-91.733 0-179.2 16.213-260.267 45.653l101.973 102.4c51.627-13.227 103.68-20.053 158.293-20.053 144.213 0 277.333 47.36 384 128l76.8-102.4c-128.427-96.427-287.573-153.6-460.8-153.6v0zM512 341.333c-16.213 0-32 0-47.787 2.133l136.107 136.533c52.053 11.947 100.693 34.987 142.080 66.133l76.8-102.4c-85.333-64.427-192-102.4-307.2-102.4z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }]
}
export default class NoWifi extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
