import React from "react"
import PropTypes from "prop-types"
import Range from "rc-slider/lib/Range"
import "rc-slider/assets/index.css"
import { Clearfix } from "react-bootstrap"
import MediaQuery from "react-responsive"
import FlatButton from "material-ui/FlatButton"
import { minutesToDuration } from "utils/filtersHelper"
import { withTranslation } from "react-i18next"
import muiThemeable from "material-ui/styles/muiThemeable"
import * as constants from "config/constants"
import _ from "underscore"

class SliderFilter extends React.PureComponent {
  handleChange = value => {
    if (value[1] === 1440) {
      value[1] = 1439
    }
    this.props.onChange(value)
  }

  handleApplyFilter = () => {
    this.props.onApplyFilter()
  }

  selected = value => {
    const { selected } = this.props
    return _.isEqual(selected, value)
  }

  render() {
    const { selected, muiTheme, t } = this.props

    const railStyle = { backgroundColor: "#cccccc", height: 2 }
    const trackStyle = { backgroundColor: muiTheme.palette.primary1Color, height: 2 }
    const handleStyle = { borderColor: muiTheme.palette.primary1Color, height: 12, width: 12 }

    const btnStyle = { height: 32, lineHeight: "32px", minWidth: 0, marginRight: 8, border: "1px solid #eaeaea" }
    const btnStyleSelected = { ...btnStyle, border: `1px solid ${muiTheme.palette.primary1Color}` }
    const btnLabelStyle = { fontSize: 12, paddingLeft: 10, paddingRight: 10 }
    const btnApplyLabelStyle = { fontSize: 14, paddingLeft: 0, paddingRight: 0 }

    const predefinedOptions = [
      { label: t("Madrugada"), value: [0, 5 * 60] },
      { label: t("Mañana"), value: [5 * 60, 12 * 60] },
      { label: t("Tarde"), value: [12 * 60, 19 * 60] },
      { label: t("Noche"), value: [19 * 60, 1439] }
    ]

    return (
      <div>
        <Clearfix style={{ marginBottom: 8 }}>
          <span style={{ float: "left" }}>{minutesToDuration(selected[0])}</span>
          <span style={{ float: "right" }}>{minutesToDuration(selected[1])}</span>
        </Clearfix>
        <div style={{ padding: "0 8px", marginBottom: 16 }}>
          <Range
            min={0}
            max={1439}
            value={selected}
            step={30}
            railStyle={railStyle}
            trackStyle={[trackStyle]}
            handleStyle={[handleStyle, handleStyle]}
            allowCross={false}
            pushable={1}
            onChange={this.handleChange}
          />
        </div>

        {predefinedOptions.map((option, i) => {
          const style = this.selected(option.value) ? btnStyleSelected : btnStyle
          return (
            <FlatButton
              key={i}
              label={option.label}
              labelStyle={btnLabelStyle}
              style={i === predefinedOptions.length - 1 ? { ...style, marginRight: 0 } : style}
              onClick={() => {
                this.handleChange(option.value)
              }}
            />
          )
        })}

        <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
          {/* NOT MOBILE */}
          <FlatButton
            style={{ marginTop: 10 }}
            labelStyle={btnApplyLabelStyle}
            primary
            label={t("Aplicar filtro")}
            onClick={this.handleApplyFilter}
          />
        </MediaQuery>
      </div>
    )
  }
}

export default muiThemeable()(withTranslation()(SliderFilter))

SliderFilter.propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onApplyFilter: PropTypes.func.isRequired
}

SliderFilter.defaultProps = {
  selected: [0, 1439]
}
