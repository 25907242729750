import React from "react"
import { Row, Col } from "react-bootstrap"
import FlightIcon from "material-ui/svg-icons/maps/flight"
import AnimatedBgEffect from "components/commons/styled/AnimatedBgEffect"

class FlightsResultsPlaceholder extends React.PureComponent {
  render() {
    /* TODO: Comentamos esto por el momento ya que simulan el switch de currency y el order by select que los tenemos ocultos
    <Clearfix>
      <AnimatedBgEffect style={{ float: 'right', width: 153, height: 32, borderRadius: 1 }} />
      <AnimatedBgEffect style={{ float: 'right', width: 112, height: 32, borderRadius: 1, marginRight: 8 }} />
    </Clearfix>*/

    return (
      <div>
        {[...Array(5)].map((x, i) => (
          <div style={{ backgroundColor: "#f0f0f0", padding: 16, marginTop: 16, borderRadius: 10 }} key={i}>
            <Row>
              <Col md={9}>
                {[...Array(2)].map((y, j) => (
                  <div style={{ marginTop: j === 1 ? 16 : 0 }} key={j}>
                    <Row>
                      <Col xs={1}>
                        <FlightIcon style={{ transform: "rotate(90deg)", width: 20, height: 20 }} color={"#ccc"} />
                      </Col>
                      <Col xs={3}>
                        <AnimatedBgEffect style={{ width: 32, height: 16, borderRadius: 8, float: "right" }} />
                      </Col>
                      <Col xs={1} />
                      <Col xs={3}>
                        <AnimatedBgEffect style={{ width: 32, height: 16, borderRadius: 8 }} />
                      </Col>
                      <Col xs={4}>
                        <AnimatedBgEffect style={{ width: 136, height: 16, borderRadius: 8, float: "right" }} />
                      </Col>
                    </Row>
                    {[...Array(Math.floor(Math.random() * 5 + 2))].map((z, k) => (
                      <div
                        key={k}
                        style={{
                          height: 48,
                          backgroundColor: "#ffffff",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.14)"
                        }}
                      />
                    ))}
                  </div>
                ))}
              </Col>
              <Col md={3}>
                <div style={{ paddingTop: 80, textAlign: "center" }}>
                  <AnimatedBgEffect
                    style={{
                      height: 16,
                      width: 101,
                      maxWidth: "60%",
                      display: "inline-block",
                      borderRadius: 8
                    }}
                  />
                  <br />
                  <AnimatedBgEffect
                    style={{
                      height: 16,
                      width: 209,
                      maxWidth: "90%",
                      display: "inline-block",
                      borderRadius: 8,
                      marginTop: 48
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    )
  }
}

export default FlightsResultsPlaceholder
