import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import MuiTextField from "material-ui/TextField"
import muiThemeable from "material-ui/styles/muiThemeable"

const Wrapper = styled.div`
  display: inline-block;
  border: 1px solid ${props => props.theme.borderColor};
  ${props =>
    props.error &&
    css`
      border-top-color: ${props.theme.errorColor};
      border-bottom-color: ${props.theme.errorColor};
      border-right-color: ${props.theme.errorColor};
    `} ${props =>
    props.applyBorderRightForError &&
    css`
      border-right-color: ${props.theme.errorColor};
    `}
  border-radius: 3px;
  background-color: #ffffff !important;

  ${props =>
    props.wrapperWidth &&
    css`
      width: ${props => props.wrapperWidth};
    `} ${props =>
    props.wrapperHeight &&
    css`
      height: ${props => props.wrapperHeight};
    `}
  
  & + & {
    > div {
      border-left: 0;
    }
  }

  > div {
    height: 100% !important;
    > div:first-child {
      overflow: hidden;
      height: calc(100% - 2px) !important;
      padding: 0 8px 0 8px;
      bottom: 0 !important;
      line-height: 34px;
      font-size: 14px;
      width: 100%;
      ${props =>
        props.error &&
        css`
          color: ${props.theme.errorColor} !important;
        `};
    }
  }
  input {
    display: block;
    padding: 7px 8px 5px 8px !important;
  }
  hr {
    display: none;
  }

  .input-group-addon + & + &:last-child,
  .input-group-addon + &:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .input-group-addon + & + &:last-child {
    border-left: 0;
  }
  .input-group-addon + &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  ${props =>
    props.applyInputGroup &&
    css`
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    `};
`

class TextField extends React.PureComponent {
  render() {
    let props = { ...this.props }
    delete props.width
    delete props.height
    delete props.error
    delete props.muiTheme
    delete props.applyBorderRightForError
    delete props.applyInputGroupLastChild
    delete props.onlyWrapper
    delete props.innerRef

    return (
      <Wrapper
        wrapperWidth={this.props.width}
        wrapperHeight={this.props.height}
        error={this.props.error}
        theme={this.props.muiTheme.palette}
        applyBorder={this.props.applyBorderRightForError}
        applyInputGroup={this.props.applyInputGroupLastChild}
      >
        {this.props.onlyWrapper ? this.props.children : <MuiTextField {...props} ref={this.props.innerRef} />}
      </Wrapper>
    )
  }
}

export default muiThemeable()(TextField)

TextField.propTypes = {
  onlyWrapper: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  error: PropTypes.bool,
  applyBorderRightForError: PropTypes.bool,
  applyInputGroupLastChild: PropTypes.bool,
  innerRef: PropTypes.func
}

TextField.defaultProps = {
  height: "36px",
  onlyWrapper: false,
  applyBorderRightForError: false,
  applyInputGroupLastChild: false
}
