import React from "react"
import PropTypes from "prop-types"
import { css } from "glamor"
import Cluster from "components/flights/results/Cluster"
import { withInfiniteScroll } from "components/commons/withInfiniteScroll"
import * as constants from "config/constants"
import SliderComponent from "./Slider"
import { RewardsCookies } from "../../../utils/rewards"
import muiThemeable from "material-ui/styles/muiThemeable"
class ClusterList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      rewardsPoint: RewardsCookies(0),
      ref: 0,
      clusterWidth: ""
    }
  }

  handlePoints = value => {
    this.setState({ rewardsPoint: value })
  }

  handleRef = ref => {
    const width = ref ? ref.clientWidth : 0
    this.setState({ clusterWidth: width })
  }

  render() {
    const { clusters, flightType, financial, applications } = this.props
    const { rewardsPoint, clusterWidth } = this.state
    const applicationsRewards = applications && applications.rewards ? applications.rewards : null
    const rewards = applicationsRewards ? applicationsRewards.response : null
    return (
      <div className={`${container(applicationsRewards)}`}>
        {applicationsRewards != null && (
          <div className={`${sliderContainer(clusterWidth)}`}>
            <SliderComponent
              minPoints={applicationsRewards.min_points}
              maxPoints={applicationsRewards.max_points}
              precision={rewards.precision}
              selectedPoints={rewardsPoint}
              handlePoints={this.handlePoints}
              color={this.props.muiTheme.palette.highlightedColor}
              userPoints={RewardsCookies(applicationsRewards.min_points)}
            />
          </div>
        )}
        {clusters.map((cluster, i) => {
          return (
            <div ref={this.handleRef}>
              <Cluster
                cluster={cluster}
                flightType={flightType}
                financial={financial}
                key={i}
                rewardsPoint={rewardsPoint}
                rewards={rewards}
                maxPoints={rewards && applicationsRewards.max_points}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

const container = (rewards: boolean) =>
  css({
    marginTop: `${!rewards} ? 16px : 0`,
    width: "100%"
  })

const sliderContainer = width =>
  css({
    width: width
  })

export default muiThemeable()(withInfiniteScroll(ClusterList))

ClusterList.propTypes = {
  clusters: PropTypes.array.isRequired,
  flightType: PropTypes.oneOf(constants.FLIGHT_TYPES).isRequired,
  onPaginatedSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  applications: PropTypes.object,
  financial: PropTypes.object
}
