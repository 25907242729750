import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import muiThemeable from "material-ui/styles/muiThemeable"

const Wrapper = styled.div`
  border-bottom: 1px solid rgba(74, 74, 74, 0.17);
  position: relative;
  margin: 4px 0 12px 0;
  > div {
    position: absolute;
    left: 50%;
    width: 24px;
    height: 16px;
    line-height: 16px;
    margin-left: -12px;
    background-color: rgb(240, 240, 240);
    text-align: center;
    top: -8px;

    > span {
      display: block;
      height: 0;
      width: 0;

      position: absolute;
      left: 50%;
      margin-left: -3px;

      ${props =>
        props.open &&
        css`
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid ${props.color};
          bottom: 8px;
        `} ${props =>
        !props.open &&
        css`
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid ${props.color};
          top: 8px;
        `};
    }
  }
`

class ClusterPriceSummaryToggle extends React.PureComponent {
  render() {
    return (
      <Wrapper open={this.props.open} color={this.props.muiTheme.palette.clickableColor}>
        <div>
          <span />
        </div>
      </Wrapper>
    )
  }
}

export default muiThemeable()(ClusterPriceSummaryToggle)

ClusterPriceSummaryToggle.propTypes = {
  open: PropTypes.bool.isRequired
}
