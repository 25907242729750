import React from "react"
import { withRerenderOnResize } from "components/commons/withRerenderOnResize"
import DateRangeIcon from "material-ui/svg-icons/action/date-range"
import ArrowBackIcon from "material-ui/svg-icons/navigation/arrow-back"
import ArrowForwardIcon from "material-ui/svg-icons/navigation/arrow-forward"
import Popover from "material-ui/Popover/Popover"
import IconButton from "material-ui/IconButton"
import CloseIcon from "material-ui/svg-icons/navigation/close"
import { InputGroup } from "react-bootstrap"
import InputGroupAddon from "components/commons/styled/InputGroupAddon"
import TextField from "components/commons/styled/TextField"
import Modal from "react-modal"
import { modalStyle } from "components/commons/acTheme"
import { isMobile } from "utils/flightsHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"
import moment from "moment"
import SingleDatePicker from "./date-picker/SingleDatePicker"
import RangeDatePicker from "./date-picker/RangeDatePicker"

import { getClientConfig } from "utils/flightsHelper"
import "airbnb-js-shims"

const START_DATE = "startDate"
const END_DATE = "endDate"
const VERTICAL_SCROLLABLE = "verticalScrollable"

class SearchBoxDateRangePicker extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      datePickerOpen: false,
      focusedInput: START_DATE,
      focused: true
    }
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.selectedDateTo) {
      this.setState({ focusedInput: END_DATE })
    }
  }

  handleOpenDatePicker = event => {
    // hack to display the popover on top of the input field if the calendar doesnt fit on the bottom
    // TODO: find a better way to solve this shit :/
    if (!isMobile()) {
      const rect = event.currentTarget.getBoundingClientRect()
      const bottom = rect.bottom || rect.top + event.currentTarget.offsetHeight
      if (bottom + 351 > window.innerHeight) {
        // 351 is the height of the calendar now
        setTimeout(() => {
          window.scrollTo(window.scrollX, window.scrollY + 1)
        }, 100)
      }
    }
    // end of hack
    this.setState({
      datePickerOpen: true,
      anchorEl: event.currentTarget,
      focusedInput: event.currentTarget.id === `search-box-date-to-${this.props.index}` ? END_DATE : START_DATE
    })
  }

  handleCloseDatePicker = () => {
    this.setState({
      datePickerOpen: false
    })
  }

  handleDateRangeChange = date => {
    const { flightType, onSelectRange, index } = this.props

    if (flightType === constants.ROUND_TRIP) {
      onSelectRange(date.startDate, date.endDate, index)
    } else if (flightType !== constants.ROUND_TRIP) {
      onSelectRange(date, null, index)
    }
  }

  onFocusChangeRange = focusedInput => {
    if (!focusedInput) {
      this.setState({ datePickerOpen: false })
    }
    this.setState({ focusedInput: !focusedInput ? START_DATE : focusedInput })
  }

  onFocusChangeSingle = () => {
    this.setState({ focused: true, datePickerOpen: false })
  }

  dayPicker = () => {
    const clientConfig = getClientConfig()
    const { flightType, selectedDateTo, highlightedDates, selectedDateFrom } = this.props
    let { minDate, maxDate } = this.props
    const { focusedInput, focused } = this.state
    if (clientConfig.search_configuration && !minDate) {
      minDate = moment()
        .add(clientConfig.search_configuration.min_days || 0, "d")
        .startOf("day")
    }

    if (clientConfig.search_configuration && !maxDate) {
      maxDate = moment()
        .add(clientConfig.search_configuration.max_days || 330, "d")
        .startOf("day")
    }

    const roundTrip = flightType === constants.ROUND_TRIP

    const iconStyle = { width: 20, height: 20, fill: "#fc511f", color: "#fc511f" }
    const singleCalendarProps = {
      onClose: this.handleCloseDatePicker,
      hideKeyboardShortcutsPanel: true,
      keepOpenOnDateSelect: false,
      numberOfMonths: isMobile() ? 12 : 2,
      orientation: isMobile() ? VERTICAL_SCROLLABLE : undefined,
      initialVisibleMonth: isMobile()
        ? () => moment() // mobile
        : flightType === constants.MULTI_DESTINATION
        ? () => (selectedDateFrom && selectedDateFrom.isAfter(minDate) ? selectedDateFrom : minDate) // multidestination
        : undefined, // round trip and one way
      firstDayOfWeek: 1,
      isOutsideRange: day => day.isBefore(minDate) || day.isAfter(maxDate),
      isDayHighlighted: day1 => highlightedDates.some(day2 => day1.isSame(day2, "day")),
      navPrev: <ArrowBackIcon style={iconStyle} />,
      navNext: <ArrowForwardIcon style={iconStyle} />
    }

    const rangedCalendarProps = { ...singleCalendarProps, minimumNights: 0 }
    const themeColor = clientConfig.theme.palette ? clientConfig.theme.palette.accent1Color : "#126aff"

    if (roundTrip) {
      return (
        <RangeDatePicker
          {...rangedCalendarProps}
          onDatesChange={this.handleDateRangeChange}
          onFocusChange={this.onFocusChangeRange}
          focusedInput={focusedInput}
          selectedFromDate={selectedDateFrom}
          minDate={minDate}
          selectedToDate={selectedDateTo}
          colors={{
            clickColor: themeColor,
            selectionColor: themeColor
          }}
        />
      )
    }

    return (
      <SingleDatePicker
        {...singleCalendarProps}
        onDateChange={this.handleDateRangeChange}
        onFocusChange={this.onFocusChangeSingle}
        focused={focused}
        selectedDate={selectedDateFrom}
        minDate={minDate}
        colors={{
          clickColor: themeColor,
          selectionColor: themeColor
        }}
      />
    )
  }

  render() {
    const { flightType, label, index, selectedDateFrom, selectedDateTo, error, inputValueFormat, t } = this.props
    const { datePickerOpen, anchorEl } = this.state

    const roundTrip = flightType === constants.ROUND_TRIP

    const textFieldProps = {
      error: error,
      fullWidth: true,
      onClick: this.handleOpenDatePicker,
      readOnly: true,
      style: { cursor: "pointer" }
    }

    const headerStyle = {
      padding: "0 16px",
      width: "100%",
      height: 48,
      textAlign: "right"
    }

    return (
      <div>
        <InputGroup>
          <InputGroupAddon>
            <DateRangeIcon /> {label}
          </InputGroupAddon>
          <TextField
            {...textFieldProps}
            id={`search-box-date-from-${index}`}
            value={selectedDateFrom ? selectedDateFrom.format(inputValueFormat) : ""}
            width={roundTrip ? "50%" : "100%"}
            hintText={t("Ida")}
          />
          {roundTrip ? (
            <TextField
              {...textFieldProps}
              id={`search-box-date-to-${index}`}
              value={selectedDateTo ? selectedDateTo.format(inputValueFormat) : ""}
              width={"50%"}
              hintText={t("Regreso")}
            />
          ) : null}
        </InputGroup>

        {isMobile() ? (
          <Modal
            isOpen={datePickerOpen}
            style={modalStyle}
            onRequestClose={this.handleCloseDatePicker}
            shouldCloseOnOverlayClick={false}
            contentLabel="Modal"
            ariaHideApp={false}
            bodyOpenClassName="ReactModal__Body--open"
            role="dialog"
          >
            <div style={headerStyle}>
              <IconButton onClick={this.handleCloseDatePicker}>
                <CloseIcon />
              </IconButton>
            </div>
            {this.dayPicker()}
          </Modal>
        ) : (
          <Popover
            open={datePickerOpen}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            targetOrigin={{ horizontal: "left", vertical: "top" }}
            onRequestClose={this.handleCloseDatePicker}
            animated={false}
          >
            {this.dayPicker()}
          </Popover>
        )}
      </div>
    )
  }
}

export default withTranslation()(withRerenderOnResize(SearchBoxDateRangePicker))
