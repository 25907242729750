import React from "react"
import { connect } from "react-redux"
import { boundFetchClusters, boundClearAll, boundClearAllButFilters } from "actions/search"
import { resetErrorMessage } from "actions"
import { css } from "glamor"
import { stringify } from "query-string"
import { clearAppParamsFromSearch } from "utils/flightsHelper"
import { propsToQuerystring } from "utils/searchBoxHelper"
import * as constants from "config/constants"
import MediaQuery from "react-responsive"
import { Grid } from "react-bootstrap"
import ReactGA from "react-ga"

import muiThemeable from "material-ui/styles/muiThemeable"

//Components
import SearchBox from "../search/SearchBox"
import { getClientConfig } from "../../../utils/flightsHelper"

class Home extends React.PureComponent {
  state = {
    homeImageUrl: "https://statics.basset.la/flights/home.jpg"
  }

  componentWillMount() {
    const config = getClientConfig()
    if (config.homeImageUrl) {
      this.setState({ homeImageUrl: config.homeImageUrl })
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    const handleSubmitSearch = filters => {
      const { history, location, clearAll, resetErrorMessage } = this.props

      resetErrorMessage()
      // clear results only when the form is submited, the clearAll was extracted to avoid clear when searching new pages for pagination
      clearAll()
      // then, push the new querystring
      history.push({
        search: stringify(
          { ...clearAppParamsFromSearch(location.search), ...propsToQuerystring(filters) },
          { encode: false }
        ),
        pathname: "/flights/results"
      })
    }

    return (
      <Grid>
        <div className={container}>
          <div className={columnLeft} style={{ backgroundColor: this.props.muiTheme.palette.searchBoxBgColor }}>
            <div className={searchBoxContainer}>
              <SearchBox onSubmitSearch={handleSubmitSearch} />
            </div>
          </div>
          <MediaQuery minDeviceWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
            <div className={columnRight}>
              <img src={this.state.homeImageUrl} alt={"home"} className={flightImg} />
            </div>
          </MediaQuery>
        </div>
      </Grid>
    )
  }
}

const container = css({
  "@media(min-width: 992px)": { display: "flex" }
})

const columnLeft = css({
  justifyContent: "center",
  "@media(min-width: 992px)": {
    flex: 1,
    maxWidth: "500px",
    padding: "0 40px 0 40px"
  }
})

const columnRight = css({
  "@media(min-width: 992px)": { flex: 1 },
  overflow: "hidden"
})

const searchBoxContainer = css({
  "@media(min-width: 992px)": { padding: "174px 20px 0 20px" }
})

const flightImg = css({
  height: "auto",
  width: "100%"
})

export default muiThemeable()(
  connect(
    null,
    {
      fetchClusters: boundFetchClusters,
      clearAll: boundClearAll,
      clearAllButFilters: boundClearAllButFilters,
      resetErrorMessage: resetErrorMessage
    }
  )(Home)
)
