import React, { Fragment } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import MediaQuery from "react-responsive"
import AnimateHeight from "react-animate-height"
import Modal from "react-modal"
import { modalStyle } from "components/commons/acTheme"
import muiThemeable from "material-ui/styles/muiThemeable"
import { RadioButton } from "material-ui/RadioButton"
import RaisedButton from "material-ui/RaisedButton"
import RadioButtonGroup from "components/commons/styled/RadioButtonGroup"
import { Card, CardHeader, CardText } from "material-ui/Card"
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table"
import classNames from "classnames"
import IconButton from "material-ui/IconButton"
import CloseIcon from "material-ui/svg-icons/navigation/close"
import WorkIcon from "material-ui/svg-icons/action/work"
import KeyboardArrowDownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down"
import KeyboardArrowUpIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up"
import { Tooltip, OverlayTrigger } from "react-bootstrap"
import Icon from "components/commons/Icon"
import SuitcaseIcon from "components/commons/svg-icons/Basset/suitcase"
import BaggageAllowance from "components/commons/BaggageAllowance"
import Stops from "components/commons/Stops"
import Leg from "components/flights/results/Leg"
import LastSeats from "components/flights/results/LastSeats"
import { duration, hasMultipleMarketingCarriers, isMobile } from "utils/flightsHelper"
import moment from "moment"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"
import ReactGA from "react-ga"

const Wrapper = styled.div`
  > * {
    float: left;
    margin-left: 5px;
  }
  > *:first-child,
  > *:last-child {
    margin-left: 0;
  }
`

const FromTo = styled.span`
  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px) {
    padding: 0 16px;
  }
  @media (min-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}px) {
    font-weight: 400;
  }
`

class SegmentOption extends React.PureComponent {
  state = {
    cardOpened: false,
    scrollPos: undefined
  }

  selectOption = () => {
    const { option } = this.props

    this.props.onSelect(option.id)
    if (isMobile()) {
      this.handleCloseDetail()
    }
  }

  toggleDetail = event => {
    if (event.target.type !== "radio" && event.target.name !== "itinerary") {
      const { cardOpened } = this.state

      this.setState({ cardOpened: !cardOpened })

      if (!cardOpened) {
        ReactGA.event({
          category: "Flight Results",
          action: "Itinerary detail open",
          label: "Cluster"
        })
      }
    }
  }

  scrollBack = () => {
    if (isMobile()) {
      window.scrollTo(0, this.state.scrollPos)
    }
  }

  handleCloseDetail = () => {
    this.setState({ cardOpened: false }, this.scrollBack)
  }

  handleTableClick = (rowNumber, columnNumber, event) => {
    if (isMobile()) {
      // the segment option click event in mobile will open de detail
      this.toggleDetail(event)
      this.setState({
        scrollPos: window.scrollY
      })
    } else {
      if (columnNumber === 6 || columnNumber === 2) {
        // the segment option click event in desktop will select the segment
        this.toggleDetail(event)
      }
      if (columnNumber === 0) {
        this.selectOption()
      }
    }
  }

  handleArrowClick = event => {
    this.toggleDetail(event)
  }

  handleRadioClick = () => {
    this.selectOption()
  }

  handleSelectOptionBtnClick = () => {
    this.selectOption()
  }

  diffDaysAfterFlight = option => {
    const diff = moment(option.arrival_date).diff(moment(option.departure_date), "days")
    return diff > 0 ? <span className="exponent-red">+{diff}</span> : null
  }

  stopsDraw = option => {
    return <Stops stopsQty={option.legs.length - 1} />
  }

  legs = () => {
    const { option, t } = this.props

    return (
      <div>
        <FromTo>
          {t("Sale")} {moment(option.departure_date).format("dddd DD [" + t("de") + "] MMMM")}
        </FromTo>

        {option.legs.map((leg, i) => (
          <Leg leg={leg} nextLeg={option.legs[i + 1]} key={i} />
        ))}

        <FromTo>
          {t("Llega")} {moment(option.arrival_date).format("dddd DD [" + t("de") + "] MMMM")}
        </FromTo>
      </div>
    )
  }

  cardTextContent = () => {
    const { option, t } = this.props
    const { cardOpened } = this.state

    if (isMobile()) {
      return (
        <Modal
          isOpen={cardOpened}
          style={modalStyle}
          onRequestClose={this.handleCloseDetail}
          shouldCloseOnOverlayClick={false}
          contentLabel="Modal"
          ariaHideApp={false}
          bodyOpenClassName="ReactModal__Body--open"
          role="dialog"
        >
          <div style={{ padding: "16px 16px 23px 16px" }}>
            <div
              style={{
                padding: "0 16px 12px 16px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.17)",
                margin: "0 -16px"
              }}
            >
              <IconButton
                onClick={this.handleCloseDetail}
                style={{ float: "right", width: 24, height: 24, padding: 0 }}
              >
                <CloseIcon />
              </IconButton>
              <strong style={{ fontSize: 16, lineHeight: "24px" }}>{t("Detalle de vuelo")}</strong>
            </div>

            <div
              style={{
                overflow: "auto",
                position: "absolute",
                top: 53,
                bottom: 53,
                left: 0,
                right: 0,
                paddingBottom: 16
              }}
            >
              <div style={{ marginBottom: 16 }}>
                <BaggageAllowance baggageAllowances={option.baggage_allowances} />
              </div>

              {this.legs()}
            </div>

            <div
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                padding: "8px 16px",
                borderTop: "1px solid #eaeaea"
              }}
            >
              <RaisedButton
                className="custom-btn-secondary"
                label={t("Seleccionar vuelo")}
                secondary={true}
                fullWidth={true}
                onClick={this.handleSelectOptionBtnClick}
              />
            </div>
          </div>
        </Modal>
      )
    }

    return (
      <AnimateHeight duration={constants.ANIMATIONS_DURATION} height={cardOpened ? "auto" : 0}>
        <div style={{ padding: "16px 16px 23px 16px", backgroundColor: "#ffffff" }}>{this.legs()}</div>
      </AnimateHeight>
    )
  }

  render() {
    const { option, origin, destination, selected, index, muiTheme } = this.props
    const { cardOpened } = this.state

    const radioValue = selected ? option.id : undefined
    const shadow = cardOpened ? "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)" : "none"

    let mainStyle = {}
    if (!isMobile()) {
      mainStyle = {
        transition: `margin ${constants.ANIMATIONS_DURATION}ms`,
        marginLeft: cardOpened ? -8 : 0,
        marginRight: cardOpened ? -8 : 0,
        marginTop: cardOpened || index === 0 ? 8 : 0,
        marginBottom: cardOpened ? 8 : 0
      }
    }

    const checkedBaggages = option.baggage_allowances.filter((b: any) => {
      return b.type === "CHECKED_BAGGAGE"
    })
    const carryOns = option.baggage_allowances.filter((b: any) => {
      return b.type === "CARRY_ON"
    })

    return (
      <div style={mainStyle}>
        <Card containerStyle={{ paddingBottom: 0, borderRadius: 3, boxShadow: shadow }} className="segment-option">
          <CardHeader style={{ padding: 0 }} textStyle={{ display: "none" }}>
            <Table
              selectable={false}
              onCellClick={this.handleTableClick}
              className={classNames({
                tableRow: {
                  cursor: "default"
                }
              })}
            >
              <TableBody displayRowCheckbox={false}>
                <TableRow style={{ cursor: "inherit" }}>
                  <TableRowColumn style={{ cursor: "pointer", paddingRight: "0" }}>
                    <div style={{ width: 24, display: "inline-block" }}>
                      <RadioButtonGroup name="itinerary" onChange={this.handleRadioClick} valueSelected={radioValue}>
                        <RadioButton value={option.id} iconStyle={{ marginRight: 8 }} />
                      </RadioButtonGroup>
                    </div>
                    <div style={{ display: "inline" }}>
                      {hasMultipleMarketingCarriers(option)
                        ? // using .concat() to make a copy of the legs array and don't modify the instance when making the sort
                          option.legs
                            .concat()
                            .sort((a, b) => a.marketing_carrier.code.localeCompare(b.marketing_carrier.code))
                            .map((leg, i) => (
                              <OverlayTrigger
                                key={i}
                                placement="top"
                                overlay={<Tooltip id="multipleMarketingCarriers">{leg.marketing_carrier.name}</Tooltip>}
                              >
                                <span>
                                  <Icon icon={leg.marketing_carrier.code} />
                                </span>
                              </OverlayTrigger>
                            ))
                            .reduce((prev, curr, j) => [
                              prev,
                              <span style={{ margin: "0 4px" }} key={j + "+"}>
                                {" "}
                                +{" "}
                              </span>,
                              curr
                            ])
                        : [
                            <OverlayTrigger
                              key={0}
                              placement="top"
                              overlay={<Tooltip id="marketingCarrier">{option.legs[0].marketing_carrier.name}</Tooltip>}
                            >
                              <span>
                                <Icon icon={option.legs[0].marketing_carrier.code} style={{ marginRight: 8 }} key={0} />
                                {option.legs[0].marketing_carrier.name}
                              </span>
                            </OverlayTrigger>
                          ]}

                      <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MAX}>
                        <Wrapper>
                          <strong>{origin.code}</strong>
                          <span>{option.departure_time}</span>
                          <span style={{ width: 36 }}>{this.stopsDraw(option)}</span>
                          <strong>{destination.code}</strong>
                          <span>{option.arrival_time}</span>
                          <span>{this.diffDaysAfterFlight(option)}</span>
                        </Wrapper>
                      </MediaQuery>
                    </div>
                  </TableRowColumn>
                  <TableRowColumn style={{ width: 80 }} className="visible-lg">
                    {option.departure_time}
                  </TableRowColumn>
                  <TableRowColumn style={{ width: 100, cursor: "pointer" }} className="visible-lg">
                    {this.stopsDraw(option)}
                  </TableRowColumn>
                  <TableRowColumn style={{ width: 95 }} className="visible-lg">
                    {option.arrival_time}
                    {this.diffDaysAfterFlight(option)}
                  </TableRowColumn>
                  <TableRowColumn style={{ width: 95, fontSize: 12 }} className="visible-lg">
                    {duration(option.duration)}
                  </TableRowColumn>
                  <TableRowColumn style={{ width: 91 }}>
                    <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MAX}>
                      <div>{duration(option.duration)}</div>
                    </MediaQuery>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip1" bsClass="custom-tooltip">
                          <BaggageAllowance baggageAllowances={option.baggage_allowances} />
                        </Tooltip>
                      }
                    >
                      <span>
                        <WorkIcon
                          style={{
                            width: 13,
                            height: 13,
                            color: carryOns.length > 0 ? muiTheme.palette.highlightedColor : "#9b9b9b",
                            verticalAlign: "text-bottom"
                          }}
                        />

                        {checkedBaggages && checkedBaggages.length > 0 ? (
                          checkedBaggages.map((baggage, i) => (
                            <Fragment key={i}>
                              {[...Array(baggage.quantity)].map((x, j) =>
                                baggage.weight > 0 ? (
                                  <SuitcaseIcon
                                    key={i + j}
                                    color={muiTheme.palette.highlightedColor}
                                    size={baggage.weight >= 15 ? 19 : 14}
                                    style={{ marginLeft: i + j === 0 ? -2 : -4, verticalAlign: "text-bottom" }}
                                  />
                                ) : (
                                  <SuitcaseIcon
                                    size={18}
                                    color={"#9b9b9b"}
                                    style={{ marginRight: 3, verticalAlign: "text-bottom" }}
                                  />
                                )
                              )}
                            </Fragment>
                          ))
                        ) : (
                          <Fragment>
                            <SuitcaseIcon
                              size={18}
                              color={"#9b9b9b"}
                              style={{ marginRight: 3, verticalAlign: "text-bottom" }}
                            />
                          </Fragment>
                        )}
                      </span>
                    </OverlayTrigger>

                    <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MAX}>
                      <LastSeats option={option} />
                    </MediaQuery>
                  </TableRowColumn>
                  <TableRowColumn className="td-icon td-small visible-lg">
                    <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MIN}>
                      <LastSeats option={option} />
                    </MediaQuery>
                  </TableRowColumn>
                  <TableRowColumn className="td-icon td-only-icon visible-lg">
                    <IconButton style={{ width: 24, height: 24 }} onClick={this.handleArrowClick} data-arrow-btn>
                      {cardOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableRowColumn>
                </TableRow>
              </TableBody>
            </Table>
          </CardHeader>
          <CardText style={{ backgroundColor: "#ffffff", padding: 0, borderRadius: "0 0 3px 3px" }}>
            {this.cardTextContent()}
          </CardText>
        </Card>
      </div>
    )
  }
}

export default muiThemeable()(withTranslation()(SegmentOption))

SegmentOption.propTypes = {
  option: PropTypes.object.isRequired,
  origin: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
}

SegmentOption.defaultProps = {
  selected: false
}
