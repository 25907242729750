import React from "react"
import PropTypes from "prop-types"
import { FormGroup, InputGroup, Clearfix } from "react-bootstrap"
import AnimateHeight from "react-animate-height"
import InputGroupAddon from "components/commons/styled/InputGroupAddon"
import SelectField from "components/commons/styled/SelectField"
import MenuItem from "material-ui/MenuItem"
import PersonIcon from "material-ui/svg-icons/social/person"
import AccessibleIcon from "material-ui/svg-icons/action/accessible"
import ArrowDropUpIcon from "material-ui/svg-icons/navigation/arrow-drop-up"
import ArrowDropDownIcon from "material-ui/svg-icons/navigation/arrow-drop-down"
import StopIcon from "components/commons/svg-icons/Basset/stop"
import ClaseIcon from "components/commons/svg-icons/Basset/clase"
import SearchBoxNumberField from "components/flights/search/SearchBoxNumberField"
import { withTranslation } from "react-i18next"
import muiThemeable from "material-ui/styles/muiThemeable"
import * as constants from "config/constants"
import { stopLabel } from "utils/flightsHelper"
import Checkbox from "material-ui/Checkbox"

const personIcon = <PersonIcon />
const accessibleIcon = <AccessibleIcon />

class SearchBoxAdvancedSearch extends React.PureComponent {
  handleChangeBaggageType = value => {
    this.props.onChangeBaggageType(value)
  }

  handleChangeCabinType = value => {
    this.props.onChangeCabinType(value)
  }

  handleChangeStops = value => {
    this.props.onChangeStops(value)
  }

  handleChangeSeniors = value => {
    this.props.onChangeSeniors(value)
  }

  handleChangeDisabled = value => {
    this.props.onChangeDisabled(value)
  }

  handleToggle = () => {
    this.props.onToggle()
  }

  openIcon = () => {
    const style = { verticalAlign: "middle" }
    return this.props.open ? <ArrowDropUpIcon style={style} /> : <ArrowDropDownIcon style={style} />
  }

  render() {
    const {
      t,
      muiTheme,
      open,
      seniors,
      disabled,
      adults,
      cabinType,
      stops,
      maxSeniors,
      maxDisabled,
      baggageType
    } = this.props

    const { sitesConfig } = this.context

    const btnStyle = {
      color: muiTheme.palette.searchBoxTextColor,
      textAlign: "center",
      lineHeight: "32px",
      cursor: "pointer"
    }

    return (
      <div style={{ margin: "0 -8px 8px -8px", padding: "0 8px" }}>
        <div style={btnStyle} onClick={this.handleToggle}>
          {t("Búsqueda avanzada")} {this.openIcon()}
        </div>
        <Clearfix>
          <AnimateHeight duration={constants.ANIMATIONS_DURATION} height={open ? "auto" : 0}>
            {sitesConfig.get("showSeniorsField") && (
              <FormGroup>
                <SearchBoxNumberField
                  icon={personIcon}
                  label={t("Mayores")}
                  placeholder={t("+ 65 años")}
                  textFieldId="seniors"
                  selectedNumber={seniors}
                  minValue={adults + disabled > 0 ? 0 : constants.SENIORS_MIN_VALUE} // when we have adults or disabled we can have 0 seniors
                  maxValue={maxSeniors}
                  onChangeNumber={this.handleChangeSeniors}
                />
              </FormGroup>
            )}
            {sitesConfig.get("showDisabledField") && (
              <FormGroup>
                <SearchBoxNumberField
                  icon={accessibleIcon}
                  label={t("Discapacitados")}
                  textFieldId="disabled"
                  selectedNumber={disabled}
                  minValue={adults + seniors > 0 ? 0 : constants.DISABLED_MIN_VALUE} // when we have adults or seniors we can have 0 disabled
                  maxValue={maxDisabled}
                  onChangeNumber={this.handleChangeDisabled}
                />
              </FormGroup>
            )}
            <FormGroup>
              <InputGroup>
                <InputGroupAddon>
                  <ClaseIcon /> {t("Clase")}
                </InputGroupAddon>
                <SelectField
                  dropDownMenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" } }}
                  value={cabinType}
                  onChange={(event, key, payload) => {
                    this.handleChangeCabinType(payload)
                  }}
                  id="searchBoxCabinType"
                  fullWidth={true}
                >
                  <MenuItem
                    value={constants.SEARCH_BOX_CABIN_TYPE_DEFAULT_VALUE}
                    primaryText={t("Todas las clases")}
                    style={{ fontSize: 14 }}
                  />
                  {Object.keys(constants.CABIN_TYPES).map((key, index) => (
                    <MenuItem
                      value={key}
                      primaryText={constants.CABIN_TYPES[key].label}
                      key={index}
                      style={{ fontSize: 14 }}
                    />
                  ))}
                </SelectField>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon>
                  <StopIcon /> {t("Escalas")}
                </InputGroupAddon>
                <SelectField
                  dropDownMenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" } }}
                  value={stops}
                  onChange={(event, key, payload) => {
                    this.handleChangeStops(payload)
                  }}
                  id="searchBoxStops"
                  fullWidth={true}
                >
                  {[...Array(4)].map((x, index) => {
                    const stopsQty = index - 1
                    return (
                      <MenuItem
                        value={stopsQty}
                        primaryText={stopsQty === -1 ? t("Todas las escalas") : stopLabel(stopsQty, t)}
                        key={index}
                        style={{ fontSize: 14 }}
                      />
                    )
                  })}
                </SelectField>
              </InputGroup>
            </FormGroup>
            {sitesConfig.get("showCheckedBaggageField") && (
              <FormGroup>
                <Checkbox
                  checked={baggageType === constants.BAGGAGE_TYPE_CHECKED}
                  style={{ marginBottom: 8 }}
                  iconStyle={{ marginRight: 8 }}
                  labelStyle={{}}
                  label={t("Vuelos con equipaje")}
                  onCheck={(event, isInputChecked) => {
                    this.handleChangeBaggageType(isInputChecked)
                  }}
                />
              </FormGroup>
            )}
          </AnimateHeight>
        </Clearfix>
      </div>
    )
  }
}

export default muiThemeable()(withTranslation()(SearchBoxAdvancedSearch))

SearchBoxAdvancedSearch.contextTypes = {
  sitesConfig: PropTypes.object.isRequired
}

SearchBoxAdvancedSearch.propTypes = {
  open: PropTypes.bool.isRequired,
  seniors: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cabinType: PropTypes.string.isRequired,
  baggageType: PropTypes.string.isRequired,
  stops: PropTypes.number.isRequired,
  maxSeniors: PropTypes.number.isRequired,
  maxDisabled: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  onChangeSeniors: PropTypes.func.isRequired,
  onChangeDisabled: PropTypes.func.isRequired,
  onChangeCabinType: PropTypes.func.isRequired,
  onChangeStops: PropTypes.func.isRequired,
  onChangeBaggageType: PropTypes.func.isRequired
}
