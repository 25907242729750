import React from "react"
import PropTypes from "prop-types"
import muiThemeable from "material-ui/styles/muiThemeable"
import { Row, Col } from "react-bootstrap"
import PlaneChange from "components/commons/svg-icons/Basset/plane-change"
import { withTranslation } from "react-i18next"

class LegChangeAirplane extends React.PureComponent {
  render() {
    const { leg, nextLeg, muiTheme, t } = this.props

    if (!nextLeg || leg.destination.code !== nextLeg.origin.code) {
      return null
    }

    return (
      <Row style={{ paddingTop: 8 }}>
        <Col xs={1} style={{ textAlign: "center" }}>
          <p>
            <PlaneChange style={{ fill: muiTheme.palette.highlightedColor }} size={20} />
          </p>
        </Col>
        <Col xs={11} sm={11} md={8}>
          <p>
            <strong style={{ color: muiTheme.palette.highlightedColor }}>{t("Cambio de avión")}</strong>
          </p>
        </Col>
      </Row>
    )
  }
}

export default muiThemeable()(withTranslation()(LegChangeAirplane))

LegChangeAirplane.propTypes = {
  leg: PropTypes.object.isRequired,
  nextLeg: PropTypes.object
}
