import { getClientConfig } from "utils/flightsHelper"
import moment from "moment"
import "moment/locale/es"

moment.defineLocale("basset-es", {
  parentLocale: "es",
  months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split("_"),
  weekdays: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
  weekdaysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
  weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sá".split("_")
})

moment.locale("basset-es")

const domainClientConfigBuilder = () => {
  const config = getClientConfig(true)

  if (Promise.resolve(config) === config) {
    // if it's a promise
    return config.then(config => {
      return config
    })
  } else {
    // it's just the object
    return config
  }
}

export default domainClientConfigBuilder
