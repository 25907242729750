import React from "react"
import PropTypes from "prop-types"
import muiThemeable from "material-ui/styles/muiThemeable"
import { withRerenderOnResize } from "components/commons/withRerenderOnResize"
import TextField from "components/commons/styled/TextField"
import Autosuggest from "react-autosuggest"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import InputGroupAddon from "components/commons/styled/InputGroupAddon"
import Modal from "react-modal"
import MenuItem from "material-ui/MenuItem"
import FlatButton from "material-ui/FlatButton"
import IconButton from "material-ui/IconButton"
import HighlightOffIcon from "material-ui/svg-icons/action/highlight-off"
import { InputGroup } from "react-bootstrap"
import FlightIcon from "material-ui/svg-icons/maps/flight"
import LocationCityIcon from "material-ui/svg-icons/social/location-city"
import { withTranslation } from "react-i18next"
import { theme, modalStyle } from "./acTheme"
import { isMobile } from "utils/flightsHelper"
import * as constants from "config/constants"
import _ from "underscore"

const getSuggestionValue = suggestion => {
  if (suggestion && suggestion.iata_code) {
    return suggestion.name
  }
  return ""
}

const getSectionSuggestions = section => section.locations

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const menuItemStyle = { fontSize: 14, minHeight: 32, padding: "8px 0", lineHeight: "16px" }
  const menuItemInnerDivStyle = { overflow: "hidden", textOverflow: "ellipsis" }

  let className = "autocomplete-menu-item"
  if (!suggestion.iata_code) {
    className += " no-iata-code"
  }

  const matches = match(suggestion.name, query)
  const parts = parse(suggestion.name, matches)

  return (
    <MenuItem className={className} style={menuItemStyle} innerDivStyle={menuItemInnerDivStyle}>
      {parts.map((part, index) => (
        <span style={{ backgroundColor: part.highlight ? "rgba(252, 81, 31, 0.35)" : "transparent" }} key={index}>
          {part.text}
        </span>
      ))}
    </MenuItem>
  )
}

export class LocationAutocomplete extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      autocompleteValue: props.selectedLocation.name ? props.selectedLocation.name : "",
      modalOpen: false
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.selectedLocation !== newProps.selectedLocation &&
      !_.isEmpty(newProps.selectedLocation) &&
      newProps.selectedLocation.name
    ) {
      this.setState({
        autocompleteValue: newProps.selectedLocation.name
      })
    }
  }

  onSuggestionSelected = (event, { suggestion }) => {
    const { index, onSelectItem } = this.props

    if (!suggestion.iata_code) return

    onSelectItem(suggestion, index)
    this.setState({ modalOpen: false })
  }

  onChange = (event, { newValue }) => {
    this.setState({
      autocompleteValue: newValue
    })
  }

  onBlur = event => {
    const { selectedLocation, index, onSelectItem } = this.props
    const { autocompleteValue } = this.state

    if (_.isEmpty(selectedLocation)) return

    if (!autocompleteValue.length) {
      onSelectItem({}, index)
    } else if (
      selectedLocation.name !== autocompleteValue &&
      (!event.relatedTarget ||
        ` ${event.relatedTarget.className} `.indexOf(" autocomplete-menu-item ") === -1 ||
        ` ${event.relatedTarget.className} `.indexOf(" no-iata-code ") !== -1)
    ) {
      this.setState({ autocompleteValue: selectedLocation.name })
    }
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === "input-changed") {
      this.props.onUpdateInput(value)
    }
  }

  onSuggestionsClearRequested = () => {
    this.props.onClear()
  }

  openModal = event => {
    event.target.blur()
    this.setState({ modalOpen: true })
  }

  focusAutocompleteInput = () => {
    document.getElementById(this.inputId()).focus()
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  inputId = () => {
    return this.props.label.toLowerCase().replace(/ /g, "_") + "__input"
  }

  renderSectionTitle = section => {
    const { t } = this.props

    const iconStyle = { width: 16, height: 16, verticalAlign: "sub", color: "#9b9b9b", fill: "#9b9b9b" }
    const menuItemStyle = { color: "#9b9b9b", fontSize: 12, minHeight: 32, padding: "8px 0", lineHeight: "16px" }
    switch (section.title) {
      case constants.LOCATION_TYPE_CITY:
        return (
          <MenuItem className="autocomplete-menu-section" style={menuItemStyle}>
            <LocationCityIcon style={iconStyle} /> {t("Ciudades")}
          </MenuItem>
        )
      case constants.LOCATION_TYPE_AIRPORT:
        return (
          <MenuItem className="autocomplete-menu-section" style={menuItemStyle}>
            <FlightIcon style={iconStyle} /> {t("Aeropuertos")}
          </MenuItem>
        )
      default:
        return null
    }
  }

  renderInputComponent = inputProps => {
    const { ref, ...restInputProps } = inputProps
    const textFieldProps = { ...restInputProps, style: { paddingRight: 24 } }
    const textField = <TextField {...textFieldProps} innerRef={ref} />

    if (isMobile()) {
      return (
        <div style={{ position: "relative" }}>
          {textField}
          <IconButton
            onClick={() => {
              this.setState({ autocompleteValue: "" })
              this.focusAutocompleteInput()
            }}
            style={{
              position: "absolute",
              right: 2,
              top: 2,
              width: 32,
              height: 32,
              padding: 4,
              backgroundColor: "#fff"
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </div>
      )
    }
    return textField
  }

  autosuggest = () => {
    const { label, placeholder, error, dataSource, muiTheme, t } = this.props
    const { autocompleteValue, modalOpen } = this.state

    const inputProps = {
      hintText: placeholder,
      value: autocompleteValue,
      onChange: this.onChange,
      onBlur: this.onBlur,
      id: this.inputId(),
      width: "100%",
      height: "32px",
      fullWidth: true,
      error: error
    }

    const suggestions = _.map(_.groupBy(dataSource, "type"), (locations, type) => {
      return {
        title: type,
        locations: locations
      }
    })

    const autosuggestProps = {
      suggestions: suggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      getSuggestionValue: getSuggestionValue,
      getSectionSuggestions: getSectionSuggestions,
      renderSuggestion: renderSuggestion,
      renderInputComponent: this.renderInputComponent,
      onSuggestionSelected: this.onSuggestionSelected,
      multiSection: true,
      renderSectionTitle: this.renderSectionTitle,
      inputProps: inputProps,
      focusInputOnSuggestionClick: false,
      theme: theme,
      id: label.toLowerCase().replace(/ /g, "_")
    }

    if (isMobile()) {
      return [
        <TextField
          error={error}
          width={inputProps.width}
          fullWidth={true}
          id={this.inputId() + "__fake_input"}
          value={inputProps.value}
          hintText={inputProps.hintText}
          onFocus={this.openModal}
          key={0}
        />,
        <Modal
          isOpen={modalOpen}
          style={modalStyle}
          onAfterOpen={this.focusAutocompleteInput}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Modal"
          ariaHideApp={false}
          bodyOpenClassName="ReactModal__Body--open"
          role="dialog"
          key={1}
        >
          <div style={{ background: muiTheme.palette.searchBoxBgColor, padding: "8px 8px 8px 16px", display: "flex" }}>
            <div style={{ width: "100%" }}>
              <Autosuggest {...autosuggestProps} alwaysRenderSuggestions={true} />
            </div>
            <FlatButton label={t("Cancelar")} onClick={this.closeModal} style={{ color: "#fff" }} />
          </div>
        </Modal>
      ]
    }

    const webProps = { ...autosuggestProps, inputProps: { ...inputProps, applyInputGroupLastChild: true } }

    return <Autosuggest {...webProps} />
  }

  render() {
    const { icon, label } = this.props

    return (
      <InputGroup>
        <InputGroupAddon>
          {icon} {label}
        </InputGroupAddon>
        {this.autosuggest()}
      </InputGroup>
    )
  }
}

export default muiThemeable()(withTranslation()(withRerenderOnResize(LocationAutocomplete)))

LocationAutocomplete.propTypes = {
  // required
  label: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  // optional
  index: PropTypes.number, // used for multiple flights, if you don't have multi-destination you don't need to pass this prop
  icon: PropTypes.node,
  placeholder: PropTypes.string,
  selectedLocation: PropTypes.object,
  error: PropTypes.bool,
  // handlers
  onUpdateInput: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired
}

LocationAutocomplete.defaultProps = {
  selectedLocation: {},
  error: false
}
