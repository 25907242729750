import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import api from "middleware/api"
import { routerMiddleware } from "middleware/router"
import rootReducer from "reducers"
import * as ActionTypesSearch from "actions/search"
import { startListener } from "store/historyListener"
import { loadingBarMiddleware } from "react-redux-loading-bar"

const configureStore = history => {
  const historyMiddleware = routerMiddleware(history)

  const store = createStore(
    rootReducer,
    {},
    applyMiddleware(
      thunk,
      api,
      historyMiddleware,
      loadingBarMiddleware({
        promiseTypeSuffixes: [
          ActionTypesSearch.SEARCH_CLUSTERS_REQUEST,
          ActionTypesSearch.SEARCH_CLUSTERS_SUCCESS,
          ActionTypesSearch.SEARCH_CLUSTERS_FAILURE
        ]
      })
    )
  )

  // Start the history listener
  startListener(history, store)

  return store
}

export default configureStore
