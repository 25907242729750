import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import es from "./es.json"
import esAR from "./es-AR.json"

const missingKeyHandler = (lng, ns, key) => {
  throw new Error(`missing key '${key}' on namespace '${ns}' for language '${lng}'`)
}

i18n.use(initReactI18next).init({
  react: {
    wait: true,
    bindI18n: "initialized languageChanged loaded"
  },
  resources: {
    es: {
      translation: es
    },
    "es-AR": {
      translation: esAR
    }
  },
  backend: {
    loadPath: `${process.env.REACT_APP_I18N_PATH}${process.env.REACT_APP_I18N_VERSION}/{{lng}}/{{ns}}.json`,
    crossDomain: true
  },
  fallbackLng: "es",
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false
  },
  returnObjects: true,
  saveMissing: process.env.NODE_ENV === "development" ? true : false,
  missingKeyHandler: missingKeyHandler
})

const rule = {
  name: "Español",
  numbers: [1, 2],
  plurals: function(n) {
    return Number(n !== 1)
  }
}

i18n.services.pluralResolver.addRule("es-AR", rule)
i18n.services.pluralResolver.addRule("es-EC", rule)
i18n.services.pluralResolver.addRule("es-CL", rule)

export default i18n
