import React from "react"
import PropTypes from "prop-types"
import muiThemeable from "material-ui/styles/muiThemeable"
import { withTranslation } from "react-i18next"
import { Row, Col } from "react-bootstrap"
import { css } from "glamor"
import { parsePrice } from "utils/flightsHelper"
import MessageDialog from "components/commons/MessageDialog"

class FinancialAdvertising extends React.Component {
  state = {
    openConditionsDialog: false,
    hide: false
  }
  componentDidMount() {
    const { financial } = this.props
    if (financial.duration > 0) {
      setTimeout(() => {
        this.setState({ hide: true })
      }, financial.duration * 1000)
    }
  }
  calculateInstallments = (price, financial) => {
    return (price.total + price.total * (financial.cft / 100)) / financial.installments
  }
  handleConditionsDialogOpen = () => {
    this.setState({ openConditionsDialog: true })
  }
  handleConditionsDialogClose = () => {
    this.setState({ openConditionsDialog: false })
  }
  render() {
    const { financial, price, muiTheme, t } = this.props

    let installmentMessage = t("Hasta {{installments}} cuotas sin interés de", {
      installments: financial.installments
    })
    if (financial.cft > 0) {
      installmentMessage = t("Hasta {{installments}} cuotas de", { installments: financial.installments })
    }

    return (
      <Row>
        <MessageDialog
          open={this.state.openConditionsDialog}
          message={financial.terms_and_conditions}
          title={t("Condiciones")}
          handleClose={this.handleConditionsDialogClose}
        />
        <Col xs={6} sm={6} md={12} lg={12} style={{ width: "100%" }}>
          <div className={this.state.hide ? hideFinancialContainer : container}>
            <div className={title}>
              <span>{t("Financiación exclusiva")}</span>
            </div>
            <div className={cardLogo}>
              <img
                className={cardLogoImage}
                alt={financial.card_code + financial.bank_code}
                src={"https://statics.basset.la/cards-logos/" + financial.card_code + ".svg"}
              />
            </div>
            <div className={intallmentsContainer}>
              <span className={intallments}>{installmentMessage}</span>
              <span className={intallmentsPrice}>
                {parsePrice(this.calculateInstallments(price, financial), price.currency)}
              </span>
            </div>
            <div className={termsAndConditions}>
              <span
                className={seeConditions(muiTheme.palette.clickableColor)}
                onClick={this.handleConditionsDialogOpen}
              >
                {t("Ver condiciones")}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}
const title = css({
  fontSize: "11px",
  textAlign: "center",
  color: "#0d207b"
})
const cardLogo = css({
  textAlign: "center"
})
const cardLogoImage = css({
  maxWidth: "95px"
})
const intallmentsContainer = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
})
const intallments = css({
  fontSize: "12px",
  textAlign: "center",
  color: "#4a4a4a"
})
const intallmentsPrice = css({
  fontSize: "14px",
  fontWeight: 500,
  textAlign: "center",
  color: "#4a4a4a"
})
const termsAndConditions = css({
  textAlign: "center"
})
const container = css({
  padding: "8px",
  height: "128px",
  borderRadius: "2px",
  backgroundColor: "#ffffff",
  marginBottom: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around"
})
const hideFinancialContainer = css({
  height: "0",
  overflow: "hidden",
  transition: "height .5s"
})
const seeConditions = color =>
  css({
    fontSize: "14px",
    color: color,
    cursor: "pointer",
    textDecoration: "none"
  })

FinancialAdvertising.propTypes = {
  financial: PropTypes.object.isRequired,
  price: PropTypes.object.isRequired
}

export default muiThemeable()(withTranslation()(FinancialAdvertising))
