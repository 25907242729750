import EconomyIcon from "components/commons/svg-icons/Basset/economy"
import PremiumIcon from "components/commons/svg-icons/Basset/premium"
import BusinessIcon from "components/commons/svg-icons/Basset/business"

export const PUSH = "ROUTER/PUSH"
export const REPLACE = "ROUTER/REPLACE"
export const GO = "ROUTER/GO"
export const GO_BACK = "ROUTER/GO_BACK"
export const GO_FORWARD = "ROUTER/GO_FORWARD"
export const LOCATION_CHANGE = "ROUTER/LOCATION_CHANGE"

export const MEDIA_QUERY_BREAKPOINT_MOBILE_MIN = 992
export const MEDIA_QUERY_BREAKPOINT_MOBILE_MAX = 991
export const MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MIN = 1200
export const MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MAX = 1199
export const MEDIA_QUERY_BREAKPOINT_MEDIUM_SCREEN_MAX = 1365

export const ANIMATIONS_DURATION = 300

export const ONE_WAY = "ONE_WAY"
export const ROUND_TRIP = "ROUND_TRIP"
export const MULTI_DESTINATION = "MULTI_DESTINATION"
export const BAGGAGE_TYPE_CHECKED = "CHECKED_BAGGAGE"
export const BAGGAGE_TYPE_ANY = "ANY"
export const FLIGHT_TYPES = [ONE_WAY, ROUND_TRIP, MULTI_DESTINATION]
export const MULTI_DESTINATION_MAX_FLIGHTS = 6
export const PASSENGERS_MAX_VALUE = 9
export const ADULTS_DEFAULT_VALUE = 1
export const ADULTS_MIN_VALUE = 1
export const SENIORS_DEFAULT_VALUE = 0
export const SENIORS_MIN_VALUE = 1
export const DISABLED_DEFAULT_VALUE = 0
export const DISABLED_MIN_VALUE = 1
export const CHILDREN_DEFAULT_VALUE = 0
export const CHILDREN_MIN_VALUE = 0
export const CHILDREN_AGES_OPTIONS = ["0 a 24 meses (en brazos)", "0 a 24 meses (en asiento)", "2 a 11 años"]
export const DEFAULT_CHILDREN_AGE = 0
export const INFANTS_CHILDREN_AGE = 0
export const INFANTS_DEFAULT_VALUE = 0
export const SEARCH_BOX_CABIN_TYPE_DEFAULT_VALUE = ""
export const SEARCH_BOX_STOPS_DEFAULT_VALUE = -1
export const SLIDER_ANIMATION_INTERVAL = 6000

export const LOCATION_TYPE_CITY = "CITY"
export const LOCATION_TYPE_AIRPORT = "AIRPORT"

export const ORDER_BY_OPTIONS = ["Precio", "Escalas", "Duración", "Más convenientes"]

export const CLUSTERS_PER_PAGE = 20

export const CABIN_TYPES = {
  Y: { color: "#36A4D5", icon: EconomyIcon, label: "Economy" },
  M: { color: "#36A4D5", icon: EconomyIcon, label: "Standard" },
  W: { color: "#79AB3F", icon: PremiumIcon, label: "Premium economy" },
  C: { color: "#D6A106", icon: BusinessIcon, label: "Business" },
  F: { color: "#FF8202", icon: BusinessIcon, label: "Primera" }
}
export const LAST_SEATS_MIN_VALUE = 5

export const DEFAULT_DEPARTURE_TIME = [0, 24 * 60 - 1]

export const CHECK_ALL = "checkAll"
