import React from "react"
import styled from "styled-components"
import muiThemeable from "material-ui/styles/muiThemeable"
import * as constants from "config/constants"

const Wrapper = styled.div`
  & + & {
    margin-top: 16px;
  }
  table {
    background-color: transparent !important;
    thead {
      tr,
      th {
        height: 24px !important;
      }
      th {
        font-size: 14px !important;
        letter-spacing: 0.1px;
        color: #000000 !important;
        svg {
          width: 19px !important;
          height: 19px !important;
          color: ${props => props.theme.highlightedColor} !important;
          fill: ${props => props.theme.highlightedColor} !important;
          vertical-align: text-bottom;
        }
      }
      th.blue-th {
        color: ${props => props.theme.highlightedColor} !important;
      }
      th:first-child {
        padding-left: 8px !important;
        font-weight: 300 !important;
      }
      th:last-child {
        padding-right: 8px !important;
        text-align: right !important;
        width: 266px;
        font-weight: 300 !important;
      }
    }
    tbody {
      background-color: #fff;
      .td-icon {
        text-align: center !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .td-only-icon {
        width: 48px !important;
        line-height: 0 !important;
        button {
          padding: 0 !important;
          width: 24px !important;
        }
      }
      .td-small {
        width: 32px !important;
      }
      .exponent-red {
        color: #d0021b;
        font-size: 8px;
        line-height: 1;
        position: relative;
        top: -5px;
        left: 5px;
        font-weight: 400;
      }
      tr {
        border-bottom: 1px solid rgb(224, 224, 224);
        cursor: pointer;

        td {
          line-height: 24px;
        }
        td,
        label {
          font-weight: 300;
        }
        label {
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc(100% - 24px) !important;
        }
        td:first-child {
          padding-left: 8px !important;
        }
      }
    }
  }
  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MAX}px) {
    thead {
      th {
        color: #4a4a4a !important;
      }
      th:last-child {
        font-size: 12px !important;
      }
    }
    .segment-option {
      table {
        tbody {
          tr {
            height: 64px !important;
            td:first-child {
              > div {
                float: left;
              }
              > div:first-child {
                padding-top: 12px;
              }
            }
          }
        }
      }
    }
  }
`

class Segment extends React.PureComponent {
  render() {
    return <Wrapper theme={this.props.muiTheme.palette}>{this.props.children}</Wrapper>
  }
}

export default muiThemeable()(Segment)
