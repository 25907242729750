import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M349.952 102.709c92.050 98.896 130.408 242.316 88.098 380.703s-154.308 235.839-285.923 266.354c45.261 48.601 103.502 86.856 171.573 107.668 206.46 63.121 425.25-53.212 488.371-259.672s-53.212-425.25-259.672-488.371c-68.072-20.812-137.747-21.664-202.448-6.682z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }]
}
export default class NightFlight extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
