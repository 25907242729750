import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import MediaQuery from "react-responsive"
import RaisedButton from "material-ui/RaisedButton"
import { Row, Col } from "react-bootstrap"
import Segment from "components/flights/results/Segment"
import LastSeats from "components/flights/results/LastSeats"
import ClusterPriceSummary from "components/flights/results/ClusterPriceSummary"
import FinancialAdvertising from "components/commons/FinancialAdvertising"
import MissingRates from "components/flights/results/MissingRates"
import { getMissingRateTypes } from "utils/rateHelper"
import { getClientConfig, clearAppParamsFromSearch } from "utils/flightsHelper"

import { parse, stringify } from "query-string"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"
import moment from "moment"
import { RewardsCookies, calculatePointsByCluster } from "../../../utils/rewards"
import { roundingWithStep } from "../../../utils/flightsHelper"

const Wrapper = styled.div`
  background-color: #f0f0f0;
  padding: 16px;
  margin-bottom: 16px;
  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px) {
    padding: 8px;
    margin-bottom: 8px;
  }
`

class Cluster extends React.PureComponent {
  state = {
    selectedOptions: {}
  }

  handleSelectedOption = (num, value) => {
    this.setState({ selectedOptions: { ...this.state.selectedOptions, [num - 1]: value } })
  }

  handleBuy = () => {
    const { cluster, location, rewards, rewardsPoint } = this.props
    const { selectedOptions } = this.state

    const _selectedOptions = cluster.segments.map((segment, i) => {
      if (selectedOptions[i]) {
        return selectedOptions[i]
      }
      return segment.options[0].id
    })

    const flightId = _selectedOptions.join("_")
    const extraParams = clearAppParamsFromSearch(parse(location.search))

    let config = getClientConfig()
    let checkoutUrl = config.checkoutUrl.replace("{id}", flightId).replace("{product}", "flights")

    if (!!rewards) {
      const points = calculatePointsByCluster(cluster.price, roundingWithStep(rewardsPoint, rewards.precision), rewards)
      checkoutUrl = checkoutUrl
        .replace("{rewardsId}", rewards.id)
        .replace("{rewardsPoints}", points)
        .replace("{channel}", config.channel)
    }

    if (!extraParams) checkoutUrl += "?" + stringify(extraParams)

    window.location.href = checkoutUrl
  }

  selectedPoints = (cluster, pointFromSlider) => {
    return pointFromSlider < cluster.minPoints
      ? cluster.minPoints
      : pointFromSlider > cluster.maxPoints
      ? cluster.maxPoints
      : pointFromSlider
  }

  buyButton = () => {
    const { rewards, rewardsPoint, cluster } = this.props

    const disabled = rewards ? RewardsCookies() < calculatePointsByCluster(cluster.price, rewardsPoint, rewards) : false

    return (
      <RaisedButton
        className="custom-btn-secondary"
        label={rewards ? this.props.t("Canjear") : this.props.t("Comprar")}
        secondary={true}
        fullWidth={true}
        style={{ marginTop: 8 }}
        onClick={this.handleBuy}
        disabled={disabled}
      />
    )
  }

  render() {
    const { cluster, rewards, rewardsPoint, flightType, financial, location, maxPoints, t } = this.props
    const missingRateTypes = getMissingRateTypes(cluster, parse(location.search), t)

    return (
      <Wrapper>
        <Row>
          <Col md={3} mdPush={9}>
            <div>
              <LastSeats cluster={cluster} />
              {!rewards && financial && <FinancialAdvertising financial={financial} price={cluster.price} />}
              {!!rewards ? (
                <ClusterPriceSummary
                  price={cluster.price}
                  missingRateTypes={missingRateTypes}
                  rewardsPoint={rewardsPoint}
                  rewards={rewards}
                  financial={financial}
                  maxPoints={maxPoints}
                />
              ) : (
                <ClusterPriceSummary price={cluster.price} missingRateTypes={missingRateTypes} />
              )}
              <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>{this.buyButton()}</MediaQuery>
              {rewards && (
                <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
                  <div style={{ textAlign: "center", fontSize: "12px", fontWeight: "500", margin: "8px 0" }}>
                    {t("taxes_not_included")}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "inline-flex", fontSize: "12px", color: "red" }}>
                      {t("rewards.disclaimer")}
                    </div>
                  </div>
                </MediaQuery>
              )}
              <MissingRates price={cluster.price} missingRateTypes={missingRateTypes} />
            </div>
          </Col>
          <Col md={9} mdPull={3}>
            {cluster.segments.map((segment, i) => (
              <Segment
                key={i}
                segmentNumber={i + 1}
                selectedOption={this.state.selectedOptions[i]}
                options={segment.options}
                flightType={flightType}
                origin={segment.origin}
                destination={segment.destination}
                departureDate={moment(segment.departure_date)}
                onOptionSelected={this.handleSelectedOption}
              />
            ))}

            <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>{this.buyButton()}</MediaQuery>
            {rewards && (
              <>
                <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
                  <div style={{ textAlign: "center", fontSize: "12px", fontWeight: "500", margin: "8px 0" }}>
                    {t("taxes_not_included")}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "inline-flex", fontSize: "12px", color: "red" }}>
                      {t("rewards.disclaimer")}
                    </div>
                  </div>
                </MediaQuery>
              </>
            )}
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

let __Cluster = withTranslation()(Cluster)
if (process.env.NODE_ENV !== "test") {
  __Cluster = withRouter(__Cluster)
}
const _Cluster = __Cluster
export default _Cluster

Cluster.propTypes = {
  cluster: PropTypes.object.isRequired,
  financial: PropTypes.object,
  flightType: PropTypes.oneOf(constants.FLIGHT_TYPES),
  maxPoints: PropTypes.number
}
