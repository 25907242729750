import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Card, CardHeader, CardText } from "material-ui/Card"
import muiThemeable from "material-ui/styles/muiThemeable"

const Wrapper = styled.div`
  & + & {
    border-top: 1px solid rgba(0, 0, 0, 0.14);
  }
`

class CardFilter extends React.PureComponent {
  render() {
    const { title, initiallyExpanded } = this.props

    return (
      <Wrapper>
        <Card initiallyExpanded={initiallyExpanded} style={{ boxShadow: "none", backgroundColor: "transparent" }}>
          <CardHeader
            title={title}
            actAsExpander={true}
            showExpandableButton={true}
            style={{ padding: "8px 0 8px 8px", marginTop: 8 }}
            titleStyle={{ fontSize: 16 }}
            iconStyle={{ color: this.props.muiTheme.palette.clickableColor }}
          />
          <CardText expandable={true} style={{ padding: 8 }}>
            {this.props.children}
          </CardText>
        </Card>
      </Wrapper>
    )
  }
}

export default muiThemeable()(CardFilter)

CardFilter.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  initiallyExpanded: PropTypes.bool
}

CardFilter.defaultProps = {
  initiallyExpanded: false
}
