// @flow

const config = {
  default: {
    showSeniorsField: false,
    showDisabledField: false,
    showCheckedBaggageField: false
  },
  EC: {
    showSeniorsField: true,
    showDisabledField: true,
    showCheckedBaggageField: true
  }
}

class sitesConfig {
  constructor(site) {
    this.site = site
  }

  get = key => {
    if (config[this.site] && config[this.site][key] !== undefined) {
      return config[this.site][key]
    } else if (config["default"][key] !== undefined) {
      return config["default"][key]
    }
    return null // TODO: return null or better throw an exception?
  }
}

const createSitesConfig = site => {
  return new sitesConfig(site)
}

export default createSitesConfig
