import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Row, Col } from "react-bootstrap"
import StopIcon from "material-ui/svg-icons/av/stop"
import { city, duration } from "utils/flightsHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"

const Wrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #f4f4f4;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 8px;
  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

class LegTechnicalStop extends React.PureComponent {
  render() {
    const { technicalStop, t } = this.props

    return (
      <Wrapper>
        <Row>
          <Col xs={1} style={{ textAlign: "center" }}>
            <p>
              <StopIcon style={{ verticalAlign: "middle", width: 16, height: 16 }} />
            </p>
          </Col>
          <Col xs={11} sm={11} md={8}>
            <p>
              <strong>
                {t("Parada técnica en")} {city(technicalStop.location.name)} ({technicalStop.location.code})
              </strong>
              <br />
              {t("Mismo avión")}
            </p>
          </Col>
          <Col xs={11} sm={11} xsOffset={1} smOffset={1} md={3}>
            <p>
              <strong>
                {technicalStop.arrival_time} - {technicalStop.departure_time}
              </strong>
            </p>
          </Col>
          <Col xs={11} sm={11} xsOffset={1} smOffset={1} md={3} mdOffset={0}>
            <p>
              <strong>{duration(technicalStop.duration)}</strong>
            </p>
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

export default withTranslation()(LegTechnicalStop)

LegTechnicalStop.propTypes = {
  technicalStop: PropTypes.object.isRequired
}
