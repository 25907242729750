import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Row, Col } from "react-bootstrap"
import StopIcon from "components/commons/svg-icons/Basset/stop"
import LegChangeAirplane from "components/flights/results/leg/LegChangeAirplane"
import LegChangeAirport from "components/flights/results/leg/LegChangeAirport"
import { stopDuration, city } from "utils/flightsHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"

const Wrapper = styled.div`
  background-color: #f7f9ff;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px dashed rgba(0, 0, 0, 0.17);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.17);
  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px) {
    padding-left: 16px;
    padding-right: 16px;
    border-top: none;
    border-bottom: none;
  }
`

class LegStop extends React.PureComponent {
  render() {
    const { leg, nextLeg, t } = this.props

    if (!nextLeg) {
      return null
    }

    return (
      <Wrapper>
        <Row>
          <Col xs={1} style={{ textAlign: "center" }}>
            <p>
              <StopIcon />
            </p>
          </Col>
          <Col xs={11} sm={11} md={8}>
            <p>
              {t("Escala en")} {city(leg.destination.name)} <strong>({leg.destination.code})</strong>
            </p>
          </Col>
          <Col xs={11} sm={11} xsOffset={1} smOffset={1} md={3}>
            <p>
              <strong>{stopDuration(leg, nextLeg, " ")}</strong>
            </p>
          </Col>
        </Row>
        <LegChangeAirplane leg={leg} nextLeg={nextLeg} />
        <LegChangeAirport leg={leg} nextLeg={nextLeg} />
      </Wrapper>
    )
  }
}

export default withTranslation()(LegStop)

LegStop.propTypes = {
  leg: PropTypes.object.isRequired,
  nextLeg: PropTypes.object
}
