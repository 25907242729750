import React, { Suspense } from "react"
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider"
import getMuiTheme from "material-ui/styles/getMuiTheme"
import domainClientConfigBuilder from "./config/theme"
import createBrowserHistory from "history/createBrowserHistory"
import Root from "containers/Root"
import configureStore from "store/configureStore"
import ReactGA from "react-ga"
import i18n from "i18n/i18n" // initialized i18next instance using reactI18nextModule
import SitesConfigProvider from "components/commons/SitesConfigProvider"
import createSitesConfig from "config/sites"

const browserHistory = createBrowserHistory()
let store = configureStore(browserHistory)

class App extends React.Component {
  state = {
    configuration: {}
  }

  componentWillMount = () => {
    const config = domainClientConfigBuilder()

    if (Promise.resolve(config) === config) {
      // if it's a promise
      config.then(clientConfig => {
        ReactGA.initialize(clientConfig.analyticsId)
        this.setState({ configuration: clientConfig })
      })
    } else {
      // it's just the object
      this.setState({ configuration: config })
    }
  }

  render() {
    const loading = <div style={{ textAlign: "center", lineHeight: "300px" }} />

    if (!this.state.configuration.theme) {
      return loading
    }

    // TODO: If its not test, we add the <I18nextProvider> (is not working on test env)
    let root = <Root store={store} history={browserHistory} />
    i18n.changeLanguage("es-" + this.state.configuration.site)

    const sitesConfig = createSitesConfig(this.state.configuration.site)

    return (
      <Suspense fallback={loading}>
        <MuiThemeProvider muiTheme={getMuiTheme(this.state.configuration.theme)}>
          <SitesConfigProvider sitesConfig={sitesConfig}>{root}</SitesConfigProvider>
        </MuiThemeProvider>
      </Suspense>
    )
  }
}

export default App
