import React from "react"
import Dialog from "material-ui/Dialog"
import PropTypes from "prop-types"
import muiThemeable from "material-ui/styles/muiThemeable"
import { withTranslation } from "react-i18next"
import { css } from "glamor"
import CloseIcon from "material-ui/svg-icons/navigation/close"

/**
 * Alerts are urgent interruptions, requiring acknowledgement, that inform the user about a situation.
 */
class MessageDialog extends React.Component {
  render() {
    const { open, message, title, muiTheme, handleClose } = this.props
    const iconStyle = {
      width: "24px",
      height: "24px",
      color: muiTheme.palette.clickableColor,
      float: "right",
      cursor: "pointer"
    }
    return (
      <div>
        <Dialog
          modal={false}
          open={open}
          onRequestClose={handleClose}
          title={
            <div className={titleContainer}>
              {title}
              <CloseIcon style={iconStyle} onClick={handleClose} />
            </div>
          }
        >
          <div className={text} dangerouslySetInnerHTML={{ __html: message }} />
        </Dialog>
      </div>
    )
  }
}

const text = css({
  fontSize: "16px",
  color: "rgba(0, 0, 0, 0.6)"
})
const titleContainer = css({
  fontSize: "20px",
  color: "rgba(0, 0, 0, 0.87)"
})

MessageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default muiThemeable()(withTranslation()(MessageDialog))
