import React from "react"
import PropTypes from "prop-types"
import AnimateHeight from "react-animate-height"
import { Tab } from "material-ui/Tabs"
import Tabs from "components/commons/styled/Tabs"
import SliderTable from "components/commons/SliderTable"
import IconButton from "material-ui/IconButton"
import KeyboardArrowDownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down"
import KeyboardArrowUpIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up"
import PricesByPlaceholder from "components/flights/results/placeholders/PricesByPlaceholder"
import * as constants from "config/constants"

class PricesBy extends React.PureComponent {
  state = {
    tab: 0,
    open: true
  }

  handleChangeTab = value => {
    this.setState({
      tab: value
    })
  }

  handleCellClick = (elem, x, y, dataSource) => {
    this.props.onCellClick(elem, x, y, dataSource)
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { dataSource, isLoading } = this.props
    const { open } = this.state

    if (isLoading || !dataSource.length) {
      return <PricesByPlaceholder />
    }

    const iconStyle = {
      width: 30,
      height: 30,
      padding: 0,
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 1,
      borderLeft: "solid 1px #eaeaea"
    }

    return (
      <div style={{ position: "relative", marginBottom: "16px" }}>
        <IconButton style={iconStyle} onClick={this.handleToggle}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Tabs value={this.state.tab} onChange={this.handleChangeTab}>
          {dataSource.map((_pricesBy, index) => (
            <Tab
              label={_pricesBy.label}
              className={`custom-tab${this.state.tab === index ? " active" : ""}`}
              value={index}
              key={index}
            >
              {" "}
              <AnimateHeight duration={constants.ANIMATIONS_DURATION} height={open ? "auto" : "0"}>
                <SliderTable dataSource={_pricesBy.table} onCellClick={this.handleCellClick} />
              </AnimateHeight>
            </Tab>
          ))}
        </Tabs>
      </div>
    )
  }
}

export default PricesBy

PricesBy.propTypes = {
  dataSource: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCellClick: PropTypes.func
}
