import React from "react"

export const withInfiniteScroll = Component =>
  class WithInfiniteScroll extends React.Component {
    componentDidMount() {
      window.addEventListener("scroll", this.onScroll, false)
    }

    componentWillUnmount() {
      window.removeEventListener("scroll", this.onScroll, false)
    }

    onScroll = () => {
      let body = document.body,
        html = document.documentElement
      let documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      )

      let windowHeight = window.innerHeight
      let scrollBottom = windowHeight + window.scrollY
      let triggerBottom = scrollBottom + 2700 // 2700px from the bottom to request next page

      if (triggerBottom >= documentHeight && this.props.clusters.length && !this.props.isLoading) {
        this.props.onPaginatedSearch()
      }
    }

    render() {
      return <Component {...this.props} />
    }
  }
