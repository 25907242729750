import React from "react"

export const withRerenderOnResize = Component =>
  class WithRerenderOnResize extends React.Component {
    state = {
      width: null
    }

    timeout = null

    componentDidMount() {
      window.addEventListener("resize", this.resize)
    }

    componentWillUnmount() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      window.removeEventListener("resize", this.resize)
    }

    resize = () => {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => {
        var w = window,
          d = document,
          documentElement = d.documentElement,
          body = d.getElementsByTagName("body")[0],
          width = w.innerWidth || documentElement.clientWidth || body.clientWidth
        this.setState({ width: width })
      }, 200)
    }

    render() {
      return <Component {...this.props} __width={this.state.width} />
    }
  }
