import * as React from "react"
import PropTypes from "prop-types"

class SitesConfigProvider extends React.PureComponent {
  static propTypes = {
    sitesConfig: PropTypes.object.isRequired
  }
  // you must specify what you’re adding to the context
  static childContextTypes = {
    sitesConfig: PropTypes.object.isRequired
  }
  getChildContext() {
    const { sitesConfig } = this.props
    return { sitesConfig }
  }
  render() {
    return <div>{this.props.children}</div>
  }
}

export default SitesConfigProvider
