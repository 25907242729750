import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "core-js/es6/map"
import "core-js/es6/set"
import React from "react"
import ReactDOM from "react-dom"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "./css/main.css"
import App from "./App"
import { unregister } from "./registerServiceWorker"

if (process.env.NODE_ENV === "development" && false) {
  const { whyDidYouUpdate } = require("why-did-you-update")
  whyDidYouUpdate(React, {
    exclude: [
      /SvgIcon/,
      /ToggleCheckBoxOutlineBlank/,
      /ToggleCheckBox/,
      /TouchRipple/,
      /ScaleIn/,
      /EnhancedSwitch/,
      /Checkbox/,
      /RadioButton/,
      /RadioButtonGroup/,
      /FormGroup/,
      /EnhancedButton/,
      /InputGroupAddon/,
      /AutoComplete/,
      /InputGroup/,
      /TextField/,
      /Row/,
      /Col/,
      /RaisedButton/,
      /Paper/,
      /IconButton/,
      /ToggleRadioButtonUnchecked/,
      /ToggleRadioButtonChecked/,
      /PopoverAnimationDefault/,
      /ListItem/,
      /List/,
      /Today/,
      /Month/,
      /Day/,
      /DefaultCalendar/,
      /Calendar/,
      /withPropsOnChange/,
      /DropDownMenu/,
      /BeforeAfterWrapper/,
      /NavigationArrowDropDown/,
      /PopoverAnimationVertical/,
      /SelectField/,
      /NavigationMenu/,
      /AppBar/,
      /Navigation/,
      /Route/,
      /Switch/,
      /Layout/,
      /Grid/,
      /Connect/
    ]
  })
}

ReactDOM.render(<App />, document.getElementById("root"))
unregister()
