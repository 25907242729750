import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import MediaQuery from "react-responsive"
import muiThemeable from "material-ui/styles/muiThemeable"
import { Row, Col } from "react-bootstrap"
import ClusterPriceSummaryToggle from "components/mobile/results/ClusterPriceSummaryToggle"
import { parsePrice, roundingWithStep } from "utils/flightsHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"

import { calculateClusterCash, calculatePointsByCluster } from "../../../utils/rewards"

const Wrapper = styled.div`
  font-weight: 300;

  table {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
    display: table;

    td {
      width: 50%;

      :first-child {
        text-align: left;
      }
      :last-child {
        text-align: right;
      }
    }

    tbody {
      tr {
        :last-child {
          td {
            padding-bottom: 8px;
          }
        }
      }
    }

    tfoot {
      tr {
        :first-child {
          td {
            padding-top: 8px;
          }
        }
      }
    }
  }

  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px) {
    table {
      margin-top: 8px;
      display: ${props => (props.open ? "table" : "none")};
    }
  }
`
const BannerPoints = styled.div`
  display: flex;
  position: relative;
  clear: both;
  top: 0;
  right: 8px;
  background: linear-gradient(45deg, transparent 0%, transparent 15%, black 14%),
    linear-gradient(70deg, transparent 0, transparent 0), linear-gradient(21deg, transparent 76%, black 63%),
    linear-gradient(-45deg, black 85%, transparent 41%);
  color: white;
  width: 110%;
  height: 42px;
  font-weight: 500;
  font-size: 20px;
  vertical-align: middle;
  margin-top: -40px;
  margin-bottom: 18px;
  padding: 8px;
  justify-content: center;

  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px) {
    margin-bottom: 50px;
    position: relative;
    top: 36px;
  }
`
class ClusterPriceSummary extends React.PureComponent {
  state = {
    open: false
  }

  handleToggleSummary = () => {
    this.setState({ open: !this.state.open })
  }

  mainFare = () => {
    const { price } = this.props

    let mainFare = { fare: 0, label: "" }

    if (price.adults && price.adults.quantity > 0) {
      mainFare.fare = parsePrice(price.adults.fare / price.adults.quantity, price.currency)
      mainFare.label = "Tarifa por adulto"
    } else if (price.seniors && price.seniors.quantity > 0) {
      mainFare.fare = parsePrice(price.seniors.fare / price.seniors.quantity, price.currency)
      mainFare.label = "Tarifa por adulto mayor"
    } else if (price.disabled && price.disabled.quantity > 0) {
      mainFare.fare = parsePrice(price.disabled.fare / price.disabled.quantity, price.currency)
      mainFare.label = "Tarifa por persona con discapacidad"
    }

    return mainFare
  }

  render() {
    const { price, rewards, rewardsPoint, muiTheme, missingRateTypes, financial, maxPoints, t } = this.props
    const { open } = this.state

    const mainFare = this.mainFare()
    let total = parsePrice(price.total, price.currency)
    let points

    if (rewards) {
      total = parsePrice(
        calculateClusterCash(price, roundingWithStep(rewardsPoint, rewards.precision), rewards),
        price.currency
      )
      points = parsePrice(
        calculatePointsByCluster(price, roundingWithStep(rewardsPoint, rewards.precision), rewards),
        ""
      )
    }

    const totalLabel = "Total"
    const mobileClosedStyles = {
      textTransform: "uppercase",
      textAlign: "center",
      fontWeight: 500,
      color: muiTheme.palette.clickableColor
    }

    return (
      <div>
        {rewards ? (
          <Wrapper onClick={this.handleToggleSummary} open={open}>
            <BannerPoints>
              {t("Total {{points}} puntos", {
                points: parsePrice(calculatePointsByCluster(price, maxPoints, rewards), "")
              })}
            </BannerPoints>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
                  {/* NOT MOBILE */}

                  <div style={{ textAlign: "center" }}>
                    <span style={{ fontSize: 14, fontWeight: 500, color: muiTheme.palette.textColor }}>
                      {t("A canjear")}
                    </span>
                  </div>
                </MediaQuery>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
                  {/* MOBILE */}
                  <div style={mobileClosedStyles}>{t("A canjear")}</div>
                </MediaQuery>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
                  {/* NOT MOBILE */}
                  <div style={{ textAlign: "center" }}>
                    <span style={{ fontSize: 24, fontWeight: 500, color: muiTheme.palette.textColor }}>
                      {points} {t("puntos")}
                    </span>
                  </div>
                </MediaQuery>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
                  {/* MOBILE */}
                  <div style={mobileClosedStyles}>
                    {points} {t("puntos")}
                  </div>
                </MediaQuery>
              </Col>

              {calculateClusterCash(price, rewardsPoint, rewards) > 0 && (
                <>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
                      {/* NOT MOBILE */}
                      <div style={{ display: "flex", padding: " 0 24px" }}>
                        <div style={{ textAlign: "center", width: "100%", fontSize: "32px" }}>+</div>
                      </div>
                    </MediaQuery>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
                      {/*  MOBILE */}
                      <div style={mobileClosedStyles}>+</div>
                    </MediaQuery>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
                      {/* NOT MOBILE */}
                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontSize: 24, fontWeight: 500, color: muiTheme.palette.textColor }}>
                          {total}
                        </span>
                      </div>
                    </MediaQuery>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
                      {/* MOBILE */}
                      <div style={mobileClosedStyles}>{total}</div>
                    </MediaQuery>
                  </Col>
                </>
              )}
              {calculateClusterCash(price, rewardsPoint, rewards) > 0 && financial && (
                <>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
                      {/* NOT MOBILE */}
                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontSize: 12, fontWeight: 500, color: muiTheme.palette.textColor }}>
                          {t("Hasta {{installments}} cuotas sin interés", {
                            installments: financial.installments
                          })}
                        </span>
                      </div>
                    </MediaQuery>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
                      {/* MOBILE */}
                      <div style={mobileClosedStyles}>
                        {t("Hasta {{installments}} cuotas sin interés", {
                          installments: financial.installments
                        })}
                      </div>
                    </MediaQuery>
                  </Col>
                </>
              )}
            </Row>
          </Wrapper>
        ) : (
          <Wrapper onClick={this.handleToggleSummary} open={open}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
                  {/* NOT MOBILE */}
                  <div style={{ textAlign: "center" }}>{t(mainFare.label)}</div>
                </MediaQuery>

                <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
                  {/* MOBILE */}
                  <div style={open ? { textAlign: "left" } : mobileClosedStyles}>
                    {t(open ? mainFare.label : totalLabel)}
                  </div>
                </MediaQuery>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
                  {/* NOT MOBILE */}
                  <div style={{ textAlign: "center" }}>
                    <span style={{ fontSize: 24, fontWeight: 500, color: muiTheme.palette.textColor }}>
                      {mainFare.fare}
                    </span>
                  </div>
                </MediaQuery>

                <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
                  {/* MOBILE */}
                  <div style={{ textAlign: "right" }}>
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: open ? muiTheme.palette.textColor : muiTheme.palette.clickableColor
                      }}
                    >
                      {open ? mainFare.fare : total}
                    </span>
                  </div>
                </MediaQuery>
              </Col>
            </Row>
            <table>
              <tbody style={{ fontSize: 12 }}>
                {price.adults && price.adults.quantity > 0 ? (
                  <tr>
                    <td>
                      {missingRateTypes && missingRateTypes.length > 0 && "*"}
                      {price.adults.quantity} {t("Adulto", { count: price.adults.quantity })}
                    </td>
                    <td>{parsePrice(price.adults.fare, price.currency)}</td>
                  </tr>
                ) : null}
                {price.seniors && price.seniors.quantity > 0 ? (
                  <tr>
                    <td>
                      {price.seniors.quantity} {t("Mayor", { count: price.seniors.quantity })}
                    </td>
                    <td>{parsePrice(price.seniors.fare, price.currency)}</td>
                  </tr>
                ) : null}
                {price.disabled && price.disabled.quantity > 0 ? (
                  <tr>
                    <td>
                      {price.disabled.quantity} {t("Persona con discapacidad", { count: price.disabled.quantity })}
                    </td>
                    <td>{parsePrice(price.disabled.fare, price.currency)}</td>
                  </tr>
                ) : null}
                {price.children && price.children.quantity > 0 ? (
                  <tr>
                    <td>
                      {price.children.quantity} {t("Niño", { count: price.children.quantity })}
                    </td>
                    <td>{parsePrice(price.children.fare, price.currency)}</td>
                  </tr>
                ) : null}
                {price.infants && price.infants.quantity > 0 ? (
                  <tr>
                    <td>
                      {price.infants.quantity} {t("Bebé", { count: price.infants.quantity })}
                    </td>
                    <td>{parsePrice(price.infants.fare, price.currency)}</td>
                  </tr>
                ) : null}
                <tr>
                  <td>{t("Impuestos y tasas")}</td>
                  <td>{parsePrice(price.taxes, price.currency)}</td>
                </tr>
                <tr>
                  {/* DISPLAY DISCOUNT WHEN THE FARE HAVE MARKDOWN */}
                  <td>{price.charges >= 0 ? t("Cargos") : t("Descuento")}</td>
                  <td>{parsePrice(price.charges, price.currency)}</td>
                </tr>
              </tbody>
              <tfoot style={{ fontSize: 16, fontWeight: 500 }}>
                <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
                  {/* NOT MOBILE */}
                  <tr
                    style={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.17)",
                      marginTop: 8,
                      color: muiTheme.palette.textColor
                    }}
                  >
                    <td>{t(totalLabel)}</td>
                    <td>{total}</td>
                  </tr>
                </MediaQuery>

                <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
                  {/* MOBILE */}
                  <tr
                    style={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.17)",
                      marginTop: 8,
                      color: muiTheme.palette.clickableColor
                    }}
                  >
                    <td>{t(open ? totalLabel : mainFare.label)}</td>
                    <td>{open ? total : mainFare.fare}</td>
                  </tr>
                </MediaQuery>
              </tfoot>
            </table>
            <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
              <ClusterPriceSummaryToggle open={open} />
            </MediaQuery>
          </Wrapper>
        )}
      </div>
    )
  }
}

export default muiThemeable()(withTranslation()(ClusterPriceSummary))

ClusterPriceSummary.propTypes = {
  price: PropTypes.shape({
    adults: PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      fare: PropTypes.number.isRequired
    }),
    children: PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      fare: PropTypes.number.isRequired
    }),
    infants: PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      fare: PropTypes.number.isRequired
    }),
    charges: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired
  }),
  missingRateTypes: PropTypes.array.isRequired
}
