import React from "react"
import { Route, Switch } from "react-router-dom"
import Layout from "containers/Layout"
import Home from "./components/flights/home/Home"
import FlightsResultsContainer from "containers/FlightsResultsContainer"
import ComponentTestContainer from "containers/ComponentTestContainer"
import { withTranslation } from "react-i18next"

class Routes extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <Switch>
            <Route exact path="/flights" component={Home} />
            <Route exact path="/flights/results" component={FlightsResultsContainer} />
            <Route exact path="/flights/test" component={ComponentTestContainer} />
            <Route
              render={() => (
                <div style={{ textAlign: "center", marginTop: 100 }}>
                  <h1>{this.props.t("404 No se ha encontrado la página")}</h1>
                  <br />
                  <a href="/flights">{this.props.t("¿Desea ir a vuelos?")}</a>
                </div>
              )}
            />
          </Switch>
        </Layout>
      </div>
    )
  }
}

export default withTranslation()(Routes)
