import React from "react"
import { Clearfix, Col } from "react-bootstrap"
import AnimatedBgEffect from "components/commons/styled/AnimatedBgEffect"

class PricesByPlaceholder extends React.PureComponent {
  render() {
    return (
      <div style={{ padding: "20px 16px 24px 16px", backgroundColor: "#eaeaea", marginBottom: 16, borderRadius: 10 }}>
        <AnimatedBgEffect style={{ width: 136, height: 16, borderRadius: 8, margin: "0 auto 20px auto" }} />
        <div>
          {[...Array(4)].map((x, i) => (
            <Clearfix key={i}>
              <AnimatedBgEffect
                className="col-xs-2"
                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.14)", height: 24 }}
              />
              <Col
                xs={10}
                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.14)", backgroundColor: "#fff", height: 24 }}
              />
            </Clearfix>
          ))}
        </div>
      </div>
    )
  }
}

export default PricesByPlaceholder
