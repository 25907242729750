import React from "react"
import styled from "styled-components"
import { RadioButtonGroup as MuiRadioButtonGroup } from "material-ui/RadioButton"

const Wrapper = styled.div`
  svg {
    width: 16px !important;
    height: 16px !important;
    margin-top: 4px;
  }
  input + div {
    > div:first-child {
      margin-right: 8px !important;
      width: 16px !important;
      height: 16px !important;
      > div:nth-child(2) > span {
        top: -4px !important;
        left: -8px !important;
      }
    }
  }
  @media (max-width: 334px) {
    font-size: 13px;
  }
  @media (max-width: 319px) {
    input + div {
      > div:first-child {
        margin-right: 6px !important;
      }
    }
  }
  @media (max-width: 313px) {
    font-size: 12px;
  }
  @media (max-width: 298px) {
    input + div {
      > div:first-child {
        margin-right: 3px !important;
      }
    }
  }
  @media (max-width: 289px) {
    font-size: 11px;
  }
`

class RadioButtonGroup extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <MuiRadioButtonGroup {...this.props} />
      </Wrapper>
    )
  }
}

export default RadioButtonGroup
