import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M708.444 251.259v-208.593h-107.556v208.593h-215.111v-208.593h-107.556v208.593c-53.778 0-107.556 52.148-107.556 104.296v286.815l188.222 182.519v156.444h268.889v-156.444l188.222-182.519v-286.815c0-52.148-53.778-104.296-107.556-104.296z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }]
}
export default class Plug extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
