import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M818.666 385.423c-24.353-6.495-48.931 9.857-54.865 36.553l-83.295 374.519h-251.1c-25.077 0-45.406 22.282-45.406 49.752 0 27.477 20.33 49.752 45.406 49.752h286.769c20.935 0 39.158-15.689 44.113-37.971l91.745-412.49c5.934-26.697-9.002-53.614-33.367-60.116z",
    "M652.142 718.28l72.020-350.109c4.773-23.196-5.305-46.913-25.468-59.934-20.156-13.021-46.373-12.729-66.238 0.722l-180.8 122.465-148.559 23.772c-32.271 5.163-54.161 34.991-48.893 66.627 5.268 31.63 35.707 53.072 67.978 47.927l161.714-25.878c8.661-1.383 16.9-4.642 24.124-9.532l71.055-48.133-33.441 162.571h-250.703c-22.639 0-43.296 12.651-53.22 32.594l-107.708 216.48c-14.331 28.808-2.13 63.55 27.269 77.596 8.357 3.998 17.197 5.885 25.907 5.885 21.933 0 43.005-11.995 53.269-32.612l91.483-183.88h262.161c28.192 0 52.471-19.476 58.048-46.561z",
    "M661.333 256c58.913 0 106.667-47.754 106.667-106.667s-47.754-106.667-106.667-106.667c-58.913 0-106.667 47.754-106.667 106.667s47.754 106.667 106.667 106.667z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }, { fill: "rgb(74, 74, 74)" }, { fill: "rgb(74, 74, 74)" }]
}
export default class Clase extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
