import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  .filters-header,
  .filters-content,
  .filters-footer {
    padding: 0 16px;
    width: 100%;
  }
  .filters-header {
    height: 48px;
    text-align: right;
  }
  .filters-content,
  .filters-footer {
    position: absolute;
  }
  .filters-content {
    overflow-y: auto;
    top: 48px;
    bottom: 68px;
    width: 100%;
  }
  .filters-footer {
    height: 68px;
    bottom: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.14);
  }
`

class FlightsFiltersMobileContainer extends React.PureComponent {
  render() {
    return <Wrapper open={this.props.open}>{this.props.children}</Wrapper>
  }
}

export default FlightsFiltersMobileContainer
