import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M499.793 679.998l280.253-271.378c21.384-20.708 21.929-54.829 1.228-76.207-4.898-5.060-10.569-8.911-16.618-11.635-13.366-6.94-29.708-7.425-43.972-0.078-2.773 1.234-5.461 2.689-8.036 4.413l-132.689 74.542-176.224 6.449c-26.438 0.97-47.086 23.187-46.122 49.625 0.946 25.846 22.193 46.158 47.847 46.158 0.593 0 1.186-0.012 1.785-0.036l126.347-4.623-93.106 90.16h-265.881c-22.887 0-43.277 14.456-50.859 36.056l-78.028 222.448c-9.851 28.091 4.934 58.841 33.020 68.698 5.904 2.072 11.923 3.054 17.839 3.054 22.253 0 43.074-13.887 50.853-36.074l65.386-186.392h249.485c13.995 0 27.438-5.443 37.493-15.18z",
    "M840.003 328.887c53.817 0 97.448-43.631 97.448-97.448 0-53.823-43.631-97.454-97.448-97.454s-97.448 43.631-97.448 97.454c0 53.817 43.631 97.448 97.448 97.448z",
    "M853.442 405.469c-99.394 106.945-198.788 213.89-298.182 320.835h-271.079c-57.793 0-57.889 89.825 0 89.825h250.67c17.767 0 40.469 3.198 57.242-3.868 13.653-5.755 22.941-18.426 32.798-29.025 17.959-19.318 35.912-38.643 53.871-57.961 48.074-51.727 96.154-103.46 144.228-155.187l93.962-101.107c39.319-42.301-24.055-105.963-63.512-63.512z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }, { fill: "rgb(74, 74, 74)" }, { fill: "rgb(74, 74, 74)" }]
}
export default class Business extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
