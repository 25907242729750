import { css } from "glamor"
import Color from "color"

const DEFAULT_COLORS: ColorProps = {
  clickColor: "#126aff",
  selectionColor: "#4680e9"
}

export const datepickerStyle = colors => {
  colors = colors || DEFAULT_COLORS
  const selectionColorLighten = Color(colors.selectionColor).lighten(0.2)

  return css({
    height: "100%",
    "&, &>*": {
      fontFamily: "Roboto"
    },
    "& .DayPicker_weekHeader": {
      borderBottom: "1px solid #eaeaea",
      padding: "0 0 10px 0",
      margin: "0 13px"
    },
    "@media(min-width:992px)": {
      "& .DayPicker_weekHeader": {
        margin: 0
      },
      "& .CalendarMonth_caption": {
        paddingBottom: "52px"
      }
    },
    "& .DayPicker .CalendarDay": {
      textAlign: "center",
      verticalAlign: "middle"
    },
    "& .DayPicker__withBorder": {
      boxShadow: "none"
    },
    "& .DayPicker_transitionContainer__verticalScrollable": {
      paddingTop: "35px",
      position: "relative"
    },
    "& .CalendarMonth_caption strong, .DayPicker_weekHeader_li small": {
      fontSize: "13px",
      fontWeight: 700,
      color: "#4a4a4a"
    },
    "& .DayPickerNavigation_button__default:focus, .DayPickerNavigation_button__default:hover": {
      border: "none"
    },
    "& .DayPickerNavigation_button": {
      border: "none",
      backgroundColor: "#fff !important",
      "& .DayPickerNavigation_svg__horizontal": {
        fill: colors.clickColor
      }
    },
    "& .CalendarMonth_table td": {
      border: "1px solid #fff"
    },
    "& .CalendarMonth_table .CalendarDay": {
      fontSize: "12px",
      fontWeight: 400
    },
    "& .CalendarMonth_table .CalendarDay__hovered_span, .CalendarMonth_table .CalendarDay__hovered_span:active, .CalendarMonth_table .CalendarDay__hovered_span:hover, .CalendarMonth_table .CalendarDay__selected_span, .CalendarMonth_table .CalendarDay__selected_span:active, .CalendarMonth_table .CalendarDay__selected_span:hover": {
      background: selectionColorLighten + " !important",
      border: "1px solid " + selectionColorLighten,
      color: "#fff"
    },
    "& .CalendarMonth_table .CalendarDay__selected, .CalendarMonth_table .CalendarDay__selected:active, .CalendarMonth_table .CalendarDay__selected:hover": {
      position: "relative",
      background: colors.selectionColor + " !important",
      border: "1px solid " + colors.selectionColor,
      color: "#fff"
    },
    "& .CalendarMonth_table .CalendarDay__selected_end::before, .CalendarMonth_table .CalendarDay__selected_start::after": {
      position: "absolute",
      width: 0,
      height: 0,
      content: " ",
      display: "block",
      top: 0,
      borderColor: "transparent",
      borderStyle: "solid"
    },
    "& .CalendarMonth_table .CalendarDay__selected_start::after": {
      right: "-6px",
      borderWidth: "19px 0 19px 10px",
      borderLeftColor: colors.selectionColor,
      background: selectionColorLighten,
      content: " "
    },

    "& .CalendarMonth_table .CalendarDay__selected_end::before": {
      left: "-5px",
      borderWidth: "19px 10px 19px 0",
      borderRightColor: colors.selectionColor,
      background: selectionColorLighten,
      content: " "
    },
    "& .CalendarMonth_table .CalendarDay__blocked_out_of_range, .CalendarMonth_table .CalendarDay__blocked_out_of_range:active, .CalendarMonth_table .CalendarDay__blocked_out_of_range:hover": {
      background: "#fff",
      border: "1px solid #fff",
      cursor: "not-allowed"
    },
    "& .CalendarMonth_table .CalendarDay__highlighted_calendar.CalendarDay__blocked_out_of_range": {
      background: "#ffe8bc"
    },
    "& .CalendarMonth_table .CalendarDay__blocked_out_of_range.CalendarDay__highlighted_calendar:active, .CalendarMonth_table .CalendarDay__blocked_out_of_range.CalendarDay__highlighted_calendar:hover": {
      background: "#ffce71"
    },
    "& .has-error .input-group .input-group-addon": {
      borderColor: "#d0011b"
    },
    "& .has-error .input-group .input-group-addon, .has-error .input-group .input-group-addon *": {
      color: "#d0011b!important",
      fill: "d0011b!important"
    }
  })
}
