import React, { Fragment } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import WorkIcon from "material-ui/svg-icons/action/work"
import LocalMallIcon from "@material-ui/icons/LocalMall"
import SuitcaseIcon from "components/commons/svg-icons/Basset/suitcase"
import muiThemeable from "material-ui/styles/muiThemeable"
import { withTranslation } from "react-i18next"

const Wrapper = styled.div`
  padding: 16px;
  font-size: 12px;
  background: #fff;
  text-align: left;
  color: #4a4a4a;
  font-weight: 400;
  > div {
    border-bottom: 1px dashed #4a4a4a;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  > p {
    white-space: nowrap;
    line-height: 24px;
    margin-top: 0;
  }
`

class BaggageAllowance extends React.PureComponent {
  render() {
    const { baggageAllowances, t } = this.props
    const color = this.props.muiTheme.palette.highlightedColor

    const iconStyle = {
      width: 18,
      height: 14,
      color: color,
      verticalAlign: "text-bottom",
      marginRight: 3
    }

    const checkedBaggages = baggageAllowances.filter((b: any) => {
      return b.type === "CHECKED_BAGGAGE"
    })
    const carryOns = baggageAllowances.filter((b: any) => {
      return b.type === "CARRY_ON"
    })
    const personalItems = baggageAllowances.filter((b: any) => {
      return b.type === "PERSONAL_ITEM"
    })

    return (
      <Wrapper>
        {personalItems.length > 0 ? (
          personalItems.map((personalItem: any, i) => (
            <p style={{ color: color }} key={i}>
              <LocalMallIcon style={iconStyle} />
              {t("Mochila o cartera")}
            </p>
          ))
        ) : (
          <p style={{ color: "#9b9b9b" }}>
            <LocalMallIcon style={iconStyle} color={"#9b9b9b"} />
            {t("No incluye mochila o cartera")}
          </p>
        )}

        {carryOns.length > 0 ? (
          carryOns.map((carryOn: any, i) => (
            <p style={{ color: color }} key={i}>
              <WorkIcon style={iconStyle} />
              {t("Incluye equipaje de mano")}
            </p>
          ))
        ) : (
          <p style={{ color: "#9b9b9b" }}>
            <WorkIcon style={iconStyle} color={"#9b9b9b"} />
            {t("No incluye equipaje de mano")}
          </p>
        )}

        {checkedBaggages.length > 0 ? (
          checkedBaggages.map((checkedBaggage: any, i) => (
            <Fragment key={i}>
              <div style={{ borderBottom: "dotted 1.5px rgba(0, 0, 0, 0.17)" }} />
              <p style={{ color: color }} key={i}>
                {checkedBaggage.quantity && checkedBaggage.quantity > 0 && (
                  <Fragment>
                    <SuitcaseIcon size={18} color={color} style={{ marginRight: 3, verticalAlign: "text-bottom" }} />
                    {t("Incluye {{qty}} equipaje de {{weight}}kg para despachar", {
                      count: checkedBaggage.quantity,
                      qty: checkedBaggage.quantity,
                      weight: checkedBaggage.weight
                    })}
                  </Fragment>
                )}
              </p>
            </Fragment>
          ))
        ) : (
          <Fragment>
            <div style={{ borderBottom: "dotted 1.5px rgba(0, 0, 0, 0.17)" }} />
            <p style={{ color: color }}>
              <SuitcaseIcon size={18} color={"#9b9b9b"} style={{ marginRight: 3, float: "left" }} />
              <span style={{ float: "left", color: "#9b9b9b" }}>
                <span style={{ display: "block", lineHeight: "16px" }}>{t("No incluye equipaje para despachar")}</span>
                <span style={{ color: "#000000" }}>
                  {t("En el aeropuerto podrás sumar valijas para tu vuelo con un costo extra")}
                </span>
              </span>
              <span className="clearfix" />
            </p>
          </Fragment>
        )}
      </Wrapper>
    )
  }
}

export default muiThemeable()(withTranslation()(BaggageAllowance))

BaggageAllowance.propTypes = {
  baggageAllowances: PropTypes.array.isRequired
}
