import React from "react"
import Slider from "../../material-ui/Slider"
import { css, keyframes } from "glamor"
import { withTranslation } from "react-i18next"
import AddRounded from "@material-ui/icons/AddRounded"
import RemoveRounded from "@material-ui/icons/RemoveRounded"
import { WindowScroller } from "react-virtualized"
import Button from "@material-ui/core/Button"
import { roundingWithStep, setFormatPrice } from "../../../utils/rewards"

class SliderComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sliderValue: !props.selectedPoints ? props.userPoints : props.selectedPoints
    }
  }

  handleChange = (event, value) => {
    this.setState({ sliderValue: value })
    this.props.handlePoints(value)
  }

  addPoints = () => {
    const { precision, handlePoints } = this.props
    const { sliderValue } = this.state
    this.setState({ sliderValue: sliderValue + precision })
    handlePoints(sliderValue + precision)
  }

  subPoints = () => {
    const { precision, handlePoints } = this.props
    const { sliderValue } = this.state
    this.setState({ sliderValue: sliderValue - precision })
    handlePoints(sliderValue - precision)
  }

  marks = (userP, min, max) => {
    const { precision } = this.props
    let userPoint = roundingWithStep(userP, precision)
    const hasMin = userPoint !== min
    const hasMax = userPoint !== max && userPoint < max
    let setMarks = [hasMin ? { value: min, label: setFormatPrice(min, 0, 0) } : { value: userPoint }]

    if (hasMax) {
      setMarks.push({ value: max, label: setFormatPrice(max, 0, 0) })
    } else {
      if (userPoint >= max) {
        setMarks.push({ value: max, label: setFormatPrice(max, 0, 0) })
      } else {
        setMarks.push({ value: userPoint })
      }
    }

    if (hasMax && hasMin) {
      setMarks.push({ value: userPoint })
    }

    return setMarks
  }
  valueLabelFormat = x => {
    return setFormatPrice(x, 0, 0)
  }

  slider = () => {
    const { sliderValue } = this.state
    const { minPoints, maxPoints, precision, color, userPoints, t } = this.props
    let sliderValueRounded = roundingWithStep(sliderValue, precision)
    let userP = roundingWithStep(userPoints, precision)
    return (
      <>
        <div className={`${infoContainer}`}>
          <span className={`${titleStyle}`}>
            <span>{t("SliderComponent.title")}</span> <span>{setFormatPrice(userPoints, 0, 0)}</span>
          </span>
        </div>

        <div className={`${silderContainer}`}>
          {!!sliderValueRounded && (
            <>
              {userP > minPoints && userP <= maxPoints && (
                <div
                  className={`${divDivisor(((userP - minPoints) * 100) / (maxPoints - minPoints))}`}
                  style={{ zIndex: 1 }}
                >
                  <Slider
                    valueLabelDisplay={"on"}
                    aria-labelledby="discrete-slider-small-steps"
                    min={minPoints}
                    max={userP}
                    step={precision}
                    rootColor={color}
                    trackColor={color}
                    disabledColor={"#444"}
                    value={sliderValueRounded}
                    valueLabelFormat={this.valueLabelFormat}
                    onChange={this.handleChange}
                    marks={this.marks(userP, minPoints, maxPoints)}
                  />
                </div>
              )}
              {userP < maxPoints && (
                <div className={`${divDivisor(100 - ((userP - minPoints) * 100) / (maxPoints - minPoints))}`}>
                  {userP < minPoints && <span className={`${warningOfPoints}`}>Puntos insuficientes</span>}
                  <Slider
                    defaultValue={sliderValueRounded}
                    aria-labelledby="discrete-slider-small-steps"
                    step={precision}
                    min={userP < minPoints ? minPoints : userP}
                    max={maxPoints}
                    disabled
                    marks={
                      userP < minPoints
                        ? [
                            { value: minPoints, label: setFormatPrice(minPoints, 0, 0) },
                            { value: maxPoints, label: setFormatPrice(maxPoints, 0, 0) }
                          ]
                        : userP === minPoints
                        ? [
                            { value: userP, label: setFormatPrice(userP, 0, 0) },
                            { value: maxPoints, label: setFormatPrice(maxPoints, 0, 0) }
                          ]
                        : [{ value: maxPoints, label: setFormatPrice(maxPoints, 0, 0) }]
                    }
                  />
                </div>
              )}
              {userP > maxPoints && (
                <div
                  className={`${divDivisor(((userP - minPoints) * 100) / (maxPoints - minPoints))}`}
                  style={{ zIndex: 1 }}
                >
                  <Slider
                    valueLabelDisplay={"on"}
                    aria-labelledby="discrete-slider-small-steps"
                    min={minPoints}
                    max={maxPoints}
                    step={precision}
                    rootColor={color}
                    trackColor={color}
                    disabledColor={"#444"}
                    value={sliderValueRounded}
                    valueLabelFormat={this.valueLabelFormat}
                    onChange={this.handleChange}
                    marks={this.marks(userP, minPoints, maxPoints)}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className={`${buttons}`}>
          <Button disabled={userP === sliderValueRounded || userP < minPoints} onClick={this.addPoints}>
            <AddRounded />
          </Button>
          <Button disabled={minPoints === sliderValueRounded || userP < minPoints} onClick={this.subPoints}>
            <RemoveRounded />
          </Button>
        </div>
      </>
    )
  }

  render() {
    return (
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <>
            <div className={`${RewardsContainer(false)}`}>{this.slider()}</div>
            {window.scrollY > 300 && <div className={`${RewardsContainer(window.scrollY > 250)}`}>{this.slider()}</div>}
          </>
        )}
      </WindowScroller>
    )
  }
}

const RewardsContainer = val =>
  css([
    {
      background: "#ffffff !important",
      transition: "all 1s ease",
      height: "90px",
      display: "flex",
      alignItems: "center",
      padding: "16px",
      marginBottom: "16px",
      overflowX: "hidden",
      overflowY: "hidden"
    },
    val && {
      position: "fixed",
      zIndex: 1,
      clear: "both",
      width: "inherit",
      top: 0,
      animation: `${smoothScroll} 1s`
    }
  ])

const smoothScroll = keyframes({
  "0%": {
    transform: "translateY(-40px)"
  },
  "100 %": {
    transform: "translateY(0px)"
  }
})

const warningOfPoints = css({
  color: "red"
})

const silderContainer = css({
  width: `100% !important`,
  marginRight: "32px !important",
  marginTop: "20px !important",
  display: "flex",
  "& > *": {
    marginBottom: "0 !important"
  }
})
const divDivisor = width =>
  css({
    display: "inline-flex",
    width: `${width}%`,
    flexDirection: "column"
  })

const buttons = css({
  "& > button": {
    margin: "4px 0",
    borderRadius: " 2px",
    border: "solid 1px #eaeaea",
    backgroundColor: "#f9f9f9",
    display: "flex",
    padding: "2.75px",
    minWidth: "32px !important"
  }
})

const infoContainer = css({
  minWidth: "160px"
})

const titleStyle = css({
  color: "#4a4a4a",
  fontSize: "14px",
  fontWeight: 500,
  display: "flex",
  flexDirection: "column"
})

export default withTranslation()(SliderComponent)
