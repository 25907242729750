import React from "react"
import PropTypes from "prop-types"
import muiThemeable from "material-ui/styles/muiThemeable"
import styled from "styled-components"
import { Grid, Col } from "react-bootstrap"
import PersonIcon from "material-ui/svg-icons/social/person"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"

const Wrapper = styled.div`
  height: 48px;
  line-height: 32px;
  margin: 8px;
  padding: 8px 0;
  background-color: ${props => props.theme.searchBoxBgColor};
  color: ${props => props => props.theme.searchBoxTextColor};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  > div > div {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    :first-child {
      text-overflow: ellipsis;
    }
    + div {
      border-left: 1px solid #fff;
      strong {
        display: inline-block;
        width: 100%;
      }
    }
  }
`

class SearchBoxMobileBar extends React.PureComponent {
  handleToggle = () => {
    this.props.onToggle()
  }

  flightFromTo = () => {
    const { flightType, flights, t } = this.props

    if (flightType === constants.MULTI_DESTINATION) {
      return t("Multidestino")
    }

    const origin = flights[0].origin.iata_code ? flights[0].origin.iata_code : ""
    const destination = flights[0].destination.iata_code ? flights[0].destination.iata_code : ""

    return origin && destination ? `${origin} - ${destination}` : ""
  }

  flightDates = () => {
    const { flightType, flights } = this.props

    const from = flights[0].dateFrom ? flights[0].dateFrom.format("DD MMM") : ""
    let to = ""

    if (flightType === constants.ROUND_TRIP) {
      to = flights[0].dateTo ? " - " + flights[0].dateTo.format("DD MMM") : ""
    } else if (flightType === constants.MULTI_DESTINATION) {
      to = flights.slice(-1).pop().dateFrom
        ? " - " +
          flights
            .slice(-1)
            .pop()
            .dateFrom.format("DD MMM")
        : ""
    }

    return from + to
  }

  passengersQty = () => {
    const { adults, seniors, disabled, children } = this.props

    let _adults = isNaN(parseInt(adults, 10)) ? 0 : parseInt(adults, 10)
    let _seniors = isNaN(parseInt(seniors, 10)) ? 0 : parseInt(seniors, 10)
    let _disabled = isNaN(parseInt(disabled, 10)) ? 0 : parseInt(disabled, 10)
    let _children = isNaN(parseInt(children, 10)) ? 0 : parseInt(children, 10)

    return _adults + _seniors + _disabled + _children > constants.PASSENGERS_MAX_VALUE
      ? constants.PASSENGERS_MAX_VALUE
      : _adults + _seniors + _disabled + _children
  }

  render() {
    const { muiTheme } = this.props

    //const styles = { height: 32, lineHeight: '32px', backgroundColor: muiTheme.palette.searchBoxBgColor, color: muiTheme.palette.searchBoxTextColor }
    const iconStyles = {
      color: muiTheme.palette.searchBoxTextColor,
      fill: muiTheme.palette.searchBoxTextColor,
      width: 20,
      height: 20,
      marginRight: 6,
      verticalAlign: "middle",
      marginTop: -2
    }

    return (
      <Wrapper theme={muiTheme.palette} onClick={this.handleToggle}>
        <Grid>
          <Col xs={4}>
            <strong>{this.flightFromTo()}</strong>
          </Col>
          <Col xs={5}>
            <strong>{this.flightDates()}</strong>
          </Col>
          <Col xs={3}>
            <strong>
              <PersonIcon style={iconStyles} />
              {this.passengersQty()}
            </strong>
          </Col>
        </Grid>
      </Wrapper>
    )
  }
}

export default muiThemeable()(withTranslation()(SearchBoxMobileBar))

SearchBoxMobileBar.propTypes = {
  flightType: PropTypes.oneOf(constants.FLIGHT_TYPES),
  flights: PropTypes.array.isRequired,
  adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  seniors: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onToggle: PropTypes.func.isRequired
}
