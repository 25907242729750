import { combineReducers } from "redux"
import * as ActionTypesLocation from "actions/location"
import * as ActionTypesSearch from "actions/search"
import * as ActionTypes from "actions"
import * as constants from "config/constants"
import { routerReducer } from "./router"
import { loadingBarReducer } from "react-redux-loading-bar"
import { queryParamsAndStateToProps } from "utils/searchBoxHelper"
import { filterQueryParamsToProps } from "utils/filtersHelper"
import _ from "underscore"
import lodash from "lodash"

const NO_LOCATIONS_MSG = "No se encontraron resultados"

const location = (state = { origins: [], destinations: [] }, action) => {
  switch (action.type) {
    case ActionTypesLocation.ORIGINS_SUCCESS:
      return { ...state, origins: action.response.length ? action.response : [{ name: NO_LOCATIONS_MSG }] }
    case ActionTypesLocation.DESTINATIONS_SUCCESS:
      return { ...state, destinations: action.response.length ? action.response : [{ name: NO_LOCATIONS_MSG }] }
    case ActionTypesLocation.CLEAR_LOCATIONS:
      return { ...state, [action.where]: [] }
    case ActionTypesLocation.SHOW_LOCATIONS_PLACEHOLDER:
      return { ...state, [action.where]: [{ name: "Ingrese al menos 3 letras" }] }
    default:
      return state
  }
}

const searchInitialState = {
  clusters: [],
  matrix: [],
  metadata: {},
  filters: {},
  total: undefined,
  offset: 0,
  limit: constants.CLUSTERS_PER_PAGE,
  executeSearch: false,
  isLoading: false,
  currency: "ARS"
}

const search = (state = searchInitialState, action) => {
  switch (action.type) {
    case ActionTypesSearch.CLEAR_ALL:
      return { ...state, ...searchInitialState }
    case ActionTypesSearch.CLEAR_ALL_BUT_FILTERS:
      return { ...state, ...searchInitialState, filters: { ...state.filters } }
    case ActionTypesSearch.SEARCH_CLUSTERS_REQUEST:
      return { ...state, isLoading: true, executeSearch: false }
    case ActionTypesSearch.SEARCH_CLUSTERS_SUCCESS:
      return {
        ...state,
        ...action.response,
        isLoading: false,
        clusters: state.clusters.concat(action.response.clusters),
        currency: action.response.clusters[0].price.currency,
        metadata: state.clusters.concat(action.response.metadata),
        applications: action.response.applications
      }
    case constants.LOCATION_CHANGE:
      if (action.payload.pathname.indexOf("/flights/results") >= 0 && !_.isEmpty(action.payload.queries)) {
        // based on the page param it is easy to calculate the offset and limit
        let page = action.payload.queries.page ? action.payload.queries.page : 1
        let offset = (page - 1) * constants.CLUSTERS_PER_PAGE
        let limit = constants.CLUSTERS_PER_PAGE
        // but when we have less clusters than the offset (i.e.: page 2 (offset = 20) and we have 0 clusters, it means
        // that the user entered the uri directly and we should request both pages 1 and 2, so the offset should be
        // 0 and the limit should be based on the page, easy, isn't it? =)
        if (state.clusters.length < offset) {
          offset = 0
          limit *= page
          // because when we request clusters we concat the result to the previous list, we clear all here to start with
          // the initial state of this reducer, adding the info we got from the uri, BUT without cleaning the filters
          return {
            ...state,
            ...searchInitialState,
            flightType: action.payload.queries.type,
            offset: offset,
            limit: limit,
            executeSearch: true,
            filters: { ...state.filters }
          }
        }
        // this is the normal way, just update the state based on the uri
        return { ...state, flightType: action.payload.queries.type, offset: offset, limit: limit, executeSearch: true }
      }
      return state
    default:
      return state
  }
}

export const emptyFlight = {
  origin: {},
  destination: {},
  dateFrom: null,
  dateTo: null
}

const searchBoxInitialState = {
  open: true,
  openAdvanced: false,
  flightType: constants.ROUND_TRIP,
  flights: [_.clone(emptyFlight)],
  adults: constants.ADULTS_DEFAULT_VALUE,
  seniors: constants.SENIORS_DEFAULT_VALUE,
  disabled: constants.DISABLED_DEFAULT_VALUE,
  children: constants.CHILDREN_DEFAULT_VALUE,
  childrenAges: {},
  cabinType: constants.SEARCH_BOX_CABIN_TYPE_DEFAULT_VALUE,
  baggageType: constants.BAGGAGE_TYPE_ANY,
  stops: constants.SEARCH_BOX_STOPS_DEFAULT_VALUE
}

const searchBox = (state = searchBoxInitialState, action) => {
  var flights
  switch (action.type) {
    case ActionTypes.TOGGLE_SEARCH_BOX:
      return { ...state, open: action.open }
    case ActionTypes.TOGGLE_ADVANCED_SEARCH:
      return { ...state, openAdvanced: action.openAdvanced }
    case ActionTypes.CHANGE_FLIGHT_TYPE:
      flights = lodash.cloneDeep(state.flights)
      if (action.flightType === constants.MULTI_DESTINATION) {
        let newFlight = _.clone(emptyFlight)
        newFlight.dateFrom = flights[0].dateFrom //seteo en multidestino la fecha que viene por cache
        newFlight.dateTo = null
        newFlight.origin = flights[0].destination //seteo en multidestino el origen con el valor del destino de viaje anterior
        flights.push(newFlight)
      } else if (flights.length > 1) {
        flights = flights.slice(0, 1)
      }
      return { ...state, flightType: action.flightType, flights: flights }
    case ActionTypes.ADD_FLIGHT:
      flights = lodash.cloneDeep(state.flights)

      const lastFlight = flights[flights.length - 1]

      let newFlight = _.clone(emptyFlight)

      newFlight.origin = lastFlight.destination
      newFlight.dateFrom = lastFlight.dateFrom
      newFlight.dateTo = null

      flights.push(newFlight)

      return { ...state, flights: flights }
    case ActionTypes.REMOVE_FLIGHT:
      flights = lodash.cloneDeep(state.flights)
      if (action.index in flights) {
        flights.splice(action.index, 1)
      }
      return { ...state, flights: flights }
    case ActionTypes.SELECT_ORIGIN:
      // save in local cache the origin for future usage
      localStorage.setItem(action.origin.iata_code, JSON.stringify(action.origin))

      flights = lodash.cloneDeep(state.flights)
      flights[action.index].origin = action.origin

      return { ...state, flights: flights }
    case ActionTypes.SELECT_DESTINATION:
      // save in local cache the destination for future usage
      localStorage.setItem(action.destination.iata_code, JSON.stringify(action.destination))

      flights = lodash.cloneDeep(state.flights)
      flights[action.index].destination = action.destination

      // update next flight when multi destination
      if (state.flightType === constants.MULTI_DESTINATION) {
        const next = action.index + 1
        if (next in flights && flights[next].origin) {
          flights[next].origin = action.destination
        }
      }

      return { ...state, flights: flights }
    case ActionTypes.SELECT_DATE_RANGE:
      flights = lodash.cloneDeep(state.flights)
      flights[action.index].dateFrom = action.dateFrom
      flights[action.index].dateTo = action.dateTo

      // update next flight when multi destination
      if (state.flightType === constants.MULTI_DESTINATION) {
        for (let i = action.index + 1; i < flights.length; i++) {
          if (flights[i].dateFrom && action.dateFrom.isAfter(flights[i].dateFrom)) {
            flights[i].dateFrom = null
          }
        }
      }

      return { ...state, flights: flights }
    case ActionTypes.CHANGE_ADULTS:
      return { ...state, adults: action.adults }
    case ActionTypes.CHANGE_SENIORS:
      return { ...state, seniors: action.seniors }
    case ActionTypes.CHANGE_DISABLED:
      return { ...state, disabled: action.disabled }
    case ActionTypes.CHANGE_CHILDREN:
      const children =
        action.children > constants.PASSENGERS_MAX_VALUE ? constants.PASSENGERS_MAX_VALUE : action.children
      let _childrenAges = state.childrenAges
      for (let key in _childrenAges) {
        if (key >= children) {
          delete _childrenAges[key]
        }
      }
      for (let i = 0; i < children; i++) {
        if (!(i in _childrenAges)) {
          _childrenAges[i] = constants.DEFAULT_CHILDREN_AGE
        }
      }
      return { ...state, children: children, childrenAges: _childrenAges }
    case ActionTypes.CHANGE_CHILDREN_AGE:
      return { ...state, childrenAges: action.childrenAges }
    case ActionTypes.CHANGE_CABIN_TYPE:
      return { ...state, cabinType: action.cabinType }
    case ActionTypes.CHANGE_BAGGAGE_TYPE:
      return { ...state, baggageType: action.baggageType }
    case ActionTypes.CHANGE_STOPS:
      return { ...state, stops: action.stops }
    case ActionTypesLocation.PRELOAD_LOCATIONS_SUCCESS:
      flights = lodash.cloneDeep(state.flights)
      flights.forEach(flight => {
        ;["origin", "destination"].forEach(type => {
          if (flight[type].load && action.response) {
            const location = _.find(action.response, loc => loc.iata_code === flight[type].iata_code)
            if (location) {
              localStorage.setItem(location.iata_code, JSON.stringify(location))
              flight[type] = location
            }
          }
        })
      })
      return { ...state, flights: flights }
    case constants.LOCATION_CHANGE:
      if (action.payload.pathname.indexOf("/flights/results") >= 0 && !_.isEmpty(action.payload.queries)) {
        return queryParamsAndStateToProps(action.payload.queries, state)
      }
      return state
    default:
      return state
  }
}

const filtersInitialState = {
  airlines: [],
  stops: [],
  outboundDepartureAirports: [],
  outboundArrivalAirports: [],
  inboundDepartureAirports: [],
  inboundArrivalAirports: [],
  outboundDepartureTime: constants.DEFAULT_DEPARTURE_TIME,
  inboundDepartureTime: constants.DEFAULT_DEPARTURE_TIME
}

const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_FILTER_AIRLINE:
      let airlines = _.clone(state.airlines)
      airlines.push(action.code)
      return { ...state, airlines: airlines }
    case ActionTypes.REMOVE_FILTER_AIRLINE:
      return { ...state, airlines: action.code === constants.CHECK_ALL ? [] : _.without(state.airlines, action.code) }

    case ActionTypes.ADD_FILTER_STOP:
      let stops = _.clone(state.stops)
      stops.push(action.value)
      return { ...state, stops: stops }
    case ActionTypes.REMOVE_FILTER_STOP:
      return { ...state, stops: action.value === constants.CHECK_ALL ? [] : _.without(state.stops, action.value) }

    case ActionTypes.ADD_FILTER_OUTBOUND_DEPARTURE_AIRPORT:
      let outboundDepartureAirports = _.clone(state.outboundDepartureAirports)
      outboundDepartureAirports.push(action.code)
      return { ...state, outboundDepartureAirports: outboundDepartureAirports }
    case ActionTypes.REMOVE_FILTER_OUTBOUND_DEPARTURE_AIRPORT:
      return { ...state, outboundDepartureAirports: _.without(state.outboundDepartureAirports, action.code) }

    case ActionTypes.ADD_FILTER_OUTBOUND_ARRIVAL_AIRPORT:
      let outboundArrivalAirports = _.clone(state.outboundArrivalAirports)
      outboundArrivalAirports.push(action.code)
      return { ...state, outboundArrivalAirports: outboundArrivalAirports }
    case ActionTypes.REMOVE_FILTER_OUTBOUND_ARRIVAL_AIRPORT:
      return { ...state, outboundArrivalAirports: _.without(state.outboundArrivalAirports, action.code) }

    case ActionTypes.ADD_FILTER_INBOUND_DEPARTURE_AIRPORT:
      let inboundDepartureAirports = _.clone(state.inboundDepartureAirports)
      inboundDepartureAirports.push(action.code)
      return { ...state, inboundDepartureAirports: inboundDepartureAirports }
    case ActionTypes.REMOVE_FILTER_INBOUND_DEPARTURE_AIRPORT:
      return { ...state, inboundDepartureAirports: _.without(state.inboundDepartureAirports, action.code) }

    case ActionTypes.ADD_FILTER_INBOUND_ARRIVAL_AIRPORT:
      let inboundArrivalAirports = _.clone(state.inboundArrivalAirports)
      inboundArrivalAirports.push(action.code)
      return { ...state, inboundArrivalAirports: inboundArrivalAirports }
    case ActionTypes.REMOVE_FILTER_INBOUND_ARRIVAL_AIRPORT:
      return { ...state, inboundArrivalAirports: _.without(state.inboundArrivalAirports, action.code) }

    case ActionTypes.CHANGE_FILTER_OUTBOUND_DEPARTURE_TIME:
      return { ...state, outboundDepartureTime: action.time }

    case ActionTypes.CHANGE_FILTER_INBOUND_DEPARTURE_TIME:
      return { ...state, inboundDepartureTime: action.time }

    case constants.LOCATION_CHANGE:
      if (action.payload.pathname.indexOf("/flights/results") >= 0 && !_.isEmpty(action.payload.queries)) {
        return { ...state, ...filterQueryParamsToProps(action.payload.queries) }
      }
      return state

    default:
      return state
  }
}

const userSelectedFilters = (state = { filters: filtersInitialState, applyFilters: false }, action) => {
  switch (action.type) {
    case ActionTypes.APPLY_FILTERS:
      return { ...state, applyFilters: true }
    case constants.LOCATION_CHANGE:
      if (action.payload.pathname.indexOf("/flights/results") >= 0 && !_.isEmpty(action.payload.queries)) {
        return { filters: filterQueryParamsToProps(action.payload.queries), applyFilters: false }
      }
      return state

    default:
      return state
  }
}

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
  const { type } = action

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null
  }
  // If fails search, sorry mami
  if (type === ActionTypesSearch.SEARCH_CLUSTERS_FAILURE) {
    return action.error
  }

  return state
}

const rootReducer = combineReducers({
  // search
  search,
  // entities
  location,
  // containers
  searchBox,
  filters,
  userSelectedFilters,
  // errors handling
  errorMessage,
  // router
  router: routerReducer,
  // loading bar
  loadingBar: loadingBarReducer
})

export default rootReducer
