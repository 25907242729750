import { validDuration } from "utils/flightsHelper"
import * as constants from "config/constants"
import _ from "underscore"

export const minutesToDuration = minutes => {
  let hh = Math.floor(minutes / 60)
  let mm = minutes % 60

  hh = hh < 10 ? `0${hh}` : hh
  mm = mm < 10 ? `0${mm}` : mm

  return `${hh}:${mm}`
}

export const durationToMinutes = duration => {
  if (!validDuration(duration)) {
    return 0
  }
  const arr = duration.split(":")
  return parseInt(arr[0], 10) * 60 + parseInt(arr[1], 10)
}

export const filterQueryParamsToProps = params => {
  let filters = {
    airlines: [],
    stops: [],
    outboundDepartureAirports: [],
    outboundArrivalAirports: [],
    inboundDepartureAirports: [],
    inboundArrivalAirports: [],
    outboundDepartureTime: constants.DEFAULT_DEPARTURE_TIME,
    inboundDepartureTime: constants.DEFAULT_DEPARTURE_TIME
  }
  if (params.airlines) {
    filters.airlines = params.airlines.split(",")
  }
  if (params.stops) {
    filters.stops = params.stops.split(",").map(stop => parseInt(stop, 10))
  }
  if (params.outbound_departure_airports) {
    filters.outboundDepartureAirports = params.outbound_departure_airports.split(",")
  }
  if (params.outbound_arrival_airports) {
    filters.outboundArrivalAirports = params.outbound_arrival_airports.split(",")
  }
  if (params.inbound_departure_airports) {
    filters.inboundDepartureAirports = params.inbound_departure_airports.split(",")
  }
  if (params.inbound_arrival_airports) {
    filters.inboundArrivalAirports = params.inbound_arrival_airports.split(",")
  }
  if (params.outbound_departure_time) {
    filters.outboundDepartureTime = params.outbound_departure_time.split(",").map(durationToMinutes)
  }
  if (params.inbound_departure_time) {
    filters.inboundDepartureTime = params.inbound_departure_time.split(",").map(durationToMinutes)
  }
  return filters
}

/**
 * FlightsFilters props to api querystring params
 * @param props
 * @returns {{}}
 */
export const filterPropsToApiFilters = props => {
  let filters = {}
  if (props.airlines && props.airlines.length) {
    filters.airlines = props.airlines.join(",")
  }
  if (props.stops && props.stops.length) {
    filters.stops = props.stops.join(",")
  }
  if (props.outboundDepartureAirports && props.outboundDepartureAirports.length) {
    filters.outbound_departure_airports = props.outboundDepartureAirports.join(",")
  }
  if (props.outboundArrivalAirports && props.outboundArrivalAirports.length) {
    filters.outbound_arrival_airports = props.outboundArrivalAirports.join(",")
  }
  if (props.inboundDepartureAirports && props.inboundDepartureAirports.length) {
    filters.inbound_departure_airports = props.inboundDepartureAirports.join(",")
  }
  if (props.inboundArrivalAirports && props.inboundArrivalAirports.length) {
    filters.inbound_arrival_airports = props.inboundArrivalAirports.join(",")
  }
  if (
    props.outboundDepartureTime &&
    props.outboundDepartureTime.length &&
    !_.isEqual(props.outboundDepartureTime, constants.DEFAULT_DEPARTURE_TIME)
  ) {
    filters.outbound_departure_time = props.outboundDepartureTime.map(minutesToDuration).join(",")
  }
  if (
    props.inboundDepartureTime &&
    props.inboundDepartureTime.length &&
    !_.isEqual(props.inboundDepartureTime, constants.DEFAULT_DEPARTURE_TIME)
  ) {
    filters.inbound_departure_time = props.inboundDepartureTime.map(minutesToDuration).join(",")
  }
  return filters
}
