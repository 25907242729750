import React from "react"
import muiThemeable from "material-ui/styles/muiThemeable"
import { withTranslation } from "react-i18next"
import { css } from "glamor"

class MissingRates extends React.PureComponent {
  notificationText() {
    const { missingRateTypes, t } = this.props

    return [missingRateTypes.slice(0, -1).join(", "), missingRateTypes.slice(-1)[0]].join(
      missingRateTypes.length < 2 ? "" : ` ${t("Y")} `
    )
  }

  render() {
    const { missingRateTypes } = this.props

    if (missingRateTypes && missingRateTypes.length === 0) {
      return null
    }

    return (
      <div className={containerStyle}>
        <p>*Esta línea aérea no cuenta con tarifa diferencial para {this.notificationText()}.</p>
      </div>
    )
  }
}

const containerStyle = css({
  margin: "20px 0",
  textAlign: "center",
  fontSize: "11px",
  textTransform: "lowercase",
  "::first-letter": {
    textTransform: "capitalize"
  }
})

export default muiThemeable()(withTranslation()(MissingRates))
