import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M416.9 80.516h26.685v82.36c0 5.761 7.789 10.363 15.887 10.363s15.872-4.602 15.872-10.363v-82.36h79.397v82.36c0 5.761 3.827 10.363 11.918 10.363 8.106 0 11.902-4.602 11.902-10.363v-82.36h34.625c7.598 0 13.744-9.735 13.744-18.947 0.008-9.107-6.145-18.902-13.744-18.902h-196.286c-7.598 0-13.744 9.795-13.744 18.902-0.008 9.205 6.145 18.947 13.744 18.947zM720.207 209.203h-410.326c-55.459 0-96.547 43.156-96.547 96.425v502.432c0 53.216 41.088 97.575 96.547 97.575h6.669v52.027c0 11.869 6.693 23.671 19.151 23.671h39.461c12.489 0 20.794-11.801 20.794-23.671v-52.035h230.253v52.027c0 11.869 13.355 23.671 25.844 23.671h39.461c12.457 0 22.033-11.801 22.033-23.671v-52.027h6.669c55.451 0 96.547-44.352 96.547-97.575v-502.432c-0.008-53.269-41.104-96.417-96.555-96.417zM626.2 363.12c0 18.319-11.481 35.321-29.345 35.321h-163.63c-17.833 0-29.337-17.002-29.337-35.321v-55.517h222.313v55.517z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }]
}
export default class Suitcase extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
