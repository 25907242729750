import * as constants from "config/constants"

export const TOGGLE_SEARCH_BOX = "TOGGLE_SEARCH_BOX"
export const toggleSearchBox = open => {
  return {
    type: TOGGLE_SEARCH_BOX,
    open: open
  }
}
export const boundToggleSearchBox = () => (dispatch, getState) => {
  return dispatch(toggleSearchBox(!getState().searchBox.open))
}

export const TOGGLE_ADVANCED_SEARCH = "TOGGLE_ADVANCED_SEARCH"
export const toggleAdvancedSearch = openAdvanced => {
  return {
    type: TOGGLE_ADVANCED_SEARCH,
    openAdvanced: openAdvanced
  }
}
export const boundToggleAdvancedSearch = () => (dispatch, getState) => {
  return dispatch(toggleAdvancedSearch(!getState().searchBox.openAdvanced))
}

export const CHANGE_FLIGHT_TYPE = "CHANGE_FLIGHT_TYPE"
export const changeFlightType = flightType => {
  return {
    type: CHANGE_FLIGHT_TYPE,
    flightType: flightType
  }
}
export const boundChangeFlightType = flightType => (dispatch, getState) => {
  if (constants.FLIGHT_TYPES.includes(flightType)) {
    return dispatch(changeFlightType(flightType))
  }
  console.warn(`Invalid flightType: ${flightType} is not one of ${constants.FLIGHT_TYPES.join(", ")}`)
}

export const ADD_FLIGHT = "ADD_FLIGHT"
export const addFlight = () => {
  return {
    type: ADD_FLIGHT
  }
}
export const boundAddFlight = () => (dispatch, getState) => {
  if (getState().searchBox.flights.length < constants.MULTI_DESTINATION_MAX_FLIGHTS) {
    return dispatch(addFlight())
  }
}

export const REMOVE_FLIGHT = "REMOVE_FLIGHT"
export const removeFlight = index => {
  return {
    type: REMOVE_FLIGHT,
    index: index
  }
}
export const boundRemoveFlight = index => (dispatch, getState) => {
  return dispatch(removeFlight(index))
}

export const SELECT_ORIGIN = "SELECT_ORIGIN"
export const selectOrigin = (item, index) => {
  return {
    type: SELECT_ORIGIN,
    index: index,
    origin: item
  }
}
export const boundSelectOrigin = (item, index) => (dispatch, getState) => {
  return dispatch(selectOrigin(item, index))
}

export const SELECT_DESTINATION = "SELECT_DESTINATION"
export const selectDestination = (item, index) => {
  return {
    type: SELECT_DESTINATION,
    index: index,
    destination: item
  }
}
export const boundSelectDestination = (item, index) => (dispatch, getState) => {
  return dispatch(selectDestination(item, index))
}

export const SELECT_DATE_RANGE = "SELECT_DATE_RANGE"
export const selectDateRange = (start, end, index) => {
  return {
    type: SELECT_DATE_RANGE,
    index: index,
    dateFrom: start,
    dateTo: end
  }
}
export const boundSelectDateRange = (start, end, index) => (dispatch, getState) => {
  return dispatch(selectDateRange(start, end, index))
}

export const CHANGE_ADULTS = "CHANGE_ADULTS"
export const changeAdults = adults => {
  return {
    type: CHANGE_ADULTS,
    adults: adults
  }
}
export const boundChangeAdults = adults => (dispatch, getState) => {
  return dispatch(changeAdults(adults))
}

export const CHANGE_SENIORS = "CHANGE_SENIORS"
export const changeSeniors = seniors => {
  return {
    type: CHANGE_SENIORS,
    seniors: seniors
  }
}
export const boundChangeSeniors = seniors => (dispatch, getState) => {
  return dispatch(changeSeniors(seniors))
}

export const CHANGE_DISABLED = "CHANGE_DISABLED"
export const changeDisabled = disabled => {
  return {
    type: CHANGE_DISABLED,
    disabled: disabled
  }
}
export const boundChangeDisabled = disabled => (dispatch, getState) => {
  return dispatch(changeDisabled(disabled))
}

export const CHANGE_CHILDREN = "CHANGE_CHILDREN"
export const changeChildren = children => {
  return {
    type: CHANGE_CHILDREN,
    children: children
  }
}
export const boundChangeChildren = children => (dispatch, getState) => {
  return dispatch(changeChildren(children))
}

export const CHANGE_CHILDREN_AGE = "CHANGE_CHILDREN_AGE"
export const changeChildrenAge = childrenAges => {
  return {
    type: CHANGE_CHILDREN_AGE,
    childrenAges: childrenAges
  }
}
export const boundChangeChildrenAge = (childrenAge, index) => (dispatch, getState) => {
  let childrenAges = { ...getState().searchBox.childrenAges }
  childrenAges[index] = childrenAge
  return dispatch(changeChildrenAge(childrenAges))
}

export const CHANGE_CABIN_TYPE = "CHANGE_CABIN_TYPE"
export const changeCabinType = cabinType => {
  return {
    type: CHANGE_CABIN_TYPE,
    cabinType: cabinType
  }
}
export const boundChangeCabinType = cabinType => (dispatch, getState) => {
  return dispatch(changeCabinType(cabinType))
}

export const CHANGE_BAGGAGE_TYPE = "CHANGE_BAGGAGE_TYPE"
export const changeBaggageType = baggageType => {
  return {
    type: CHANGE_BAGGAGE_TYPE,
    baggageType: baggageType
  }
}
export const boundChangeBaggageType = baggageType => (dispatch, getState) => {
  return dispatch(changeBaggageType(baggageType))
}

export const CHANGE_STOPS = "CHANGE_STOPS"
export const changeStops = stops => {
  return {
    type: CHANGE_STOPS,
    stops: stops
  }
}
export const boundChangeStops = stops => (dispatch, getState) => {
  return dispatch(changeStops(stops))
}

// FILTERS
export const ADD_FILTER_AIRLINE = "ADD_FILTER_AIRLINE"
export const addFilterAirline = code => {
  return {
    type: ADD_FILTER_AIRLINE,
    code: code
  }
}
export const boundAddFilterAirline = code => (dispatch, getState) => {
  if (getState().filters.airlines.indexOf(code) === -1) {
    return dispatch(addFilterAirline(code))
  }
}

export const REMOVE_FILTER_AIRLINE = "REMOVE_FILTER_AIRLINE"
export const removeFilterAirline = code => {
  return {
    type: REMOVE_FILTER_AIRLINE,
    code: code
  }
}
export const boundRemoveFilterAirline = code => (dispatch, getState) => {
  if (getState().filters.airlines.indexOf(code) >= 0 || code === constants.CHECK_ALL) {
    return dispatch(removeFilterAirline(code))
  }
}

export const ADD_FILTER_STOP = "ADD_FILTER_STOP"
export const addFilterStop = value => {
  return {
    type: ADD_FILTER_STOP,
    value: value
  }
}
export const boundAddFilterStop = value => (dispatch, getState) => {
  if (getState().filters.stops.indexOf(value) === -1) {
    return dispatch(addFilterStop(value))
  }
}

export const REMOVE_FILTER_STOP = "REMOVE_FILTER_STOP"
export const removeFilterStop = value => {
  return {
    type: REMOVE_FILTER_STOP,
    value: value
  }
}
export const boundRemoveFilterStop = value => (dispatch, getState) => {
  if (getState().filters.stops.indexOf(value) >= 0 || value === constants.CHECK_ALL) {
    return dispatch(removeFilterStop(value))
  }
}

export const ADD_FILTER_OUTBOUND_DEPARTURE_AIRPORT = "ADD_FILTER_OUTBOUND_DEPARTURE_AIRPORT"
export const addFilterOutboundDepartureAirport = code => {
  return {
    type: ADD_FILTER_OUTBOUND_DEPARTURE_AIRPORT,
    code: code
  }
}
export const boundAddFilterOutboundDepartureAirport = code => (dispatch, getState) => {
  if (getState().filters.outboundDepartureAirports.indexOf(code) === -1) {
    return dispatch(addFilterOutboundDepartureAirport(code))
  }
}

export const REMOVE_FILTER_OUTBOUND_DEPARTURE_AIRPORT = "REMOVE_FILTER_OUTBOUND_DEPARTURE_AIRPORT"
export const removeFilterOutboundDepartureAirport = code => {
  return {
    type: REMOVE_FILTER_OUTBOUND_DEPARTURE_AIRPORT,
    code: code
  }
}
export const boundRemoveFilterOutboundDepartureAirport = code => (dispatch, getState) => {
  if (getState().filters.outboundDepartureAirports.indexOf(code) >= 0) {
    return dispatch(removeFilterOutboundDepartureAirport(code))
  }
}

export const ADD_FILTER_OUTBOUND_ARRIVAL_AIRPORT = "ADD_FILTER_OUTBOUND_ARRIVAL_AIRPORT"
export const addFilterOutboundArrivalAirport = code => {
  return {
    type: ADD_FILTER_OUTBOUND_ARRIVAL_AIRPORT,
    code: code
  }
}
export const boundAddFilterOutboundArrivalAirport = code => (dispatch, getState) => {
  if (getState().filters.outboundArrivalAirports.indexOf(code) === -1) {
    return dispatch(addFilterOutboundArrivalAirport(code))
  }
}

export const REMOVE_FILTER_OUTBOUND_ARRIVAL_AIRPORT = "REMOVE_FILTER_OUTBOUND_ARRIVAL_AIRPORT"
export const removeFilterOutboundArrivalAirport = code => {
  return {
    type: REMOVE_FILTER_OUTBOUND_ARRIVAL_AIRPORT,
    code: code
  }
}
export const boundRemoveFilterOutboundArrivalAirport = code => (dispatch, getState) => {
  if (getState().filters.outboundArrivalAirports.indexOf(code) >= 0) {
    return dispatch(removeFilterOutboundArrivalAirport(code))
  }
}

export const ADD_FILTER_INBOUND_DEPARTURE_AIRPORT = "ADD_FILTER_INBOUND_DEPARTURE_AIRPORT"
export const addFilterInboundDepartureAirport = code => {
  return {
    type: ADD_FILTER_INBOUND_DEPARTURE_AIRPORT,
    code: code
  }
}
export const boundAddFilterInboundDepartureAirport = code => (dispatch, getState) => {
  if (getState().filters.inboundDepartureAirports.indexOf(code) === -1) {
    return dispatch(addFilterInboundDepartureAirport(code))
  }
}

export const REMOVE_FILTER_INBOUND_DEPARTURE_AIRPORT = "REMOVE_FILTER_INBOUND_DEPARTURE_AIRPORT"
export const removeFilterInboundDepartureAirport = code => {
  return {
    type: REMOVE_FILTER_INBOUND_DEPARTURE_AIRPORT,
    code: code
  }
}
export const boundRemoveFilterInboundDepartureAirport = code => (dispatch, getState) => {
  if (getState().filters.inboundDepartureAirports.indexOf(code) >= 0) {
    return dispatch(removeFilterInboundDepartureAirport(code))
  }
}

export const ADD_FILTER_INBOUND_ARRIVAL_AIRPORT = "ADD_FILTER_INBOUND_ARRIVAL_AIRPORT"
export const addFilterInboundArrivalAirport = code => {
  return {
    type: ADD_FILTER_INBOUND_ARRIVAL_AIRPORT,
    code: code
  }
}
export const boundAddFilterInboundArrivalAirport = code => (dispatch, getState) => {
  if (getState().filters.inboundArrivalAirports.indexOf(code) === -1) {
    return dispatch(addFilterInboundArrivalAirport(code))
  }
}

export const REMOVE_FILTER_INBOUND_ARRIVAL_AIRPORT = "REMOVE_FILTER_INBOUND_ARRIVAL_AIRPORT"
export const removeFilterInboundArrivalAirport = code => {
  return {
    type: REMOVE_FILTER_INBOUND_ARRIVAL_AIRPORT,
    code: code
  }
}
export const boundRemoveFilterInboundArrivalAirport = code => (dispatch, getState) => {
  if (getState().filters.inboundArrivalAirports.indexOf(code) >= 0) {
    return dispatch(removeFilterInboundArrivalAirport(code))
  }
}

export const CHANGE_FILTER_OUTBOUND_DEPARTURE_TIME = "CHANGE_FILTER_OUTBOUND_DEPARTURE_TIME"
export const changeFilterOutboundDepartureTime = time => {
  return {
    type: CHANGE_FILTER_OUTBOUND_DEPARTURE_TIME,
    time: time
  }
}
export const boundChangeFilterOutboundDepartureTime = time => (dispatch, getState) => {
  return dispatch(changeFilterOutboundDepartureTime(time))
}

export const CHANGE_FILTER_INBOUND_DEPARTURE_TIME = "CHANGE_FILTER_INBOUND_DEPARTURE_TIME"
export const changeFilterInboundDepartureTime = time => {
  return {
    type: CHANGE_FILTER_INBOUND_DEPARTURE_TIME,
    time: time
  }
}
export const boundChangeFilterInboundDepartureTime = time => (dispatch, getState) => {
  return dispatch(changeFilterInboundDepartureTime(time))
}

export const APPLY_FILTERS = "APPLY_FILTERS"
export const applyFilters = () => {
  return {
    type: APPLY_FILTERS
  }
}
export const boundApplyFilters = () => (dispatch, getState) => {
  return dispatch(applyFilters())
}

export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE"
// Resets the currently visible error message.
export const resetErrorMessage = () => ({
  type: RESET_ERROR_MESSAGE
})
