import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import muiThemeable from "material-ui/styles/muiThemeable"
import AnimateHeight from "react-animate-height"
import { withRerenderOnResize } from "components/commons/withRerenderOnResize"
import { isMobile } from "utils/flightsHelper"
import * as constants from "config/constants"

const Wrapper = styled.div`
  form {
    background-color: ${props => props.theme.searchBoxBgColor};
    padding: 16px 8px 1px 8px;
  }
  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px) {
    & {
      margin-bottom: 8px;
    }
    form {
      padding: 16px 16px 1px 16px;
    }
  }
  svg {
    color: ${props => props.theme.searchBoxTextColor} !important;
    fill: ${props => props.theme.searchBoxTextColor} !important;
  }
  .autocomplete-menu-section {
    svg {
      color: #aaa !important;
      fill: #aaa !important;
    }
  }
  .autocomplete-menu-section:hover,
  .no-iata-code:hover {
    background: #fff !important;
    cursor: default !important;
  }
  .input-group-addon,
  .input-group-addon svg,
  .input-group-addon svg path {
    color: ${props => props.theme.searchBoxLabelsColor} !important;
    fill: ${props => props.theme.searchBoxLabelsColor} !important;
  }
  a {
    color: ${props => props.theme.searchBoxTextColor};
    svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px;
      vertical-align: text-top;
    }
  }
  label {
    color: ${props => props.theme.searchBoxTextColor} !important;
  }
  label + a {
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.17);
    margin: 0 0 6px 0;
  }
`

class SearchBox extends React.PureComponent {
  render() {
    const searchBox = <Wrapper theme={this.props.muiTheme.palette}>{this.props.children}</Wrapper>

    if (isMobile()) {
      return (
        <AnimateHeight duration={constants.ANIMATIONS_DURATION} height={this.props.open ? "auto" : "0"}>
          {searchBox}
        </AnimateHeight>
      )
    }
    return searchBox
  }
}

export default muiThemeable()(withRerenderOnResize(SearchBox))

SearchBox.propTypes = {
  open: PropTypes.bool.isRequired
}
