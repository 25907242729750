import React from "react"
import PropTypes from "prop-types"

class Icon extends React.PureComponent {
  render() {
    const { icon, size, style, color, dir, suffix } = this.props

    if (typeof icon === "string") {
      return (
        <img
          style={{ display: "inline-block", verticalAlign: "text-bottom", width: size, height: size, ...style }}
          src={`https://statics.basset.la/${dir}/${icon}${suffix}.svg`}
          alt={icon}
        />
      )
    }

    const _style = { display: "inline-block", verticalAlign: "top", ...style }

    if (!icon) {
      return null
    }

    return (
      <svg style={_style} width={size} height={size} viewBox="0 0 1024 1024">
        {icon.p.map((path, i) => {
          let _path_style = icon.a ? { ...icon.a[i] } : {}
          if (_style.fill) {
            _path_style.fill = _style.fill
          }
          if (_style.stroke) {
            _path_style.stroke = _style.stroke
          }
          if (color) {
            _path_style.fill = Array.isArray(color) ? (color[i] ? color[i] : _path_style.fill) : color
          }
          return <path key={i} style={_path_style} d={path} />
        })}
      </svg>
    )
  }
}

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  size: PropTypes.number,
  style: PropTypes.object,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dir: PropTypes.string,
  suffix: PropTypes.string
}

Icon.defaultProps = {
  size: 16,
  style: {},
  dir: "airlines",
  suffix: "-ISO"
}

export default Icon
