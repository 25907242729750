import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M366.933 42.667v149.248c145.493 143.147 286.293 287.232 431.787 428.032 9.387-14.080 28.16-23.467 37.547-42.24v-262.827c0-52.565-61.013-76.971-93.867-91.989v-180.224h-93.867v187.733h-187.733v-187.733h-93.867zM145.408 89.6c-20.181 19.712-39.893 39.893-60.075 59.605l187.733 187.733v245.461c54.912 53.973 109.355 109.355 164.267 164.267v140.8h234.667v-140.8c10.325-12.672 21.12 15.488 31.445 20.651 56.32 56.32 107.947 112.64 164.267 168.96 23.467-18.773 42.24-42.24 61.013-61.013-262.827-262.827-525.653-520.96-783.787-783.787l0.469-1.877z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }]
}
export default class NoPlug extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
