import { CALL_API } from "../middleware/api"
import { stringify } from "query-string"
import { getClientConfig } from "utils/flightsHelper"

const baseParams = () => {
  const config = getClientConfig()
  return { site: config.site, channel: config.channel, language: "es", entities: "CITY,AIRPORT" }
}

export const ORIGINS_REQUEST = "ORIGINS_REQUEST"
export const ORIGINS_SUCCESS = "ORIGINS_SUCCESS"
export const ORIGINS_FAILURE = "ORIGINS_FAILURE"
export const fetchOrigins = filters => {
  filters = "?" + stringify({ ...filters, ...baseParams() })

  return {
    [CALL_API]: {
      types: [ORIGINS_REQUEST, ORIGINS_SUCCESS, ORIGINS_FAILURE],
      endpoint: `${process.env.REACT_APP_AUTOCOMPLETE_API_URI}/autocomplete${filters}`
    }
  }
}
export const boundFetchOrigins = q => dispatch => {
  return dispatch(fetchOrigins({ q: q }))
}

export const DESTINATIONS_REQUEST = "DESTINATIONS_REQUEST"
export const DESTINATIONS_SUCCESS = "DESTINATIONS_SUCCESS"
export const DESTINATIONS_FAILURE = "DESTINATIONS_FAILURE"
export const fetchDestinations = filters => {
  filters = "?" + stringify({ ...filters, ...baseParams() })

  return {
    [CALL_API]: {
      types: [DESTINATIONS_REQUEST, DESTINATIONS_SUCCESS, DESTINATIONS_FAILURE],
      endpoint: `${process.env.REACT_APP_AUTOCOMPLETE_API_URI}/autocomplete${filters}`
    }
  }
}
export const boundFetchDestinations = q => dispatch => {
  return dispatch(fetchDestinations({ q: q }))
}

export const CLEAR_LOCATIONS = "CLEAR_LOCATIONS"
export const clearLocations = where => {
  return {
    type: CLEAR_LOCATIONS,
    where: where
  }
}
export const boundClearLocations = where => (dispatch, getState) => {
  if (getState().location[where].length) {
    return dispatch(clearLocations(where))
  }
}

export const SHOW_LOCATIONS_PLACEHOLDER = "SHOW_LOCATIONS_PLACEHOLDER"
export const showLocationsPlaceholder = where => {
  return {
    type: SHOW_LOCATIONS_PLACEHOLDER,
    where: where
  }
}
export const boundShowLocationsPlaceholder = where => (dispatch, getState) => {
  return dispatch(showLocationsPlaceholder(where))
}

export const PRELOAD_LOCATIONS_REQUEST = "PRELOAD_LOCATIONS_REQUEST"
export const PRELOAD_LOCATIONS_SUCCESS = "PRELOAD_LOCATIONS_SUCCESS"
export const PRELOAD_LOCATIONS_FAILURE = "PRELOAD_LOCATIONS_FAILURE"
export const preloadLocations = iataCodes => {
  const querystring = "?" + stringify({ iata_code: iataCodes.join(","), language: "es" })
  return {
    [CALL_API]: {
      types: [PRELOAD_LOCATIONS_REQUEST, PRELOAD_LOCATIONS_SUCCESS, PRELOAD_LOCATIONS_FAILURE],
      endpoint: `${process.env.REACT_APP_GEO_API_URI}/geo/entities${querystring}`
    }
  }
}

export const boundPreloadLocations = iataCodes => (dispatch, getState) => {
  return dispatch(preloadLocations(iataCodes))
}
