import React from "react"
import styled from "styled-components"
import { InputGroup } from "react-bootstrap"
import muiThemeable from "material-ui/styles/muiThemeable"
import _ from "underscore"
import * as constants from "config/constants"

const Wrapper = styled.div`
  min-width: ${props => props.wrapperWidth}px;
  text-align: left;
  white-space: normal;

  @media (min-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}px) and (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MEDIUM_SCREEN_MAX}px) {
    text-align: center;
  }

  svg {
    margin-right: 3px;
    vertical-align: text-bottom !important;
    width: 18px !important;
    height: 100% !important;
    color: ${props => props.theme.searchBoxLabelsColor} !important;
    fill: ${props => props.theme.searchBoxLabelsColor} !important;
  }
  _:-ms-lang(x),
  svg {
    height: 20px !important;
  }
`

class InputGroupAddon extends React.PureComponent {
  render() {
    let props = { ...this.props }
    delete props.muiTheme

    let width = 75
    if (this.props.style && this.props.style.width && _.isNumber(this.props.style.width)) {
      width = this.props.style.width - 13
    }

    return (
      <InputGroup.Addon {...props}>
        <Wrapper theme={this.props.muiTheme.palette} wrapperWidth={width}>
          {this.props.children}
        </Wrapper>
      </InputGroup.Addon>
    )
  }
}

export default muiThemeable()(InputGroupAddon)
