import React from "react"
import PropTypes from "prop-types"
import FloatingActionButton from "material-ui/FloatingActionButton"
import KeyboardArrowRightIcon from "material-ui/svg-icons/hardware/keyboard-arrow-right"
import KeyboardArrowLeftIcon from "material-ui/svg-icons/hardware/keyboard-arrow-left"
import Icon from "components/commons/Icon"
import StyledSliderTable from "components/commons/styled/SliderTable"

class SliderTable extends React.PureComponent {
  state = {
    page: 1,
    hoverX: undefined,
    hoverY: undefined
  }

  constructor(props) {
    super(props)

    this.pagesQty = 0
    if (props.dataSource.length) {
      this.pagesQty = Math.ceil((props.dataSource[0].length - props.columnsToLock) / props.columnsPerPage)
    }
  }

  prevPage = event => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 })
    }
  }

  nextPage = event => {
    if (this.props.dataSource.length && this.state.page < this.pagesQty) {
      this.setState({ page: this.state.page + 1 })
    }
  }

  handleMouseOver = (event, x, y) => {
    this.setState({
      hoverX: x,
      hoverY: y
    })
  }

  handleMouseOut = e => {
    this.setState({
      hoverX: undefined,
      hoverY: undefined
    })
  }

  handleClick = (event, cell, x, y) => {
    const { dataSource } = this.props
    this.props.onCellClick(cell, x, y, dataSource)
  }

  render() {
    const { dataSource, columnsPerPage, columnsToLock } = this.props
    const { hoverX, hoverY } = this.state

    if (!dataSource || !dataSource.length) {
      return null
    }

    return (
      <StyledSliderTable>
        <table className="table table-bordered table-condensed" onMouseOut={this.handleMouseOut}>
          <tbody>
            {dataSource.map((row, x) => (
              <tr key={x}>
                {row.map((cell, y) => {
                  let style = {
                    width: 100 / (columnsPerPage + columnsToLock) + "%"
                  }
                  let col = y + 1
                  let min = columnsPerPage * (this.state.page - 1) + columnsToLock + 1
                  let max = columnsPerPage * this.state.page + columnsToLock

                  if (max > row.length) {
                    min -= max - row.length - columnsToLock + 1
                    max -= max - row.length
                  }

                  if (col > columnsToLock && (col < min || col > max)) {
                    style.display = "none"
                  }

                  let classNames = []
                  if (col <= columnsToLock) {
                    classNames.push("locked")
                  }
                  if (cell.highlighted) {
                    classNames.push("highlighted")
                  }
                  if (
                    ((x === hoverX && y === hoverY) ||
                      (hoverX === 0 && hoverY === y) ||
                      (hoverY === 0 && hoverX === x)) &&
                    !(hoverX === 0 && hoverY === 0) &&
                    (!cell.empty || hoverX !== x || hoverY !== y)
                  ) {
                    classNames.push("hovered")
                  }
                  if (!cell.empty) {
                    classNames.push("clickable")
                  }

                  const icon = cell.icon ? <Icon icon={cell.icon} /> : ""

                  return (
                    <td
                      key={y}
                      style={style}
                      className={classNames.join(" ")}
                      onMouseOver={e => {
                        this.handleMouseOver(e, x, y)
                      }}
                      onClick={e => {
                        if (!cell.empty) this.handleClick(e, cell, x, y)
                      }}
                    >
                      {icon} {cell.label}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {columnsPerPage < dataSource[0].length && [
          <FloatingActionButton
            key={0}
            backgroundColor="#ffffff"
            disabledColor="#f7f7f7"
            disabled={this.state.page === 1}
            onClick={this.prevPage}
            style={{ left: (100 / (columnsPerPage + columnsToLock)) * columnsToLock + "%" }}
          >
            <KeyboardArrowLeftIcon />
          </FloatingActionButton>,
          <FloatingActionButton
            key={1}
            backgroundColor="#ffffff"
            disabledColor="#f7f7f7"
            disabled={this.state.page === this.pagesQty}
            onClick={this.nextPage}
          >
            <KeyboardArrowRightIcon />
          </FloatingActionButton>
        ]}
      </StyledSliderTable>
    )
  }
}

export default SliderTable

SliderTable.propTypes = {
  dataSource: PropTypes.array.isRequired,
  columnsPerPage: PropTypes.number,
  columnsToLock: PropTypes.number,
  onCellClick: PropTypes.func
}

SliderTable.defaultProps = {
  columnsPerPage: 5,
  columnsToLock: 1
}
