import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M981.333 512c0 259.206-210.128 469.333-469.333 469.333s-469.333-210.128-469.333-469.333c0-259.206 210.128-469.333 469.333-469.333s469.333 210.128 469.333 469.333z",
    "M487.105 716.058c-48.43 0-87.337-38.363-87.337-85.432 0-44.077 28.024-75.093 76.182-84.888s97.948-32.921 125.7-70.196c10.611 35.098 15.78 72.1 15.78 109.919 0 71.828-58.225 130.597-130.325 130.597v0zM535.807 217.34c0 0 20.134 72.1 20.134 130.597 0 56.048-36.73 101.485-92.778 101.485-56.32 0-98.764-45.437-98.764-101.485l0.816-10.067c-54.96 65.299-87.881 149.915-87.881 242.149 0 120.211 97.451 217.662 217.662 217.662s217.662-97.451 217.662-217.662c0-146.922-70.468-277.519-176.85-362.679z"
  ],
  a: [
    {
      fill: "rgb(255, 255, 255)",
      stroke: "rgb(208, 2, 27)",
      strokeLinejoin: "miter",
      strokeLinecap: "butt",
      strokeMiterlimit: "4",
      strokeWidth: 42.666666666666664
    },
    { fill: "rgb(208, 2, 27)" }
  ]
}
export default class Flame extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
