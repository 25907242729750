import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M646.5 699.68l84.149-353.276c0.319-1.344 0.534-2.687 0.748-4.031 5.877-16.767 3.577-36.068-7.859-51.369-18.252-24.436-52.848-29.436-77.284-11.184l-129.775 96.983-176.69 29.694c-30.080 5.055-50.357 33.528-45.308 63.602 4.534 26.976 27.915 46.074 54.387 46.074 3.037 0 6.123-0.252 9.215-0.773l189.881-31.909c8.675-1.46 16.865-4.963 23.908-10.221l18.289-13.669-40.982 172.070h-251.139c-21.209 0-40.547 12.147-49.749 31.258l-114.812 238.458c-13.227 27.479-1.681 60.473 25.798 73.707 7.724 3.718 15.884 5.479 23.908 5.479 20.553 0 40.283-11.522 49.798-31.277l99.75-207.194h260.054c25.565 0 47.786-17.552 53.713-42.424z",
    "M684.293 248.479c55.136 0 99.836-44.7 99.836-99.836 0-55.142-44.7-99.842-99.836-99.842-55.142 0-99.836 44.7-99.836 99.842 0 55.136 44.694 99.836 99.836 99.836z",
    "M768.069 404.151c-31.884 123.278-63.78 246.556-95.664 369.841h-262.090c-59.222 0-59.314 92.026 0 92.026h297.729c19.939 0 39.32-14.264 44.363-33.786 34.804-134.536 69.602-269.078 104.401-403.614 14.841-57.375-73.897-81.854-88.738-24.467z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }, { fill: "rgb(74, 74, 74)" }, { fill: "rgb(74, 74, 74)" }]
}
export default class Premium extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
