// @flow

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { selectOrigin } from "actions"
import { boundFetchOrigins, boundClearLocations } from "actions/location"
import { Row, Col } from "react-bootstrap"
import LocationAutocomplete from "components/commons/LocationAutocomplete"
import SearchBoxDateRangePicker from "components/flights/search/SearchBoxDateRangePicker"
import SearchBoxNumberField from "components/flights/search/SearchBoxNumberField"
import * as constants from "config/constants"
import moment from "moment"
import Icon from "components/commons/Icon"
import { Grid } from "react-bootstrap"
import EntertainmentIcon from "components/commons/svg-icons/Basset/entertainment"
import FlameIcon from "components/commons/svg-icons/Basset/flame"
import RecommendedIcon from "components/commons/svg-icons/Basset/recommended"
import HapagLloydIcon from "components/commons/svg-icons/Basset/hapag-lloyd"
import NightFlightIcon from "components/commons/svg-icons/Basset/night-flight"
import NoPlugIcon from "components/commons/svg-icons/Basset/no-plug"
import NoWifiIcon from "components/commons/svg-icons/Basset/no-wifi"
import PlugIcon from "components/commons/svg-icons/Basset/plug"
import WifiIcon from "components/commons/svg-icons/Basset/wifi"

var timeout

export class ComponentTestContainer extends React.PureComponent {
  handleLocationAutocompleteUpdateInput = value => {
    if (value.length >= 3) {
      if (timeout) clearTimeout(timeout)

      timeout = setTimeout(() => {
        this.props.fetchLocations(value)
      }, 300)
    } else {
      this.props.clearLocations()
    }
  }

  handleSelectOrigin = item => {
    this.props.selectOrigin(item)
  }

  handleClearOrigin = () => {
    console.log("clear origin")
  }

  handleSelectDateRange = () => {
    alert("Date range selected")
  }

  handleChangeNumber = val => {
    alert("Number would change to: " + val)
  }

  render() {
    const { locations } = this.props

    return (
      <Grid className="main-container">
        <Row>
          <Col md={6} mdOffset={3}>
            <h1>Testing isolated componentes</h1>

            <h3>&lt;LocationAutocomplete&gt;</h3>
            <LocationAutocomplete
              label={"Isolated 1"}
              placeholder={"Type 3 chars to search for locations"}
              dataSource={locations}
              onUpdateInput={this.handleLocationAutocompleteUpdateInput}
              onSelectItem={this.handleSelectOrigin}
              onClear={this.handleClearOrigin}
            />
            <br />
            <LocationAutocomplete
              label={"Isolated 2"}
              placeholder={"This doesn't work if you type"}
              dataSource={[]}
              onUpdateInput={() => {}}
              onSelectItem={() => {}}
              onClear={() => {}}
            />

            <h2>Used inside search box (for now):</h2>
            <h3>&lt;SearchBoxDateRangePicker&gt;</h3>
            <SearchBoxDateRangePicker
              flightType={constants.ROUND_TRIP}
              label={"Isolated date range picker with return"}
              selectedDateFrom={moment()}
              selectedDateTo={moment().add(10, "d")}
              onSelectRange={this.handleSelectDateRange}
            />
            <br />
            <SearchBoxDateRangePicker
              flightType={constants.ONE_WAY}
              label={"Isolated date range picker with no return"}
              selectedDateFrom={moment()}
              onSelectRange={this.handleSelectDateRange}
            />

            <h3>&lt;SearchBoxNumberField&gt;</h3>
            <SearchBoxNumberField
              label={"Isolated Number"}
              hint={"some kind of hint here"}
              textFieldId="isolatedNum1"
              selectedNumber={123}
              onChangeNumber={this.handleChangeNumber}
            />

            <h3>&lt;Icon&gt;</h3>
            <Icon icon={"VS"} size={80} />
            <Icon icon={"AA"} />
            <EntertainmentIcon size={50} color={"#ff0000"} />
            <FlameIcon />
            <FlameIcon color={[false, "#000000"]} style={{ stroke: "blue" }} />
            <RecommendedIcon />

            <HapagLloydIcon />
            <NightFlightIcon />
            <NoPlugIcon />
            <NoWifiIcon />
            <PlugIcon />
            <WifiIcon />
          </Col>
        </Row>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location } = state

  return {
    // complementary
    locations: location.data
  }
}

export default connect(
  mapStateToProps,
  {
    // search vars
    selectOrigin,
    // complementary
    fetchLocations: boundFetchOrigins,
    clearLocations: boundClearLocations
  }
)(ComponentTestContainer)

ComponentTestContainer.propTypes = {
  // complementary
  locations: PropTypes.array.isRequired,

  // actions: search vars
  selectOrigin: PropTypes.func.isRequired,
  // actions: complementary
  fetchLocations: PropTypes.func.isRequired,
  clearLocations: PropTypes.func.isRequired
}
