import React from "react"
import styled from "styled-components"
import muiThemeable from "material-ui/styles/muiThemeable"
import * as constants from "config/constants"

const Wrapper = styled.div`
  position: relative;
  height: 36px;
  button {
    position: absolute !important;
    padding: 0 !important;
    z-index: 3;
    width: 44px !important;
    height: 100% !important;
    svg {
      width: 17px !important;
      height: 17px !important;
      color: ${props => props.theme.clickableColor} !important;
      fill: ${props => props.theme.clickableColor} !important;
    }
  }
  button:first-child {
    left: 0;
    top: 0;
  }
  button:last-child {
    right: 0;
    top: 0;
  }

  .input-group-addon + & {
    > div {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  @media (min-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}px) and (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MEDIUM_SCREEN_MAX}px) {
    height: 48px;
  }
`

class NumberField extends React.PureComponent {
  render() {
    return <Wrapper theme={this.props.muiTheme.palette}>{this.props.children}</Wrapper>
  }
}

export default muiThemeable()(NumberField)
