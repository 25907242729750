import React from "react"
import PropTypes from "prop-types"
import TransitionGroup from "react-transition-group/TransitionGroup"
import CSSTransition from "react-transition-group/CSSTransition"
import FlightsResultsPlaceholder from "components/flights/results/placeholders/FlightsResultsPlaceholder"
import ClusterList from "components/flights/results/ClusterList"
import * as constants from "config/constants"
//import FlightsResultsOptions from 'components/flights/results/FlightsResultsOptions'
class FlightsResults extends React.PureComponent {
  handlePaginatedSearch = () => {
    this.props.onPaginatedSearch()
  }

  list = () => {
    const { clusters, flightType, isLoading, financial, applications } = this.props

    if (isLoading && !clusters.length) {
      return (
        <CSSTransition classNames="flights" timeout={500} key={0}>
          <FlightsResultsPlaceholder />
        </CSSTransition>
      )
    } else if (!flightType) {
      return null
    }

    // TODO: Comentamos esto por el momento ya que el switch de currency y el order by select por ahora no los mostramos
    // <FlightsResultsOptions />

    return (
      <CSSTransition classNames="flights" timeout={500} key={1}>
        <ClusterList
          clusters={clusters}
          flightType={flightType}
          onPaginatedSearch={this.handlePaginatedSearch}
          isLoading={isLoading}
          applications={applications}
          financial={financial}
        />
      </CSSTransition>
    )
  }

  render() {
    return <TransitionGroup>{this.list()}</TransitionGroup>
  }
}

export default FlightsResults

FlightsResults.propTypes = {
  clusters: PropTypes.array.isRequired,
  flightType: PropTypes.oneOf(constants.FLIGHT_TYPES),
  isLoading: PropTypes.bool.isRequired,
  applications: PropTypes.object,
  onPaginatedSearch: PropTypes.func.isRequired,
  financial: PropTypes.object
}
