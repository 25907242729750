import React from "react"
import { DayPickerRangeController } from "react-dates"
import moment from "moment"

import { datepickerStyle } from "./datepickerStyle"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

const MAX_DAYS = 330

class RangeDatePicker extends React.Component {
  static defaultProps = {
    minDate: moment(),
    maxDate: moment().add(MAX_DAYS, "days"),
    selectedFromDate: null,
    selectedToDate: null
  }

  getOrientation = () => {
    const { mobile } = this.props
    return mobile ? "verticalScrollable" : "horizontal"
  }

  getNumberOfMonths = () => {
    const { mobile } = this.props
    return mobile ? 12 : 2
  }

  superIsOutisdeRange = day => {
    const { minDate, maxDate } = this.props
    return day.isBefore(minDate) || day.isAfter(maxDate)
  }

  isOutsideRange = (day: Moment): boolean => {
    const { maximumNights, focusedInput, selectedFromDate, maxDate, selectedToDate, minDate } = this.props

    if (maximumNights) {
      const maximumEndDate = selectedFromDate ? selectedFromDate.clone().add(maximumNights, "days") : maxDate
      const minimumDate = selectedToDate ? selectedToDate : minDate
      if (focusedInput === "startDate") {
        return day.isBefore(minimumDate)
      }

      if (focusedInput === "endDate") {
        return !day.isAfter(minimumDate) || day.isAfter(maximumEndDate)
      }
    }

    return this.superIsOutisdeRange(day)
  }

  render() {
    const {
      onDatesChange,
      colors,
      onFocusChange,
      selectedFromDate,
      selectedToDate,
      focusedInput,
      minimumNights
    } = this.props

    return (
      <div className={`${datepickerStyle(colors)}`}>
        <DayPickerRangeController
          startDate={selectedFromDate}
          endDate={selectedToDate}
          focusedInput={focusedInput}
          minimumNights={minimumNights}
          numberOfMonths={this.getNumberOfMonths()}
          onFocusChange={onFocusChange}
          orientation={this.getOrientation()}
          isOutsideRange={this.isOutsideRange}
          onDatesChange={onDatesChange}
          hideKeyboardShortcutsPanel={true}
        />
      </div>
    )
  }
}

export default RangeDatePicker
