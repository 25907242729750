import React from "react"
import PropTypes from "prop-types"
import muiThemeable from "material-ui/styles/muiThemeable"
import { Clearfix, Col } from "react-bootstrap"
import FlatButton from "material-ui/FlatButton"
//import SwapVertIcon from 'material-ui/svg-icons/action/swap-vert'
import TuneIcon from "material-ui/svg-icons/image/tune"
import { withTranslation } from "react-i18next"

class FlightsFiltersMobileBar extends React.PureComponent {
  handleToggle = () => {
    this.props.onToggle()
  }

  render() {
    const { muiTheme, t } = this.props

    const color = muiTheme.palette.clickableColor

    const styles = {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      backfaceVisibility: "hidden",
      boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)",
      height: 43,
      lineHeight: "43px",
      backgroundColor: "#fff",
      color: color
    }
    const iconStyles = {
      color: color,
      fill: color,
      width: 20,
      height: 20,
      marginRight: 8,
      verticalAlign: "middle",
      marginTop: -2
    }

    return (
      <div style={styles}>
        <Clearfix>
          {/*<Col xs={6}>
            <FlatButton fullWidth={true} style={{ color: color }}>
              <SwapVertIcon style={iconStyles} />
              <strong>Ordenar</strong>
            </FlatButton>
          </Col>*/}
          <Col xs={12}>
            <FlatButton onClick={this.handleToggle} fullWidth={true} style={{ color: color }}>
              <TuneIcon style={{ ...iconStyles, transform: "rotate(270deg)" }} />
              <strong>{t("Filtrar")}</strong>
            </FlatButton>
          </Col>
        </Clearfix>
      </div>
    )
  }
}

export default muiThemeable()(withTranslation()(FlightsFiltersMobileBar))

FlightsFiltersMobileBar.propTypes = {
  onToggle: PropTypes.func.isRequired
}
