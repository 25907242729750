import React from "react"
import styled from "styled-components"
import muiThemeable from "material-ui/styles/muiThemeable"
import { Tabs as MuiTabs } from "material-ui/Tabs"

const Wrapper = styled.div`
  > div {
    > div:first-child {
      background-color: #eaeaea !important;
      button {
        color: #4a4a4a !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        text-transform: none !important;
        padding: 8px 0 !important;
        > div > div {
          height: 16px !important;
        }
      }
      .active {
        background-color: #fff !important;
        color: ${props => props.theme.selectionColor} !important;
      }
    }
    > div:nth-child(2) {
      background-color: #eaeaea !important;
      > div {
        height: 3px !important;
        background-color: ${props => props.theme.selectionColor} !important;
      }
    }
    > div:nth-child(3) {
      background-color: #fff;
      table {
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        margin: 0;
        font-size: 12px;
        text-align: center;
        td {
          padding: 4px 8px 3px 8px;
          border-color: ${props => props.theme.borderColor};
        }
      }
    }
  }
`

class Tabs extends React.PureComponent {
  render() {
    let props = { ...this.props }
    delete props.muiTheme

    return (
      <Wrapper theme={this.props.muiTheme.palette}>
        <MuiTabs {...props} />
      </Wrapper>
    )
  }
}

export default muiThemeable()(Tabs)
