import { isMobile } from "utils/flightsHelper"

const suggestionsContainerOpen = isMobile()
  ? {
      display: "block",
      position: "fixed",
      top: 52,
      left: 0,
      width: "100%",
      backgroundColor: "#fff",
      zIndex: 3000
    }
  : {
      display: "block",
      position: "absolute",
      top: 34,
      width: 280,
      border: "1px solid #aaa",
      backgroundColor: "#fff",
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      zIndex: 3000
    }

export const theme = {
  container: {
    position: "relative"
  },
  input: {
    width: "100%"
  },
  suggestionsContainer: {
    display: "none"
  },
  suggestionsContainerOpen: suggestionsContainerOpen,
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  suggestion: {
    cursor: "pointer"
  },
  suggestionHighlighted: {
    backgroundColor: "#d8e3f9"
  }
}

export const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#fff",
    zIndex: 3000
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 0,
    padding: 0,
    overflow: "hidden",
    outline: "none",
    height: "100%",
    borderRadius: 0
  }
}
