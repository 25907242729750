import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Row, Col } from "react-bootstrap"
import * as constants from "config/constants"

const Wrapper = styled.div`
  > div {
    padding: 16px;
  }
  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px) {
    padding: 8px;

    > div {
      padding: 16px 0;
    }
  }
`

class ResultMessage extends React.PureComponent {
  render() {
    const { title, text, titleColor, img, bgColor } = this.props

    return (
      <Wrapper>
        <Row style={{ backgroundColor: bgColor, borderRadius: 3 }}>
          <Col xs={4} sm={4} md={1}>
            <img src={img} style={{ maxWidth: "100%" }} alt="result message" />
          </Col>
          <Col xs={8} sm={8} md={11}>
            <strong style={{ fontSize: 16, color: titleColor }}>{title}</strong>
            <p style={{ fontSize: 14 }}>{text}</p>
          </Col>
        </Row>
      </Wrapper>
    )
  }
}

export default ResultMessage

ResultMessage.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired
}
