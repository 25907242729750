import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import muiThemeable from "material-ui/styles/muiThemeable"
import { stopLabel } from "utils/flightsHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"

const Wrapper = styled.div`
  text-align: center;
  color: ${props => props.color};
  font-size: 12px;
  > div {
    border-bottom: 2px solid ${props => props.color};
    height: 1px;
    margin-top: 1px;
    > span {
      display: inline-block;
      border: 1px solid ${props => props.color};
      border-radius: 50%;
      width: 6px;
      height: 6px;
      background-color: #fff;
      position: relative;
      top: -12px;
      margin: 0 ${props => 6 - (props.stopsQty > 3 ? 3 : props.stopsQty)}px;
    }
  }

  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MAX}px) {
    padding-top: 9px;
    > span {
      display: none;
    }
  }
`

class Stops extends React.PureComponent {
  render() {
    const { stopsQty, t } = this.props

    return (
      <Wrapper color={stopsQty === 0 ? "#5ea50f" : this.props.muiTheme.palette.highlightedColor} stopsQty={stopsQty}>
        <span>{stopLabel(stopsQty, t)}</span>
        <div>{stopsQty > 0 && [...Array(stopsQty)].map((x, i) => <span key={i} />)}</div>
      </Wrapper>
    )
  }
}

export default muiThemeable()(withTranslation()(Stops))

Stops.propTypes = {
  stopsQty: PropTypes.number.isRequired
}
