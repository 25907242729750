import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M298.667 473.6h-247.040v70.4h247.040v105.813l140.373-140.8-140.373-141.227zM490.667 814.933h42.667v-605.867h-42.667zM987.733 508.587l-140.373-140.8v105.813h-247.040v70.4h247.040v105.813z"
  ],
  a: [{}]
}
export default class PlaneChange extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
