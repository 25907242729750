import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M512 890.667l153.6-203.378c-42.667-31.778-96-50.844-153.6-50.844s-110.933 19.067-153.6 50.844l153.6 203.378zM512 128c-172.8 0-332.373 56.776-460.8 152.533l76.8 101.689c106.667-79.656 239.787-127.111 384-127.111s277.333 47.455 384 127.111l76.8-101.689c-128.427-95.757-288-152.533-460.8-152.533v0zM512 382.222c-115.2 0-221.44 37.71-307.2 101.689l76.8 101.689c64-47.879 143.787-76.267 230.4-76.267s166.4 28.388 230.4 76.267l76.8-101.689c-85.76-63.979-192-101.689-307.2-101.689z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }]
}
export default class Wifi extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
