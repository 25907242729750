import React from "react"
import styled from "styled-components"
import muiThemeable from "material-ui/styles/muiThemeable"
import LastSeats from "components/flights/results/LastSeats"
import LegStop from "components/flights/results/leg/LegStop"
import LegTechnicalStop from "components/flights/results/leg/LegTechnicalStop"
import ClaseIcon from "components/commons/svg-icons/Basset/clase"
import { Row, Col } from "react-bootstrap"
import Icon from "components/commons/Icon"
import { Tooltip, OverlayTrigger } from "react-bootstrap"
import { duration, city, airport } from "utils/flightsHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"

const Wrapper = styled.div`
  @media (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

Wrapper.displayName = "Wrapper"

class Leg extends React.PureComponent {
  render() {
    const { leg, nextLeg, muiTheme, t } = this.props

    const iconStyle = { width: 20, height: 20, verticalAlign: "text-bottom", marginRight: 4 }

    let cabinType = { color: "#4a4a4a", icon: ClaseIcon }
    if (leg.cabin_type && leg.cabin_type.code in constants.CABIN_TYPES) {
      cabinType = constants.CABIN_TYPES[leg.cabin_type.code]
    }

    return (
      <div>
        <Wrapper>
          <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
            <Col xs={1} style={{ textAlign: "center" }}>
              <Icon icon={leg.marketing_carrier.code} />
            </Col>
            <Col xs={11} sm={11} md={8}>
              <p>
                <strong>
                  {leg.departure_time} - {leg.arrival_time}
                </strong>
              </p>
              <p>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="legOriginTooltip">
                      {t("Aeropuerto")} {airport(leg.origin.name)}
                    </Tooltip>
                  }
                >
                  <strong style={{ color: muiTheme.palette.highlightedColor }}>{leg.origin.code}</strong>
                </OverlayTrigger>{" "}
                {city(leg.origin.name)} -{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="legOriginTooltip">
                      {t("Aeropuerto")} {airport(leg.destination.name)}
                    </Tooltip>
                  }
                >
                  <strong style={{ color: muiTheme.palette.highlightedColor }}>{leg.destination.code}</strong>
                </OverlayTrigger>{" "}
                {city(leg.destination.name)}
              </p>
              <p>
                {leg.marketing_carrier.name} {leg.marketing_carrier.code}
                {leg.flight_number} • {leg.aircraft_type}
                {leg.marketing_carrier.code !== leg.operating_carrier.code
                  ? ` • ${t("Operado por")} ${leg.operating_carrier.name}`
                  : null}
              </p>
            </Col>
            <Col xs={11} sm={11} xsOffset={1} smOffset={1} md={3} mdOffset={0}>
              <p>
                <strong>{duration(leg.duration, " ")}</strong>
              </p>
              {leg.cabin_type ? (
                <div>
                  <p style={{ color: cabinType.color, fontWeight: 400, marginLeft: -2 }}>
                    <cabinType.icon color={cabinType.color} style={iconStyle} />
                    {leg.cabin_type.name}
                  </p>
                  <LastSeats leg={leg} />
                </div>
              ) : null}
            </Col>
          </Row>
        </Wrapper>
        {leg.technical_stops
          ? leg.technical_stops.map((stop, i) => <LegTechnicalStop technicalStop={stop} key={i} />)
          : null}
        <LegStop leg={leg} nextLeg={nextLeg} />
      </div>
    )
  }
}

export default muiThemeable()(withTranslation()(Leg))
