import {
  cyan500,
  cyan700,
  pinkA200,
  grey100, // grey300,
  grey400,
  grey500,
  white,
  darkBlack,
  fullBlack
} from "material-ui/styles/colors"
import { fade } from "material-ui/utils/colorManipulator"
import spacing from "material-ui/styles/spacing"
import moment from "moment"
import * as constants from "config/constants"
import ls from "localstorage-ttl"
import packageJson from "../../package.json"

const fetchAsync = async (url, options) => {
  const response = await fetch(url, options)
  return await response.json()
}

const getClientConfigCacheKey = () => {
  const domain = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_DOMAIN : window.location.hostname
  return "theme_" + domain
}

const getAdvertisingCacheKey = () => {
  const domain = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_DOMAIN : window.location.hostname
  return "advertising_" + packageJson.version + domain
}

const configToTheme = data => {
  const namedColors = {
    // app colors
    selectionColor: data.brand_colors.selection_color, // selección
    highlightedColor: data.brand_colors.highlight_color, // destacado
    clickableColor: data.brand_colors.click_color, // clickeable
    bgColor: data.brand_colors.background_color, // background
    // searchbox colors
    searchBoxLabelsColor: data.searchbox_colors.selection_color, // labels
    searchBoxBgColor: data.searchbox_colors.highlight_color, // background
    searchBoxClickableColor: data.searchbox_colors.click_color, // clickeable
    searchBoxTextColor: data.searchbox_colors.background_color // texto
  }

  const clientTheme = {
    // these 4 are to config the muiTheme as well
    primary1Color: "#4a90e2", //namedColors.selectionColor,
    primary2Color: namedColors.highlightedColor,
    accent1Color: namedColors.clickableColor,
    accent2Color: namedColors.bgColor,
    domain: data.domain,
    logo: data.logo_url,
    favicon: data.favicon_url,
    ...namedColors
  }

  const palette = {
    primary1Color: cyan500,
    primary2Color: cyan700,
    primary3Color: grey400,
    accent1Color: pinkA200,
    accent2Color: grey100,
    accent3Color: grey500,
    textColor: "#4a4a4a",
    alternateTextColor: white,
    canvasColor: white,
    borderColor: "#eaeaea",
    errorColor: "#d0011b",
    disabledColor: fade(darkBlack, 0.3),
    pickerHeaderColor: "#ff9e1e",
    clockCircleColor: fade(darkBlack, 0.07),
    shadowColor: fullBlack,
    ...clientTheme
  }

  const bassetTheme = {
    spacing: spacing,
    palette: palette,
    appBar: { height: isMobile() ? 40 : 75, color: white, textColor: "#4a4a4a" },
    flatButton: { textTransform: "none", fontSize: 16, fontWeight: 400 },
    raisedButton: { textTransform: "none", fontSize: 16, fontWeight: 400, textColor: palette.searchBoxTextColor },
    menuItem: { selectedTextColor: palette.selectionColor, hoverColor: "#d8e3f9" }
  }
  const clientConfig = {
    theme: bassetTheme,
    checkoutUrl: data.checkout_url,
    clientId: data.id,
    footer: data.footer,
    header: data.header,
    analyticsId: data.analytics_id,
    googleTagManagerId: data.google_tag_manager_id,
    site: data.site,
    search_configuration: data.search_configurations.flights,
    channel: data.channel,
    homeImageUrl:
      data.home_configurations && data.home_configurations.flights ? data.home_configurations.flights.image_url : "",
    applications: data.applications
  }

  saveClientConfig(clientConfig)

  return clientConfig
}

export const getClientConfig = (forceFetch = false) => {
  if (process.env.NODE_ENV === "test") {
    return configToTheme(require("actions/mocks/clients.json"))
  }

  const domain = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_DOMAIN : window.location.hostname
  const uri = process.env.REACT_APP_CLIENTS_API_URI + "/theme?domain=" + domain
  const cacheKey = getClientConfigCacheKey()

  if (forceFetch) {
    removeStorageKey(cacheKey)
  }

  const cachedConfig = getStorageKey(cacheKey)

  if (cachedConfig !== null) {
    try {
      const cfg = JSON.parse(cachedConfig)
      return cfg
    } catch (error) {
      removeStorageKey(cacheKey)
    }
  }

  return fetchAsync(uri, { headers: { "x-api-key": process.env.REACT_APP_API_KEY } }).then(data => {
    const config = configToTheme(data)
    saveClientConfig(config)

    return config
  })
}

const getStorageKey = key => {
  try {
    return ls.get(key)
  } catch (e) {
    return null
  }
}

const removeStorageKey = key => {
  try {
    localStorage.removeItem(key)
  } catch (e) {}
}

export const getAdvertising = (clientId, site, forceFetch = false) => {
  const uri = process.env.REACT_APP_ADVERTISING_API_URI + "/?channel=WEB&product=FLIGHTS&site=" + site
  const cacheKey = getAdvertisingCacheKey()
  if (forceFetch) {
    removeStorageKey(cacheKey)
  }
  const cachedAdvertising = getStorageKey(cacheKey)
  if (cachedAdvertising != null) {
    try {
      const cfg = JSON.parse(cachedAdvertising)
      return new Promise((resolve, reject) => {
        resolve(cfg)
      })
    } catch (error) {
      console.error(error)
      removeStorageKey(cacheKey)
    }
  }
  return fetchAsync(uri, { headers: { "x-api-key": process.env.REACT_APP_API_KEY, "x-client-id": clientId } }).then(
    data => {
      saveAdvertising(data)
      return data
    }
  )
}

export const saveClientConfig = config => {
  try {
    const ttl = parseInt(process.env.REACT_APP_THEME_TTL, 10)
    ls.set(getClientConfigCacheKey(), JSON.stringify(config), ttl)
  } catch (e) {}
}

const saveAdvertising = advertising => {
  try {
    const ttl = parseInt(process.env.REACT_APP_ADVERTISING_TTL, 10)
    ls.set(getAdvertisingCacheKey(), JSON.stringify(advertising), ttl)
  } catch (e) {}
}

export const isMobile = () => {
  return window.matchMedia(`(max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}px)`).matches
}

export const validDuration = duration => {
  if (typeof duration !== "string" || !duration.match(/[0-9]{2}:[0-9]{2}/)) {
    console.warn(`invalid duration: ${JSON.stringify(duration)} is not a string or doesn't match format hh:mm`)
    return false
  }
  return true
}

function validLeg(leg) {
  if (!leg || !leg.arrival_date || !leg.arrival_time || !leg.departure_date || !leg.departure_time) {
    console.warn(
      `invalid leg = ${JSON.stringify(
        leg
      )} is not a valid Leg with arrival_date, arrival_time, departure_date and departure_time`
    )
    return false
  }
  return true
}

export const clusterLeftSeats = cluster => {
  return cluster.segments.reduce((min, segment) => {
    const ls = segmentLeftSeats(segment)
    return ls < min ? ls : min
  }, segmentLeftSeats(cluster.segments[0]))
}

export const segmentLeftSeats = segment => {
  return segment.options.reduce((min, option) => {
    const ls = optionLeftSeats(option)
    return ls < min ? ls : min
  }, optionLeftSeats(segment.options[0]))
}

export const optionLeftSeats = option => {
  return option.legs.reduce(
    (min, leg) => (leg.cabin_type.quantity < min ? leg.cabin_type.quantity : min),
    option.legs[0].cabin_type.quantity
  )
}

/**
 * returns a representation of a string with format hh:mm in a new string h{space}h mm{space}m
 * i.e.: 10:40 to 10h 40m or 01:05 to 1h 05m
 * @param duration string with format hh:mm
 * @param space string separator between the number and the h or m. i.e.: space = # duration = 12:34 result = 12#h 34#m
 * @returns {string}
 */
export const duration = (duration, space = "") => {
  if (!validDuration(duration)) {
    return ""
  }
  const arr = duration.split(":")
  const hh = parseInt(arr[0], 10)
  return hh > 0 ? `${hh}${space}h ${arr[1]}${space}m` : `${arr[1]}${space}m`
}

/**
 * returns de time diff between one leg and another leg in a string representation with format hh{space}h mm{space}m
 * @param leg the first leg to get the from date/time
 * @param nextLeg the second leg to get the to date/time
 * @param space string separator between the number and the h or m. i.e.: space = # diff = 12:34 result = 12#h 34#m
 * @returns {string}
 */
export const stopDuration = (leg, nextLeg, space = "") => {
  if (!validLeg(leg) || !validLeg(nextLeg)) {
    return ""
  }

  const from = moment(`${leg.arrival_date} ${leg.arrival_time}`)
  const to = moment(`${nextLeg.departure_date} ${nextLeg.departure_time}`)

  const diff = to.diff(from, "minutes")

  const hh = Math.floor(diff / 60)
  const mm = diff % 60 > 0 ? diff % 60 : `0${diff % 60}`

  return hh > 0 ? `${hh}${space}h ${mm}${space}m` : `${mm}${space}m`
}

/**
 * This function is used to format a leg location (origin or destination) by removing the text between the parentesis to
 * keep only the city name. This is because the airport is added between the parentesis
 * @param locationName the name of the location to be formatted
 * @returns {string} with the location name without the parentesis and trimmed
 */
export const city = locationName => {
  if (typeof locationName !== "string") {
    console.warn(`invalid locationName: ${JSON.stringify(locationName)} is not a string`)
    return ""
  }
  return locationName.replace(/\(.*\)/, "").trim()
}

/**
 * This function is used to format a leg location (origin or destination) by keeping only the airport name. This is
 * because the airport is added between the parentesis after the iata_code
 * @param locationName the name of the location to be formatted
 * @returns {string} with the locationName airport if found (and trimmed), or the same locationName if the regex didn't match
 */
export const airport = locationName => {
  if (typeof locationName !== "string") {
    console.warn(`invalid locationName: ${JSON.stringify(locationName)} is not a string`)
    return ""
  }
  const match = locationName.match(/\([\w\d\s]+-\s*(.+)\)/)
  return (match !== null ? match[1] : locationName).trim()
}

/**
 * Shortcut to apply parseFloat and toLocaleString in one step
 * @param num number
 * @param currency string
 * @returns {string}
 */
export const parsePrice = (num, currency) => {
  if (isNaN(parseFloat(num)) || !isFinite(num)) {
    console.warn(`invalid num: ${JSON.stringify(num)} is not a number`)
    return ""
  }

  return (
    currency + " " + parseFloat(num).toLocaleString("de-DE", { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  )
}

export const stopLabel = (stopsQty, t) => {
  return stopsQty === 0 ? t("Directo") : t("{{stopsQty}} escala", { count: stopsQty, stopsQty: stopsQty })
}

export const hasMultipleMarketingCarriers = option => {
  let has = false,
    carrier = null
  option.legs.forEach(leg => {
    if (carrier === null) {
      carrier = leg.marketing_carrier
    } else if (carrier.code !== leg.marketing_carrier.code) {
      has = true
    }
  })

  return has
}

export const clearAppFilterParamsFromSearch = search => {
  let _search = { ...search }
  delete _search.airlines
  delete _search.stops
  delete _search.outbound_departure_airports
  delete _search.outbound_arrival_airports
  delete _search.inbound_departure_airports
  delete _search.inbound_arrival_airports
  delete _search.outbound_departure_time
  delete _search.inbound_departure_time

  return _search
}

export const clearAppParamsFromSearch = search => {
  let _search = clearAppFilterParamsFromSearch(search)
  delete _search.type
  delete _search.from
  delete _search.to
  delete _search.dates
  delete _search.adults
  delete _search.seniors
  delete _search.disabled
  delete _search.children
  delete _search.infants
  delete _search.cabin
  delete _search.stops

  return _search
}

/**
 * TODO: this should be improved
 * @param matrix
 * @returns {*}
 */
export const fromMatrixToPricesBy = (matrix, t = arg => arg) => {
  if (matrix && matrix.length) {
    let cheapest
    let pricesBy = [
      {
        label: t("Precios por aerolínea"),
        table: [
          [{ label: t("Aerolíneas") }],
          [{ label: t("Directo"), filters: { stops: [0] } }],
          [{ label: t("1 Escala"), filters: { stops: [1] } }],
          [{ label: t("2 Escalas o más"), filters: { stops: [2] } }]
        ]
      }
    ]
    matrix.forEach(item => {
      pricesBy[0].table[0].push({
        label: item.airline.name,
        icon: item.airline.code,
        filters: { airlines: [item.airline.code] }
      })

      pricesBy[0].table.forEach((arr, i) => {
        if (i > 0) {
          if (i - 1 in item.stops) {
            if (!cheapest || item.stops[i - 1].total < cheapest) {
              cheapest = item.stops[i - 1].total
            }
            arr.push({
              label: parsePrice(item.stops[i - 1].total, item.stops[i - 1].currency),
              value: item.stops[i - 1].total,
              filters: { airlines: [item.airline.code], stops: [i - 1] }
            })
          } else {
            arr.push({
              label: "-",
              empty: true
            })
          }
        }
      })
    })

    let tablesToRemove = []
    pricesBy[0].table.forEach((arr, i) => {
      let countWithoutValue = 0
      arr.forEach(cell => {
        if (cell.empty) {
          countWithoutValue++
        }
        if (cell.value === cheapest) {
          cell.highlighted = true
        }
      })
      if (countWithoutValue === arr.length - 1) {
        tablesToRemove.push(i)
      }
    })

    tablesToRemove.reverse().forEach(i => {
      pricesBy[0].table.splice(i, 1)
    })

    return pricesBy
  }

  return []
}

export const roundingWithStep = (val: number, step: number) => {
  let res = Math.ceil(val / step)
  if (res !== val / step) {
    res--
  }
  return res * step
}
