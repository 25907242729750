import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withRerenderOnResize } from "components/commons/withRerenderOnResize"
import IconButton from "material-ui/IconButton"
import AddIcon from "material-ui/svg-icons/content/add"
import RemoveIcon from "material-ui/svg-icons/content/remove"
import { InputGroup } from "react-bootstrap"
import InputGroupAddon from "components/commons/styled/InputGroupAddon"
import TextField from "components/commons/styled/TextField"
import NumberField from "components/commons/styled/NumberField"
import * as constants from "config/constants"

const SUBSTRACT = "-"
const ADD = "+"

const Small = styled.small`
  font-size: 10px;
  font-style: italic;
  color: #4a4a4a !important;
  white-space: nowrap;

  float: right;
  margin: 6px 8px 0 0;

  @media (min-width: ${constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}px) and (max-width: ${constants.MEDIA_QUERY_BREAKPOINT_MEDIUM_SCREEN_MAX}px) {
    float: none;
    margin: 0;
  }
  @media (max-width: 314px) {
    margin: 6px 0 0 0;
  }
  @media (max-width: 305px) {
    margin: 8px 0 0 0;
    font-size: 9px;
  }
  @media (max-width: 295px) {
    font-size: 8px;
  }
`

const validateProps = props => {
  if (props.selectedNumber < props.minValue) {
    //throw new Error('SearchBoxNumberField: the property selectedNumber must be greater than the property minValue')
  }
}

export class SearchBoxNumberField extends React.PureComponent {
  constructor(props) {
    super(props)
    validateProps(props)
  }

  componentWillReceiveProps(newProps) {
    validateProps(newProps)
  }

  handleNumberChange = action => {
    let num = this.props.selectedNumber
    if (action === ADD) {
      num++
    }
    if (action === SUBSTRACT) {
      num--
    }
    this.handleBlur(num)
  }

  handleBlur = num => {
    const { selectedNumber, minValue, maxValue, onChangeNumber } = this.props

    if (num > maxValue) {
      num = maxValue
    }
    if (num < minValue) {
      num = minValue
    }
    if (!/^[0-9]+$/.test(num)) {
      num = minValue
    }
    if (num !== selectedNumber) {
      onChangeNumber(num)
    }
  }

  render() {
    const { icon, label, placeholder, selectedNumber, textFieldId, onChangeNumber, error } = this.props

    return (
      <InputGroup>
        <InputGroupAddon style={{ width: "50%" }}>
          {icon} {label} {placeholder ? <Small>{placeholder}</Small> : null}
        </InputGroupAddon>
        <NumberField>
          <IconButton onClick={this.handleNumberChange.bind(this, SUBSTRACT)}>
            <RemoveIcon />
          </IconButton>
          <TextField
            value={selectedNumber}
            fullWidth={true}
            width="100%"
            height="100%"
            inputStyle={{ textAlign: "center" }}
            id={textFieldId}
            error={error}
            applyBorderRightForError={error}
            onChange={event => {
              onChangeNumber(event.target.value)
            }}
            onBlur={event => {
              this.handleBlur(event.target.value)
            }}
          />
          <IconButton onClick={this.handleNumberChange.bind(this, ADD)}>
            <AddIcon />
          </IconButton>
        </NumberField>
      </InputGroup>
    )
  }
}

export default withRerenderOnResize(SearchBoxNumberField)

SearchBoxNumberField.propTypes = {
  // required
  label: PropTypes.string.isRequired,
  textFieldId: PropTypes.string.isRequired,
  selectedNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // optional
  icon: PropTypes.node,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  // handlers
  onChangeNumber: PropTypes.func.isRequired
}

SearchBoxNumberField.defaultProps = {
  error: false,
  minValue: 0,
  maxValue: 9
}
