// @flow

import React, { PureComponent } from "react"
// TODO: Ver si vamos a usarlos para cuando los clientes no tengan configurado header ni footer o si volamos estos comps
//import Navigation from 'components/layout/Navigation'
//import Footer from 'components/layout/Footer'
import LoadingBar from "react-redux-loading-bar"
import muiThemeable from "material-ui/styles/muiThemeable"
import GoogleTagManager from "./GoogleTagManager"
import { getClientConfig } from "utils/flightsHelper"
import { connect } from "react-redux"

type Props = {|
  isLoading: boolean,
  muiTheme: any,
  children: any
|}

class Layout extends PureComponent<Props> {
  componentDidMount() {
    let head = document.getElementsByTagName("head")[0]

    let linkEl = document.createElement("link")
    linkEl.type = "image/x-icon"
    linkEl.rel = "icon"

    // remove existing favicons
    var links = head.getElementsByTagName("link")
    for (var i = links.length; --i >= 0; ) {
      if (/\bicon\b/i.test(links[i].getAttribute("rel"))) {
        head.removeChild(links[i])
      }
    }

    head.appendChild(linkEl)

    linkEl.href = this.props.muiTheme.palette.favicon
  }

  render() {
    const { children, muiTheme, isLoading } = this.props
    const loadingBarStyles = {
      zIndex: 1100,
      position: "relative",
      backgroundColor: muiTheme.palette.clickableColor,
      height: 4,
      top: 0
    }

    //const logo = 'https://' + muiTheme.palette.domain + muiTheme.palette.logo

    const clientConfig = getClientConfig()
    const header = { __html: clientConfig.header }
    const footer = { __html: clientConfig.footer }
    const gtmId = clientConfig.googleTagManagerId
    return (
      <div style={{ backgroundColor: muiTheme.palette.bgColor }}>
        {gtmId && gtmId.length ? <GoogleTagManager gtmId={gtmId} /> : null}
        <div dangerouslySetInnerHTML={header} />
        {isLoading && <LoadingBar style={loadingBarStyles} progressIncrease={15} />}
        {children}
        <div dangerouslySetInnerHTML={footer} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.search.isLoading
})

export default muiThemeable()(connect(mapStateToProps)(Layout))
