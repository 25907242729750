import { getClientConfig } from "utils/flightsHelper"

// Fetches an API response.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = endpoint =>
  fetch(endpoint, {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-client-id": getClientConfig().clientId
    }
  })

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  var error = new Error(response.statusText)
  error.response = response
  throw error
}

const parseJSON = response => {
  return response.json()
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "Call API"

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API]
  if (typeof callAPI === "undefined") {
    return next(action)
  }

  let { endpoint } = callAPI
  const { types } = callAPI

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState())
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.")
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.")
  }
  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.")
  }

  const actionWith = data => {
    const finalAction = { ...action, ...data }
    delete finalAction[CALL_API]
    return finalAction
  }

  const [requestType, successType, failureType] = types
  next(actionWith({ type: requestType }))

  return callApi(endpoint)
    .then(checkStatus)
    .then(parseJSON)
    .then(response => {
      next(
        actionWith({
          response,
          type: successType
        })
      )
    })
    .catch(error => {
      if (error.response) {
        error.response
          .json()
          .then(errorJson => {
            next(
              actionWith({
                type: failureType,
                //error: (error.response ? error.response.statusText : error.error) || 'Something bad happened'
                error: errorJson.error ? errorJson.error : errorJson
              })
            )
          })
          .catch(e => {
            // when the response can't be json parsed
            next(actionWith({ type: failureType, error: "Ocurrió un error inesperado" }))
          })
      } else {
        next(actionWith({ type: failureType, error: "Ocurrió un error inesperado" }))
      }
    })
}
