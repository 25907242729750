import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRerenderOnResize } from "components/commons/withRerenderOnResize"
import Modal from "react-modal"
import { modalStyle } from "components/commons/acTheme"
import {
  boundAddFilterAirline,
  boundRemoveFilterAirline,
  boundAddFilterStop,
  boundRemoveFilterStop,
  boundAddFilterOutboundDepartureAirport,
  boundRemoveFilterOutboundDepartureAirport,
  boundAddFilterOutboundArrivalAirport,
  boundRemoveFilterOutboundArrivalAirport,
  boundAddFilterInboundDepartureAirport,
  boundRemoveFilterInboundDepartureAirport,
  boundAddFilterInboundArrivalAirport,
  boundRemoveFilterInboundArrivalAirport,
  boundChangeFilterOutboundDepartureTime,
  boundChangeFilterInboundDepartureTime,
  boundApplyFilters
} from "actions"
import MediaQuery from "react-responsive"
import RaisedButton from "material-ui/RaisedButton"
import IconButton from "material-ui/IconButton"
import CloseIcon from "material-ui/svg-icons/navigation/close"
import FlightsFiltersMobileContainer from "components/mobile/filters/FlightsFiltersMobileContainer"
import ActiveFilters from "components/flights/filters/ActiveFilters"
import CardFilter from "components/flights/filters/CardFilter"
import CheckboxFilter from "components/flights/filters/CheckboxFilter"
import SliderFilter from "components/flights/filters/SliderFilter"
import FlightsFiltersMobileBar from "components/mobile/filters/FlightsFiltersMobileBar"
import { airport, stopLabel, isMobile } from "utils/flightsHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"
import _ from "underscore"
import ReactGA from "react-ga"

class FlightsFilters extends React.PureComponent {
  state = {
    open: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldApplyFilters) {
      this.props.onSubmitFilters(nextProps)
    }
  }

  scrollBack = () => {
    window.scrollTo(0, this.state.scrollPos)
  }

  handleOpenFilters = () => {
    this.setState({
      open: true,
      // saving scrollY pos to scrollBack because the react-modal leaves the scroll on the top after closing
      // https://github.com/reactjs/react-modal/issues/585#issuecomment-355001929
      scrollPos: window.scrollY
    })
  }

  handleCloseFilters = () => {
    this.setState({ open: false }, this.scrollBack)
  }

  formatAirports = airports => {
    return _.mapObject(airports, (val, key) =>
      val.map(_airport => ({
        name: `${_airport.code} - ${airport(_airport.name)}`,
        code: _airport.code
      }))
    )
  }

  formatStops = stops => {
    return stops.sort().map(stop => ({
      value: stop,
      label: stopLabel(stop, this.props.t)
    }))
  }

  applyFilters = () => {
    if (!isMobile()) {
      this.props.applyFilters()
    }
  }

  handleAddAirline = code => {
    this.props.addAirline(code)
    this.applyFilters()
  }

  handleRemoveAirline = code => {
    this.props.removeAirline(code)
    this.applyFilters()
  }

  handleAddStop = value => {
    this.props.addStop(value)
    this.applyFilters()
  }

  handleRemoveStop = value => {
    this.props.removeStop(value)
    this.applyFilters()
  }

  handleAddOutboundAirports = (code, key) => {
    if (key === "outbound") {
      this.props.addOutboundDepartureAirport(code)
    } else if (key === "inbound") {
      this.props.addOutboundArrivalAirport(code)
    }
    this.applyFilters()
  }

  handleRemoveOutboundAirports = (code, key) => {
    if (key === "outbound") {
      this.props.removeOutboundDepartureAirport(code)
    } else if (key === "inbound") {
      this.props.removeOutboundArrivalAirport(code)
    }
    this.applyFilters()
  }

  handleAddInboundAirports = (code, key) => {
    if (key === "outbound") {
      this.props.addInboundDepartureAirport(code)
    } else if (key === "inbound") {
      this.props.addInboundArrivalAirport(code)
    }
    this.applyFilters()
  }

  handleRemoveInboundAirports = (code, key) => {
    if (key === "outbound") {
      this.props.removeInboundDepartureAirport(code)
    } else if (key === "inbound") {
      this.props.removeInboundArrivalAirport(code)
    }
    this.applyFilters()
  }

  handleChangeOutboundDepartureTime = value => {
    this.props.changeOutboundDepartureTime(value)
  }

  handleChangeInboundDepartureTime = value => {
    this.props.changeInboundDepartureTime(value)
  }

  handleRemoveFilter = (key, val) => {
    switch (key) {
      case "airlines":
        this.props.removeAirline(val)
        break
      case "stops":
        this.props.removeStop(val)
        break
      case "outboundDepartureAirports":
      case "outboundArrivalAirports":
        this.props.removeOutboundDepartureAirport(val)
        this.props.removeOutboundArrivalAirport(val)
        break
      case "inboundDepartureAirports":
      case "inboundArrivalAirports":
        this.props.removeInboundDepartureAirport(val)
        this.props.removeInboundArrivalAirport(val)
        break
      case "outboundDepartureTime":
        this.props.changeOutboundDepartureTime(constants.DEFAULT_DEPARTURE_TIME)
        break
      case "inboundDepartureTime":
        this.props.changeInboundDepartureTime(constants.DEFAULT_DEPARTURE_TIME)
        break
      default:
        break
    }
    this.props.applyFilters()
  }

  handleFilter = () => {
    this.props.onSubmitFilters(this.props)
    this.setState({ open: false })

    this.sendAirlinesEvent(this.props.airlines, this.props.userSelectedFilters.airlines)
    this.sendStopsEvent(this.props.stops, this.props.userSelectedFilters.stops)

    this.sendAirportsEvent(
      "Outbound departure airport",
      this.props.outboundDepartureAirports,
      this.props.userSelectedFilters.outboundDepartureAirports
    )
    this.sendAirportsEvent(
      "Outbound arrival airport",
      this.props.outboundArrivalAirports,
      this.props.userSelectedFilters.outboundArrivalAirports
    )
    this.sendAirportsEvent(
      "Inbound departure airport",
      this.props.inboundDepartureAirports,
      this.props.userSelectedFilters.inboundDepartureAirports
    )
    this.sendAirportsEvent(
      "Outbound arrival airport",
      this.props.inboundArrivalAirports,
      this.props.userSelectedFilters.inboundArrivalAirports
    )

    this.sendTimeEvent(
      "Outbound departure time",
      this.props.outboundDepartureTime,
      this.props.userSelectedFilters.outboundDepartureTime
    )
    this.sendTimeEvent(
      "Inbound arrival time",
      this.props.inboundDepartureTime,
      this.props.userSelectedFilters.inboundDepartureTime
    )
  }

  sendTimeEvent = (action, times, appliedFilters) => {
    if (appliedFilters.toString() !== times.toString()) {
      let txt

      switch (times.toString()) {
        case "0,300":
          txt = "Early morning"
          break
        case "300,720":
          txt = "Morning"
          break
        case "720,1140":
          txt = "Afternoon"
          break
        case "1140,1439":
          txt = "Night"
          break
        default:
          txt = ""
      }

      ReactGA.event({
        category: "Flight Results",
        action: action + ": " + txt,
        label: "Filters"
      })
    }
  }

  sendAirportsEvent = (action, airports, appliedFilters) => {
    airports.forEach(airport => {
      if (appliedFilters.indexOf(airport) === -1) {
        ReactGA.event({
          category: "Flight Results",
          action: action + ": " + airport,
          label: "Filters"
        })
      }
    })
  }

  sendStopsEvent = (stops, appliedFilters) => {
    stops.forEach(stop => {
      if (appliedFilters.indexOf(stop) === -1) {
        let code
        switch (stop) {
          case 0:
            code = "Direct"
            break
          case 1:
            code = "One stop"
            break
          case 2:
            code = "Two or more stops"
            break
          default:
            code = ""
        }

        ReactGA.event({
          category: "Flight Results",
          action: "Stops: " + code,
          label: "Filters"
        })
      }
    })
  }

  sendAirlinesEvent = (airlines, appliedFilters) => {
    airlines.forEach(airline => {
      if (appliedFilters.indexOf(airline) === -1) {
        ReactGA.event({
          category: "Flight Results",
          action: "Airline: " + airline,
          label: "Filters"
        })
      }
    })
  }

  filters = () => {
    const {
      flightType,
      availableFilters,
      userSelectedFilters,
      airlines,
      stops,
      outboundDepartureAirports,
      outboundArrivalAirports,
      inboundDepartureAirports,
      inboundArrivalAirports,
      outboundDepartureTime,
      inboundDepartureTime,
      t
    } = this.props

    const activeFilters = {
      ...userSelectedFilters,
      outboundDepartureTime: _.isEqual(userSelectedFilters.outboundDepartureTime, constants.DEFAULT_DEPARTURE_TIME)
        ? []
        : userSelectedFilters.outboundDepartureTime,
      inboundDepartureTime: _.isEqual(userSelectedFilters.inboundDepartureTime, constants.DEFAULT_DEPARTURE_TIME)
        ? []
        : userSelectedFilters.inboundDepartureTime
    }

    const availableAirlines = [{ code: constants.CHECK_ALL, name: t("Todas las aerolíneas") }].concat(
      availableFilters.airlines
    )
    const availableStops = [{ value: constants.CHECK_ALL, label: t("Todas las escalas") }].concat(
      this.formatStops(availableFilters.stops)
    )
    const availableOutboundAirports = this.formatAirports(availableFilters.outbound_airports)
    const availableInboundAirports = this.formatAirports(availableFilters.inbound_airports)

    return (
      <div>
        <div className="filters-content">
          {_.reduce(activeFilters, (memo, val) => (isNaN(memo) ? memo.length : memo) + val.length) > 0 && (
            <CardFilter title={t("Filtros activos")} initiallyExpanded={true}>
              <ActiveFilters
                availableFilters={{
                  ...availableFilters,
                  airlines: availableAirlines,
                  stops: availableStops,
                  outbound_airports: availableOutboundAirports,
                  inbound_airports: availableInboundAirports
                }}
                activeFilters={activeFilters}
                onRemoveFilter={this.handleRemoveFilter}
              />
            </CardFilter>
          )}

          {availableFilters.airlines && (
            <CardFilter title={t("Aerolíneas")} initiallyExpanded={true}>
              <CheckboxFilter
                options={availableAirlines}
                selected={airlines}
                hasIcon={true}
                nowrap={true}
                onAddFilter={this.handleAddAirline}
                onRemoveFilter={this.handleRemoveAirline}
              />
            </CardFilter>
          )}

          {availableFilters.stops && (
            <CardFilter title={t("Escalas")} initiallyExpanded={true}>
              <CheckboxFilter
                options={availableStops}
                selected={stops}
                labelKey="label"
                valueKey="value"
                onAddFilter={this.handleAddStop}
                onRemoveFilter={this.handleRemoveStop}
              />
            </CardFilter>
          )}

          {availableFilters.outbound_airports && (
            <CardFilter title={t("Aeropuerto de Ida")} initiallyExpanded={true}>
              <CheckboxFilter
                options={availableOutboundAirports}
                labelsWhenObject={{
                  outbound: t("Quiero salir de") + ":",
                  inbound: t("Quiero llegar a") + ":"
                }}
                selected={{
                  outbound: outboundDepartureAirports,
                  inbound: outboundArrivalAirports
                }}
                onAddFilter={this.handleAddOutboundAirports}
                onRemoveFilter={this.handleRemoveOutboundAirports}
              />
            </CardFilter>
          )}

          {flightType === constants.ROUND_TRIP && availableFilters.inbound_airports && (
            <CardFilter title={t("Aeropuerto de Regreso")} initiallyExpanded={true}>
              <CheckboxFilter
                options={availableInboundAirports}
                labelsWhenObject={{
                  outbound: t("Quiero salir de"),
                  inbound: t("Quiero llegar a")
                }}
                selected={{
                  outbound: inboundDepartureAirports,
                  inbound: inboundArrivalAirports
                }}
                onAddFilter={this.handleAddInboundAirports}
                onRemoveFilter={this.handleRemoveInboundAirports}
              />
            </CardFilter>
          )}

          <CardFilter title={t("Horario de ida")} initiallyExpanded={true}>
            <SliderFilter
              selected={outboundDepartureTime}
              onChange={this.handleChangeOutboundDepartureTime}
              onApplyFilter={this.applyFilters}
            />
          </CardFilter>

          {flightType === constants.ROUND_TRIP && (
            <CardFilter title={t("Horario de regreso")} initiallyExpanded={true}>
              <SliderFilter
                selected={inboundDepartureTime}
                onChange={this.handleChangeInboundDepartureTime}
                onApplyFilter={this.applyFilters}
              />
            </CardFilter>
          )}
        </div>

        <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
          <div className="filters-footer">
            <RaisedButton
              id="filtersSubmit"
              label={t("Aplicar filtros")}
              secondary={true}
              fullWidth={true}
              style={{ marginTop: 16 }}
              onClick={this.handleFilter}
            />
          </div>
        </MediaQuery>
      </div>
    )
  }

  render() {
    if (_.isEmpty(this.props.availableFilters)) {
      return null
    }

    if (isMobile()) {
      const modalStyleCopy = {
        overlay: modalStyle.overlay,
        content: { ...modalStyle.content, backgroundColor: "#f8f8f8" }
      }
      return (
        <div>
          <FlightsFiltersMobileBar onToggle={this.handleOpenFilters} />

          <Modal
            isOpen={this.state.open}
            style={modalStyleCopy}
            onRequestClose={this.handleCloseFilters}
            shouldCloseOnOverlayClick={false}
            contentLabel="Modal"
            ariaHideApp={false}
            bodyOpenClassName="ReactModal__Body--open"
            role="dialog"
          >
            <FlightsFiltersMobileContainer>
              <div className="filters-header">
                <IconButton
                  onClick={this.handleCloseFilters}
                  style={{ float: "right", width: 24, height: 24, padding: 0, marginTop: 12 }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              {this.filters()}
            </FlightsFiltersMobileContainer>
          </Modal>
        </div>
      )
    }

    return this.filters()
  }
}

const mapStateToProps = (state, ownProps) => {
  const { filters, search, userSelectedFilters } = state

  return {
    flightType: search.flightType,
    userSelectedFilters: userSelectedFilters.filters,
    shouldApplyFilters: userSelectedFilters.applyFilters,
    airlines: filters.airlines,
    stops: filters.stops,
    outboundDepartureAirports: filters.outboundDepartureAirports,
    outboundArrivalAirports: filters.outboundArrivalAirports,
    inboundDepartureAirports: filters.inboundDepartureAirports,
    inboundArrivalAirports: filters.inboundArrivalAirports,
    outboundDepartureTime: filters.outboundDepartureTime,
    inboundDepartureTime: filters.inboundDepartureTime
  }
}

export default connect(
  mapStateToProps,
  {
    addAirline: boundAddFilterAirline,
    removeAirline: boundRemoveFilterAirline,
    addStop: boundAddFilterStop,
    removeStop: boundRemoveFilterStop,
    addOutboundDepartureAirport: boundAddFilterOutboundDepartureAirport,
    removeOutboundDepartureAirport: boundRemoveFilterOutboundDepartureAirport,
    addOutboundArrivalAirport: boundAddFilterOutboundArrivalAirport,
    removeOutboundArrivalAirport: boundRemoveFilterOutboundArrivalAirport,
    addInboundDepartureAirport: boundAddFilterInboundDepartureAirport,
    removeInboundDepartureAirport: boundRemoveFilterInboundDepartureAirport,
    addInboundArrivalAirport: boundAddFilterInboundArrivalAirport,
    removeInboundArrivalAirport: boundRemoveFilterInboundArrivalAirport,
    changeOutboundDepartureTime: boundChangeFilterOutboundDepartureTime,
    changeInboundDepartureTime: boundChangeFilterInboundDepartureTime,
    applyFilters: boundApplyFilters
  }
)(withTranslation()(withRerenderOnResize(FlightsFilters)))

FlightsFilters.propTypes = {
  flightType: PropTypes.oneOf(constants.FLIGHT_TYPES),
  userSelectedFilters: PropTypes.object.isRequired,
  shouldApplyFilters: PropTypes.bool.isRequired,
  airlines: PropTypes.array.isRequired,
  stops: PropTypes.array.isRequired,
  outboundDepartureAirports: PropTypes.array.isRequired,
  outboundArrivalAirports: PropTypes.array.isRequired,
  inboundDepartureAirports: PropTypes.array.isRequired,
  inboundArrivalAirports: PropTypes.array.isRequired,
  outboundDepartureTime: PropTypes.array.isRequired,
  inboundDepartureTime: PropTypes.array.isRequired,

  addAirline: PropTypes.func.isRequired,
  removeAirline: PropTypes.func.isRequired,
  addStop: PropTypes.func.isRequired,
  removeStop: PropTypes.func.isRequired,
  addOutboundDepartureAirport: PropTypes.func.isRequired,
  removeOutboundDepartureAirport: PropTypes.func.isRequired,
  addOutboundArrivalAirport: PropTypes.func.isRequired,
  removeOutboundArrivalAirport: PropTypes.func.isRequired,
  addInboundDepartureAirport: PropTypes.func.isRequired,
  removeInboundDepartureAirport: PropTypes.func.isRequired,
  addInboundArrivalAirport: PropTypes.func.isRequired,
  removeInboundArrivalAirport: PropTypes.func.isRequired,
  changeOutboundDepartureTime: PropTypes.func.isRequired,
  changeInboundDepartureTime: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,

  availableFilters: PropTypes.object.isRequired,
  onSubmitFilters: PropTypes.func.isRequired
}
