import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M655.427 679.489l18.462-350.982c1.39-26.319-16.030-49.268-40.503-55.838-19.907-7.619-43.227-1.53-56.564 16.433l-79.598 107.16-169.295 28.453c-26.557 4.462-44.465 29.611-40.009 56.168 4.005 23.82 24.649 40.686 48.030 40.686 2.682 0 5.406-0.219 8.143-0.683l188.654-31.701c8.71-1.463 16.762-5.297 23.4-10.923l-7.546 143.488h-240.786c-26.917 0-49.853 19.529-54.156 46.098l-39.613 244.815c-4.84 29.909 15.482 58.075 45.391 62.915 2.974 0.482 5.918 0.713 8.826 0.713 26.441 0 49.731-19.163 54.089-46.104l32.158-198.723h246.138c29.178 0 53.248-22.839 54.778-51.974z",
    "M619.112 241.048c54.784 0 99.188-44.41 99.188-99.188 0-54.784-44.404-99.194-99.188-99.194-54.778 0-99.188 44.41-99.188 99.194 0 54.778 44.41 99.188 99.188 99.188z",
    "M694.98 395.486c-4.748 122.551-9.509 245.108-14.257 367.665h-251.855c-58.831 0-58.929 91.429 0 91.429h295.796c25.417 0 44.757-20.974 45.714-45.714 5.346-137.795 10.685-275.59 16.030-413.379 2.286-58.837-89.149-58.74-91.429 0z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }, { fill: "rgb(74, 74, 74)" }, { fill: "rgb(74, 74, 74)" }]
}
export default class Economy extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
