import React from "react"
import PropTypes from "prop-types"
import muiThemeable from "material-ui/styles/muiThemeable"
import Chip from "material-ui/Chip"
import { minutesToDuration } from "utils/filtersHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"
import _ from "underscore"

class ActiveFilters extends React.PureComponent {
  removeFilter = (key, val) => {
    this.props.onRemoveFilter(key, val)
  }

  chip = (key, content, val = undefined, i = undefined) => {
    const color = this.props.muiTheme.palette.clickableColor

    return (
      <Chip
        key={key + i}
        onRequestDelete={() => {
          this.removeFilter(key, val)
        }}
        backgroundColor={"rgba(0,0,0,0)"}
        style={{ marginBottom: 8, marginRight: 6, border: "1px solid " + color }}
        labelColor={color}
        deleteIconStyle={{ fill: color, color: color }}
      >
        {content}
      </Chip>
    )
  }

  chips = (key, val) => {
    const { t } = this.props
    const { airlines, stops, outbound_airports, inbound_airports } = this.props.availableFilters

    switch (key) {
      case "airlines":
        if (val.length === airlines.length - 1) {
          return this.chip(
            key,
            _.find(airlines, airline => airline.code === constants.CHECK_ALL).name,
            constants.CHECK_ALL
          )
        }
        return val
          .filter(v => _.find(airlines, airline => airline.code === v))
          .map((v, i) => this.chip(key, _.find(airlines, airline => airline.code === v).name, v, i))

      case "stops":
        if (val.length === stops.length - 1) {
          return this.chip(key, _.find(stops, stop => stop.value === constants.CHECK_ALL).label, constants.CHECK_ALL)
        }
        return val
          .filter(v => _.find(stops, stop => stop.value === v))
          .map((v, i) => this.chip(key, _.find(stops, stop => stop.value === v).label, v, i))

      case "outboundDepartureAirports":
      case "outboundArrivalAirports":
        const outboundAirports = _.reduce(outbound_airports, (memo, outboundAirportArr) =>
          memo.concat(outboundAirportArr)
        )
        return val
          .filter(v => _.find(outboundAirports, airport => airport.code === v))
          .map((v, i) =>
            this.chip(key, t("Ida") + `: ${_.find(outboundAirports, airport => airport.code === v).name}`, v, i)
          )

      case "inboundDepartureAirports":
      case "inboundArrivalAirports":
        const inboundAirports = _.reduce(inbound_airports, (memo, inboundAirportArr) => memo.concat(inboundAirportArr))
        return val
          .filter(v => _.find(inboundAirports, airport => airport.code === v))
          .map((v, i) =>
            this.chip(key, t("Regreso") + `: ${_.find(inboundAirports, airport => airport.code === v).name}`, v, i)
          )

      case "outboundDepartureTime":
        if (val.length === 2) {
          return this.chip(
            key,
            t("Ida: desde {{from}} hasta {{to}}", { from: minutesToDuration(val[0]), to: minutesToDuration(val[1]) })
          )
        }
        break

      case "inboundDepartureTime":
        if (val.length === 2) {
          return this.chip(
            key,
            t("Regreso: desde {{from}} hasta {{to}}", {
              from: minutesToDuration(val[0]),
              to: minutesToDuration(val[1])
            })
          )
        }
        break

      default:
        return null
    }
    return null
  }

  render() {
    const { activeFilters } = this.props

    return (
      <div style={{ display: "flex", flexFlow: "row wrap" }}>
        {_.map(activeFilters, (val, key) => this.chips(key, val))}
      </div>
    )
  }
}

export default muiThemeable()(withTranslation()(ActiveFilters))

ActiveFilters.propTypes = {
  availableFilters: PropTypes.object.isRequired,
  activeFilters: PropTypes.object.isRequired,
  onRemoveFilter: PropTypes.func.isRequired
}
