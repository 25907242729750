import { Cookies } from "react-cookie"
import { isString } from "util"

export const RewardsCookies = min => {
  const cookies = new Cookies()

  let extractCookie = 0
  let rewardsCookie = cookies.get("rewards")
  if (rewardsCookie) {
    extractCookie = parseFloat(rewardsCookie.rewards.points.replace(/\./g, ""))
  } else {
    extractCookie = min
  }
  return extractCookie
}

const applyPromotionalPoints = (rules, totalPrice) => {
  if (rules == null) return false
  return totalPrice > rules.min_price && totalPrice < rules.max_price
}

export const calculatePointsByCluster = (price, points, rewards) => {
  if (applyPromotionalPoints(rewards.rule_from_points, price.total)) {
    return rewards.rule_from_points.from_point
  }
  const priceAssignableToPoints = priceAssignableToPointsOnCluster(price, rewards)
  return calculateFinalPoints(priceAssignableToPoints, points, rewards)
}

export const priceAssignableToPointsOnCluster = (price, rewards) => {
  return getTotalFare(price) + getNoAssignableTaxes(price, rewards)
}

export const getTotalFare = price => {
  return (
    (price.adults !== undefined ? price.adults.fare : 0) +
    (price.children !== undefined ? price.children.fare : 0) +
    (price.infants !== undefined ? price.infants.fare : 0)
  )
}

export const calculateFinalPoints = (price, points, rewards) => {
  const { minPoints, maxPoints } = calculatePointsLimitsByPrice(price, rewards)
  return validPoints(points, minPoints, maxPoints)
}

export const calculatePointsLimitsByPrice = (price, rewards) => {
  let minPoints = 0,
    maxPoints = 0

  if (rewards && rewards.coefficient && rewards.precision && rewards.min_percentage) {
    const exactMinPoints = (price * (rewards.min_percentage / 100)) / rewards.coefficient
    minPoints = Math.round(exactMinPoints / rewards.precision) * rewards.precision
    if (minPoints <= 0) minPoints = rewards.precision //avoid zero on very low prices

    const exactMaxPoint = price / rewards.coefficient
    maxPoints = Math.round(exactMaxPoint / rewards.precision) * rewards.precision
    if (maxPoints <= 0) maxPoints = rewards.precision //avoid zero on very low prices
  }

  return { minPoints, maxPoints }
}

const validPoints = (points, min, max) => {
  return points < min ? min : points > max ? max : points
}

export const getNoAssignableTaxes = (price, rewards) => {
  let totalTaxes = 0
  if (rewards.taxes_cod.length === 0) return totalTaxes
  const taxesCodes = rewards.taxes_cod

  const filterTaxes = (taxes, codes) => {
    const impuestos = taxes.filter(t => {
      return codes.some(c => {
        return t.type.includes(c) //This code is tricky: the codes like YQI have to match with YQ taxes
      })
    })
    return impuestos.reduce((a, b) => a + b.total, 0)
  }

  if (price.adults !== undefined && price.adults.taxes && price.adults.taxes.length > 0)
    totalTaxes += price.adults.quantity * filterTaxes(price.adults.taxes, taxesCodes)

  if (price.children !== undefined && price.children.taxes.length > 0)
    totalTaxes += price.children.quantity * filterTaxes(price.children.taxes, taxesCodes)

  if (price.infants !== undefined && price.infants.taxes.length > 0)
    totalTaxes += price.infants.quantity * filterTaxes(price.infants.taxes, taxesCodes)

  return totalTaxes
}

export const roundingWithStep = (val, step) => {
  let res = Math.ceil(val / step)
  if (res !== val / step) {
    res--
  }
  return res * step
}

export const setFormatPrice = (price, minimumFractionDigits, maximumFractionDigits) => {
  let p = price
  if (isString(price)) {
    p = parseFloat(price)
  }

  return p.toLocaleString("de-DE", {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  })
}

export const calculateClusterCash = (price, points, rewards) => {
  return calculateCashGeneric(
    points,
    priceAssignableToPointsOnCluster(price, rewards),
    priceAssignableToCashOnCluster(price, priceAssignableToPointsOnCluster(price, rewards), rewards),
    rewards,
    calculatePassengersOnCluster(price)
  )
}

export const calculateCashGeneric = (points, priceAssignableToPoints, priceAssignableToCash, rewards, passengers) => {
  let cash = 0
  const percentage = calculatePercentageFromPrice(points, priceAssignableToPoints, rewards)
  cash = calculatePointsInCash(percentage, priceAssignableToPoints) //Calculate how much cash wasnt cover by points
  //cash += priceAssignableToCash
  //cash += rewards.fee * passengers
  return cash
}

export const calculatePercentageFromPrice = (points, price, rewards) => {
  const { minPoints, maxPoints } = calculatePointsLimitsByPrice(price, rewards)
  const finalPoints = validPoints(points, minPoints, maxPoints)

  return calculatePercentage(finalPoints, minPoints, maxPoints, rewards.min_percentage)
}

export const calculatePercentage = (points, minPoints, maxPoints, minPercentage) => {
  if (points === minPoints) return minPercentage
  else return (points * 100) / maxPoints
}

const priceAssignableToCashOnCluster = (price, priceAssignableToPoints, rewards) => {
  let noAssignableTaxes = price.charges

  noAssignableTaxes += price.taxes - getNoAssignableTaxes(price, rewards)

  const maxDeducibleTaxes = priceAssignableToPoints * (rewards.taxes_max_percentage / 100) //refear to documentation to understand this calculation
  if (noAssignableTaxes > maxDeducibleTaxes) noAssignableTaxes = maxDeducibleTaxes

  return noAssignableTaxes
}

const calculatePassengersOnCluster = price => {
  let passengers = price.adults ? price.adults.quantity : 0
  passengers += price.children ? price.children.quantity : 0
  passengers += price.infants ? price.infants.quantity : 0
  return passengers
}

export const calculatePointsInCash = (percentage, priceAssignableToPoints) => {
  return ((100 - percentage) * priceAssignableToPoints) / 100
}
