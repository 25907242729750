import React from "react"
import PropTypes from "prop-types"
import Checkbox from "material-ui/Checkbox"
import Icon from "components/commons/Icon"
import * as constants from "config/constants"
import _ from "underscore"

class CheckboxFilter extends React.PureComponent {
  checkAll = () => {
    const { options, valueKey, onAddFilter } = this.props
    options.forEach(option => {
      if (option[valueKey] !== constants.CHECK_ALL) {
        onAddFilter(option[valueKey])
      }
    })
  }

  uncheckAll = () => {
    const { options, valueKey, onRemoveFilter } = this.props
    options.forEach(option => {
      if (option[valueKey] !== constants.CHECK_ALL) {
        onRemoveFilter(option[valueKey])
      }
    })
  }

  handleCheck = (isInputChecked, value, key) => {
    const { onAddFilter, onRemoveFilter } = this.props

    if (isInputChecked) {
      if (value === constants.CHECK_ALL) {
        this.checkAll()
      } else {
        onAddFilter(value, key)
      }
    } else if (value === constants.CHECK_ALL) {
      this.uncheckAll()
    } else {
      onRemoveFilter(value, key)
    }
  }

  checked = (option, selected) => {
    const { options, valueKey } = this.props
    if (option[valueKey] === constants.CHECK_ALL) {
      return selected.length === options.length - 1 // -1 because the CHECK_ALL option won't be in the selected array
    }
    return selected.indexOf(option[valueKey]) >= 0
  }

  checkboxes = (options, key) => {
    const { selected, hasIcon, labelKey, iconKey, valueKey, nowrap } = this.props

    let _selected = selected
    if (key) {
      _selected = selected[key]
    }

    return options.map((option, i) => (
      <Checkbox
        checked={this.checked(option, _selected)}
        key={i}
        style={{ marginBottom: 8 }}
        iconStyle={{ marginRight: 8 }}
        labelStyle={nowrap ? { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" } : {}}
        label={[
          hasIcon && option[iconKey] !== constants.CHECK_ALL ? (
            <Icon icon={option[iconKey]} style={{ marginRight: 8 }} key={0} />
          ) : null,
          <span key={1}>{option[labelKey]}</span>
        ]}
        onCheck={(event, isInputChecked) => {
          this.handleCheck(isInputChecked, option[valueKey], key)
        }}
      />
    ))
  }

  render() {
    const { options, labelsWhenObject } = this.props

    if (Array.isArray(options)) {
      return this.checkboxes(options)
    } else if (typeof options === "object") {
      const style = {
        borderBottom: "1px dotted rgba(0, 0, 0, 0.28)",
        paddingBottom: 8,
        marginBottom: 8
      }
      let i = 1
      return _.map(options, (val, key) => (
        <div style={i++ < Object.keys(options).length ? style : {}} key={key}>
          <div style={{ paddingBottom: 8 }}>
            <span style={{ fontWeight: 500 }}>{labelsWhenObject[key]}</span>
          </div>
          {this.checkboxes(val, key)}
        </div>
      ))
    }
  }
}

export default CheckboxFilter

CheckboxFilter.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]),
  selected: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]),
  hasIcon: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  iconKey: PropTypes.string,
  labelsWhenObject: PropTypes.object,
  nowrap: PropTypes.bool,
  onAddFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired
}

CheckboxFilter.defaultProps = {
  selected: [],
  hasIcon: false,
  labelKey: "name",
  valueKey: "code",
  iconKey: "code",
  nowrap: false,
  labelsWhenObject: {}
}
