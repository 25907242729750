import React from "react"
import * as constants from "config/constants"
import StyledSegment from "components/commons/styled/Segment"
import SegmentOption from "components/flights/results/SegmentOption"
import FlightIcon from "material-ui/svg-icons/maps/flight"
import { Table, TableHeader, TableHeaderColumn, TableRow } from "material-ui/Table"
import { Tooltip, OverlayTrigger } from "react-bootstrap"
import { airport } from "utils/flightsHelper"
import { withTranslation } from "react-i18next"

class Segment extends React.PureComponent {
  handleSelectedOption = value => {
    const { onOptionSelected, segmentNumber } = this.props

    onOptionSelected(segmentNumber, value)
  }

  headerFirstColumn = () => {
    const { flightType, segmentNumber, t } = this.props

    if (flightType === constants.ROUND_TRIP) {
      return segmentNumber === 1 ? (
        <span>
          <FlightIcon style={{ transform: "rotate(90deg)" }} /> {t("Ida")}
        </span>
      ) : (
        <span>
          <FlightIcon style={{ transform: "rotate(270deg)" }} /> {t("Regreso")}
        </span>
      )
    }
    return (
      <span>
        <FlightIcon style={{ transform: "rotate(90deg)" }} /> {t("Vuelo")} {segmentNumber}
      </span>
    )
  }

  render() {
    const { selectedOption, options, origin, destination, departureDate, t } = this.props

    return (
      <StyledSegment>
        <Table selectable={false}>
          <TableHeader enableSelectAll={false} displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn>{this.headerFirstColumn()}</TableHeaderColumn>
              <TableHeaderColumn className="blue-th visible-lg" style={{ width: 80 }}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="segmentOriginTooltip">
                      {t("Aeropuerto")} {airport(origin.name)}
                    </Tooltip>
                  }
                >
                  <span>{origin.code}</span>
                </OverlayTrigger>
              </TableHeaderColumn>
              <TableHeaderColumn className="visible-lg" style={{ width: 100 }} />
              <TableHeaderColumn className="blue-th visible-lg" style={{ width: 95 }}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="segmentDestinationTooltip">
                      {t("Aeropuerto")} {airport(destination.name)}
                    </Tooltip>
                  }
                >
                  <span>{destination.code}</span>
                </OverlayTrigger>
              </TableHeaderColumn>
              <TableHeaderColumn colSpan={4}>
                {departureDate.format("ddd. DD [" + t("de") + "] MMM. YYYY")}
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
        </Table>
        {options.map((option, i) => {
          return (
            <SegmentOption
              key={i}
              index={i}
              option={option}
              origin={origin}
              destination={destination}
              selected={selectedOption === option.id || (!selectedOption && i === 0)}
              onSelect={this.handleSelectedOption}
            />
          )
        })}
      </StyledSegment>
    )
  }
}

export default withTranslation()(Segment)
