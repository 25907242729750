import React from "react"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
import FlameIcon from "material-ui/svg-icons/social/whatshot"
import { Tooltip, OverlayTrigger } from "react-bootstrap"
import { optionLeftSeats, clusterLeftSeats } from "utils/flightsHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"

class LastSeats extends React.PureComponent {
  render() {
    const { leg, option, cluster, t } = this.props

    if (leg && leg.cabin_type && leg.cabin_type.quantity < constants.LAST_SEATS_MIN_VALUE) {
      return (
        <p style={legCss}>
          {leg.cabin_type.quantity === 1
            ? t("¡Último lugar!")
            : t("¡Últimos {{qty}} lugares!", { qty: leg.cabin_type.quantity })}
        </p>
      )
    } else if (option) {
      const lastSeats = optionLeftSeats(option)
      if (lastSeats < constants.LAST_SEATS_MIN_VALUE) {
        return (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="multipleMarketingCarriers">{t("¡Últimos lugares!")}</Tooltip>}
          >
            <span style={optionCss}>
              <FlameIcon style={flameCss} /> <span className="visible-lg-inline">{lastSeats}</span>
            </span>
          </OverlayTrigger>
        )
      }
    } else if (cluster) {
      const lastSeats = clusterLeftSeats(cluster)
      return (
        <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
          <div style={clusterCss}>
            {lastSeats < constants.LAST_SEATS_MIN_VALUE ? (
              <div>
                <FlameIcon style={flameCss} /> {t("¡Últimos lugares!")}
              </div>
            ) : null}
          </div>
        </MediaQuery>
      )
    }

    return null
  }
}

export default withTranslation()(LastSeats)

const color = "#d0021b"
const legCss = {
  color: color,
  fontSize: 12
}
const optionCss = {
  color: color,
  fontWeight: 500
}
const clusterCss = {
  color: color,
  textAlign: "center",
  height: 24,
  fontSize: 12,
  fontWeight: "normal"
}
const flameCss = {
  width: 14,
  height: 14,
  color: "#d0021b",
  verticalAlign: "text-top"
}

LastSeats.propTypes = {
  leg: PropTypes.object,
  option: PropTypes.object,
  cluster: PropTypes.object
}
