export const getMissingRateTypes = (cluster, search, t) => {
  const { children, seniors, disabled, infants } = search
  const { price } = cluster
  const rateTypes = []
  if (seniors > 0 && (!price.seniors || price.seniors.quantity === 0)) {
    rateTypes.push(t("AdultoMayor_plural"))
  }
  if (disabled > 0 && (!price.disabled || price.disabled.quantity === 0)) {
    rateTypes.push(t("Discapacitados"))
  }
  if (children > 0 && (!price.children || price.children.quantity === 0)) {
    rateTypes.push(t("Niño_plural"))
  }
  if (infants > 0 && (!price.infants || price.infants.quantity === 0)) {
    rateTypes.push(t("Bebé_plural"))
  }
  return rateTypes
}
