import React from "react"
import styled from "styled-components"
import muiThemeable from "material-ui/styles/muiThemeable"

const Wrapper = styled.div`
  position: relative;
  padding: 8px;
  table {
    table-layout: fixed;
  }
  td {
    white-space: nowrap;
    line-height: 24px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  td.clickable {
    cursor: pointer;
  }
  td.locked {
    text-align: left;
    background-color: #f9f9f9;
  }
  td.highlighted {
    color: ${props => props.theme.selectionColor};
    font-weight: bold;
  }
  td.hovered {
    background: #e1e9f7;
  }
  > div {
    position: absolute !important;
    top: 50%;
    margin: -12px 20px 0 16px;
    width: 24px !important;
    height: 24px !important;
    button {
      width: 24px !important;
      height: 24px !important;
      svg {
        color: ${props => props.theme.clickableColor} !important;
        fill: ${props => props.theme.clickableColor} !important;
        width: 24px !important;
        height: 24px !important;
      }
    }
    button[disabled] {
      svg {
        color: #9b9b9b !important;
        fill: #9b9b9b !important;
      }
    }
  }
  > div:last-child {
    right: 0;
  }
`

class SliderTable extends React.PureComponent {
  render() {
    return <Wrapper theme={this.props.muiTheme.palette}>{this.props.children}</Wrapper>
  }
}

export default muiThemeable()(SliderTable)
