import * as constants from "config/constants"
import moment from "moment"
import _ from "underscore"

function formatDates(flightType, dateFrom, dateTo) {
  // dates
  return dateFrom.format("YYYY-MM-DD") + (flightType === constants.ROUND_TRIP ? "," + dateTo.format("YYYY-MM-DD") : "")
}

function childrenAndInfantsQty(children, childrenAges) {
  let _infants = 0
  let _children = children
  for (let i = 0; i < children; i++) {
    if (parseInt(childrenAges[i], 10) === constants.INFANTS_CHILDREN_AGE) {
      _infants++
      _children--
    }
  }

  return { children: _children, infants: _infants }
}

/**
 * SearchBox props to api querystring params
 * @param props
 * @returns {{from: string, to: string, dates: string, adults: *}}
 */
export const propsToApiFilters = props => {
  const {
    flightType,
    flights,
    adults,
    seniors,
    disabled,
    children,
    childrenAges,
    cabinType,
    baggageType,
    stops
  } = props

  let from = []
  let to = []
  let dates = []
  _.each(flights, flight => {
    from.push(flight.origin.iata_code)
    to.push(flight.destination.iata_code)
    dates.push(flight.dateFrom.format("YYYY-MM-DD"))

    if (flightType === constants.ROUND_TRIP) {
      to.push(flight.origin.iata_code)
      from.push(flight.destination.iata_code)

      dates.push(flight.dateTo.format("YYYY-MM-DD"))
    }
  })

  let apiFilters = {
    from: from.join(","),
    to: to.join(","),
    dates: dates.join(","),
    adults: adults
  }

  if (seniors !== constants.SENIORS_DEFAULT_VALUE) {
    apiFilters.seniors = seniors
  }

  if (disabled !== constants.DISABLED_DEFAULT_VALUE) {
    apiFilters.disabled = disabled
  }

  let childInft = childrenAndInfantsQty(children, childrenAges)
  if (childInft.children > 0) {
    apiFilters.children = childInft.children
  }
  if (childInft.infants > 0) {
    apiFilters.infants = childInft.infants
  }

  if (cabinType !== constants.SEARCH_BOX_CABIN_TYPE_DEFAULT_VALUE) {
    apiFilters.cabin = cabinType
  }
  if (baggageType !== constants.BAGGAGE_TYPE_ANY) {
    apiFilters.baggage_type = baggageType
  }

  if (stops !== constants.SEARCH_BOX_STOPS_DEFAULT_VALUE) {
    apiFilters.stops = stops
  }

  return apiFilters
}

/**
 * Querystring params to SearchBox props
 * @param params
 * @param state
 * @returns {*}
 */
export const queryParamsAndStateToProps = (params, state) => {
  // TODO: Ver si podemos evitar modificar el estado cuando clickeamos el boton de buscar de la searchBox
  //if (state.flights.length && !_.isEmpty(state.flights[0].origin) && !_.isEmpty(state.flights[0].destination)) {
  //  return { ...state, open: false }
  //}

  let flights = []
  let flightType = params.type

  if (constants.FLIGHT_TYPES.indexOf(flightType) === -1) {
    throw new Error("Invalid URL: invalid type")
  }

  let fromArr = params.from.split(","),
    toArr = params.to.split(","),
    datesArr = params.dates.split("|")

  _.each(fromArr, (from, index) => {
    let to = toArr[index],
      dates = datesArr[index].split(","),
      dateFrom = moment(dates[0]),
      dateTo = flightType === constants.ROUND_TRIP ? moment(dates[1]) : null

    flights.push({
      origin: from ? { load: true, iata_code: from } : {},
      destination: to ? { load: true, iata_code: to } : {},
      dateFrom: dateFrom,
      dateTo: dateTo
    })
  })

  let childrenAges = {},
    infants = params.infants ? parseInt(params.infants, 10) : constants.INFANTS_DEFAULT_VALUE,
    children = (params.children ? parseInt(params.children, 10) : constants.CHILDREN_DEFAULT_VALUE) + infants

  for (let i = 0; i < children; i++) {
    childrenAges[i] = i < infants ? constants.INFANTS_CHILDREN_AGE : 2
  }

  return {
    ...state,
    open: false,
    flightType: flightType,
    flights: flights,
    adults: params.adults ? parseInt(params.adults, 10) : constants.ADULTS_DEFAULT_VALUE,
    seniors: params.seniors ? parseInt(params.seniors, 10) : constants.SENIORS_DEFAULT_VALUE,
    disabled: params.disabled ? parseInt(params.disabled, 10) : constants.DISABLED_DEFAULT_VALUE,
    children: children,
    childrenAges: childrenAges,
    cabinType: params.cabin ? params.cabin : constants.SEARCH_BOX_CABIN_TYPE_DEFAULT_VALUE,
    baggageType: params.baggage_type ? params.baggage_type : constants.BAGGAGE_TYPE_ANY,
    stops: params.stops ? parseInt(params.stops, 10) : constants.SEARCH_BOX_STOPS_DEFAULT_VALUE
  }
}

/**
 * SearchBox props to querystring
 * @param props
 */
export const propsToQuerystring = props => {
  const {
    flightType,
    flights,
    adults,
    seniors,
    disabled,
    children,
    childrenAges,
    cabinType,
    baggageType,
    stops
  } = props

  let from = [],
    to = [],
    dates = []
  _.each(flights, flight => {
    from.push(flight.origin.iata_code)
    to.push(flight.destination.iata_code)
    dates.push(formatDates(flightType, flight.dateFrom, flight.dateTo))
  })

  let queryString = {
    type: flightType,
    from: from.join(","),
    to: to.join(","),
    dates: dates.join("|")
  }

  if (adults !== constants.ADULTS_DEFAULT_VALUE) {
    queryString.adults = adults
  }

  if (seniors !== constants.SENIORS_DEFAULT_VALUE) {
    queryString.seniors = seniors
  }

  if (disabled !== constants.DISABLED_DEFAULT_VALUE) {
    queryString.disabled = disabled
  }

  let childInft = childrenAndInfantsQty(children, childrenAges)
  if (childInft.children !== constants.CHILDREN_DEFAULT_VALUE) {
    queryString.children = childInft.children
  }
  if (childInft.infants !== constants.INFANTS_DEFAULT_VALUE) {
    queryString.infants = childInft.infants
  }

  if (cabinType !== constants.SEARCH_BOX_CABIN_TYPE_DEFAULT_VALUE) {
    queryString.cabin = cabinType
  }

  if (stops !== constants.SEARCH_BOX_STOPS_DEFAULT_VALUE) {
    queryString.stops = stops
  }

  if (baggageType !== constants.BAGGAGE_TYPE_ANY) {
    queryString.baggage_type = baggageType
  }

  return queryString
}

export const validateProps = props => {
  const { t } = props

  var errors = {}

  _.each(props.flights, (flight, index) => {
    let err = {}
    if (_.isEmpty(flight.origin)) {
      err.origin = t("Debe seleccionar el {{field}}", { field: t("Orígen") })
    }
    if (_.isEmpty(flight.destination)) {
      err.destination = t("Debe seleccionar el {{field}}", { field: t("Destino") })
    }
    if (flight.origin.iata_code === flight.destination.iata_code) {
      err.origin = t("El {{field1}} no puede ser el mismo que el {{field2}}", {
        field1: t("Orígen"),
        field2: t("Destino")
      })
      err.destination = t("El {{field1}} no puede ser el mismo que el {{field2}}", {
        field1: t("Destino"),
        field2: t("Orígen")
      })
    }
    if (!flight.dateFrom) {
      err.date = t("La fecha de {{field}} no puede estar vacía", { field: t("Ida") })
    }
    if (props.flightType === constants.ROUND_TRIP) {
      if (!flight.dateTo) {
        err.date = t("La fecha de {{field}} no puede estar vacía", { field: t("Regreso") })
      } else if (!flight.dateTo.isSameOrAfter(flight.dateFrom)) {
        err.date = t("La fecha de {{field1}} debe ser posterior a la fecha de {{field2}}", {
          field1: t("Regreso"),
          field2: t("Ida")
        })
      }
    }
    if (!_.isEmpty(err)) {
      errors[index] = err
    }
  })

  let childInft = childrenAndInfantsQty(props.children, props.childrenAges)
  if (childInft.infants > props.adults) {
    errors["adults"] = t("La cantidad de menores en brazos debe ser igual o menor a la cantidad de adultos.")
  }

  return errors
}
