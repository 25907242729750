import { CALL_API } from "../middleware/api"
import { stringify } from "query-string"
import { getClientConfig } from "utils/flightsHelper"
//import _ from 'underscore'

export const SEARCH_CLUSTERS_REQUEST = "SEARCH_CLUSTERS_REQUEST"
export const SEARCH_CLUSTERS_SUCCESS = "SEARCH_CLUSTERS_SUCCESS"
export const SEARCH_CLUSTERS_FAILURE = "SEARCH_CLUSTERS_FAILURE"

export const fetchClusters = filters => {
  const config = getClientConfig()

  filters = "?" + stringify({ ...filters, site: config.site, channel: config.channel })

  const applications = config.applications

  if (!!applications) {
    const source =
      applications && Array.isArray(applications) && applications.some(a => a.category === "REWARDS") ? "REWARDS" : null
    if (source != null) filters += "&app=" + source
  }

  // Dejamos esto aca por si estamos sin internet o se cae la api y necesitamos avanzar
  /*if (process.env.NODE_ENV === 'development') {
    const clusters = require('./mocks/clusters.json')
    return {
      type: SEARCH_CLUSTERS_SUCCESS,
      response: clusters
    }
  }*/

  return {
    [CALL_API]: {
      types: [SEARCH_CLUSTERS_REQUEST, SEARCH_CLUSTERS_SUCCESS, SEARCH_CLUSTERS_FAILURE],
      endpoint: `${process.env.REACT_APP_CLUSTERS_API_URI}/clusters${filters}`
    }
  }
}

export const boundFetchClusters = filters => dispatch => {
  return dispatch(fetchClusters(filters))
}

export const CLEAR_ALL = "CLEAR_ALL"
export const clearAll = () => {
  return {
    type: CLEAR_ALL
  }
}
export const boundClearAll = () => dispatch => {
  return dispatch(clearAll())
}

export const CLEAR_ALL_BUT_FILTERS = "CLEAR_ALL_BUT_FILTERS"
export const clearAllButFilters = () => {
  return {
    type: CLEAR_ALL_BUT_FILTERS
  }
}
export const boundClearAllButFilters = () => dispatch => {
  return dispatch(clearAllButFilters())
}
