import React from "react"
import PropTypes from "prop-types"
import muiThemeable from "material-ui/styles/muiThemeable"
import { css } from "glamor"
import * as constants from "config/constants"
import { Link } from "react-router-dom"

class SliderAdvertising extends React.Component {
  state = {
    indexToShow: 0,
    hide: false
  }

  componentDidMount() {
    const { slider } = this.props
    let interval = setInterval(() => {
      let indexToShow = this.state.indexToShow
      indexToShow++
      if (indexToShow === slider.images.length) {
        indexToShow = 0
      }
      this.setState({ indexToShow: indexToShow })
    }, constants.SLIDER_ANIMATION_INTERVAL)
    if (slider.duration > 0) {
      setTimeout(() => {
        clearInterval(interval)
        this.setState({ hide: true })
      }, slider.duration * 1000)
    }
  }

  render() {
    const { slider } = this.props
    return (
      <div className={this.state.hide ? hideSliderContainer : sliderContainer}>
        {slider.images.map((image, i) =>
          image.link !== "" ? (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              key={i}
              to={image.link}
              className={slideItem(this.state.indexToShow === i)}
            >
              <img className={slideContent} alt={image.alt} src={image.url} />
            </Link>
          ) : (
            <div key={i} className={slideItem(this.state.indexToShow === i)}>
              <img className={slideContent} alt={image.alt} src={image.url} />
            </div>
          )
        )}
      </div>
    )
  }
}
const animateFade = css.keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 }
})
const slideItem = show =>
  css({
    display: show ? "block" : "none",
    textAlign: "center",
    height: "100%",
    width: "100%",
    position: "relative",
    animation: `${animateFade} 0.5s`
  })
const slideContent = css({
  maxWidth: "100%",
  maxHeight: "100%"
})
const sliderContainer = css({
  width: "100%",
  marginBottom: "10px"
})
const hideSliderContainer = css({
  maxHeight: "0",
  overflow: "hidden",
  transition: "max-height .5s"
})

SliderAdvertising.propTypes = {
  slider: PropTypes.object.isRequired
}

export default muiThemeable()(SliderAdvertising)
