import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M128 213.333c-47.36 0-85.333 37.973-85.333 85.333v341.333h85.333c0 70.692 57.308 128 128 128s128-57.308 128-128h256c0 70.692 57.308 128 128 128s128-57.308 128-128h85.333v-170.667c0-47.36-37.973-85.333-85.333-85.333l-128-170.667h-640zM128 277.333h442.667v106.667h-442.667v-106.667zM640 277.333h106.667l83.627 106.667h-190.293v-106.667zM256 576c35.346 0 64 28.654 64 64s-28.654 64-64 64c-35.346 0-64-28.654-64-64s28.654-64 64-64v0zM768 576c35.346 0 64 28.654 64 64s-28.654 64-64 64c-35.346 0-64-28.654-64-64s28.654-64 64-64v0z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }]
}
export default class Van extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
