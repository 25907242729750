import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Row, Col, Grid } from "react-bootstrap"
import { boundFetchClusters, boundClearAll, boundClearAllButFilters } from "actions/search"
import { resetErrorMessage } from "actions"
import MediaQuery from "react-responsive"
import SearchBox from "components/flights/search/SearchBox"
import FlightsFilters from "components/flights/filters/FlightsFilters"
import PricesBy from "components/flights/results/PricesBy"
import FlightsResults from "components/flights/results/FlightsResults"
import ResultMessage from "components/commons/ResultMessage"
import { fromMatrixToPricesBy, clearAppFilterParamsFromSearch, clearAppParamsFromSearch } from "utils/flightsHelper"
import { propsToQuerystring, propsToApiFilters } from "utils/searchBoxHelper"
import { filterPropsToApiFilters } from "utils/filtersHelper"
import { parse, stringify } from "query-string"
import errorImg from "../images/error@3x.png"
import noResultsImg from "../images/no-results@3x.png"
import { withTranslation } from "react-i18next"
import moment from "moment"
import * as constants from "config/constants"
import ReactGA from "react-ga"
import { getAdvertising } from "utils/flightsHelper"
import { getClientConfig } from "utils/flightsHelper"
import SliderAdvertising from "components/commons/SliderAdvertising"

const loadSearch = props => {
  const { fetchClusters, searchBoxContProps, filtersContProps, offset, limit } = props
  fetchClusters({
    offset: offset,
    limit: limit,
    ...propsToApiFilters(searchBoxContProps),
    ...filterPropsToApiFilters(filtersContProps)
  })
}

export class FlightsResultsContainer extends React.PureComponent {
  state = {
    advertising: {},
    flag: false
  }

  constructor(props) {
    super(props)

    if (props.querystring.length) {
      loadSearch(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.executeSearch) {
      ReactGA.pageview(window.location.pathname + window.location.search)
      loadSearch(nextProps)
    }
    const clientConfig = getClientConfig()
    getAdvertising(clientConfig.clientId, clientConfig.site).then(advertising => {
      this.setState({ advertising: advertising })
    })
  }

  handlePaginatedSearch = () => {
    const { location, history, total, offset, limit, resetErrorMessage } = this.props
    if (!total || total > offset + limit) {
      // if we know the total items, only search if the total is > than what we already got

      resetErrorMessage()

      const currentPage = offset / constants.CLUSTERS_PER_PAGE + 1
      history.push({
        // search with current location search + page = new page
        search: stringify({ ...parse(location.search), page: currentPage + 1 }, { encode: false })
      })

      ReactGA.event({
        category: "Flight Results",
        action: "Scroll to page: " + (currentPage + 1),
        label: "Clusters List"
      })
    }
  }

  handleSubmitFilters = filters => {
    const { location, history, clearAllButFilters, resetErrorMessage } = this.props

    resetErrorMessage()
    // clear results only when the form is submited, the clearAllButFilters was extracted to avoid clear when searching
    // new pages for pagination
    clearAllButFilters()

    const currentSearch = clearAppFilterParamsFromSearch(parse(location.search))

    // then, push the new querystring
    history.push({
      search: stringify({ ...currentSearch, ...filterPropsToApiFilters(filters) }, { encode: false })
    })
  }

  handleSubmitSearch = filters => {
    const { history, location, clearAll, resetErrorMessage } = this.props

    resetErrorMessage()
    // clear results only when the form is submited, the clearAll was extracted to avoid clear when searching new pages for pagination
    clearAll()
    // then, push the new querystring
    history.push({
      search: stringify(
        { ...clearAppParamsFromSearch(parse(location.search)), ...propsToQuerystring(filters) },
        { encode: false }
      )
    })
  }

  /**
   * used for testing purposes of the infinite scroll pagination
   * @returns null || XML
   */
  paginationInfo = () => {
    if (process.env.NODE_ENV === "development") {
      const { clusters, total, offset, limit, i18n } = this.props

      const style = {
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 10000,
        background: "#000",
        color: "#fff",
        padding: 10
      }
      return (
        <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
          <div
            style={style}
            onClick={() => {
              i18n.changeLanguage("en")
              moment.locale("en")
            }}
          >
            Clusters renderizados: {clusters.length}/{total} - Offset: {offset} Limit: {limit}
          </div>
        </MediaQuery>
      )
    }
    return null
  }

  handleClickMatrix = elem => {
    this.handleSubmitFilters(elem.filters)

    if (elem["filters"]["airlines"] !== undefined) {
      ReactGA.event({
        category: "Flight Results",
        action: "Airline: " + elem["filters"]["airlines"][0],
        label: "Matrix"
      })
    }

    if (elem["filters"]["stops"] !== undefined) {
      let code
      switch (elem["filters"]["stops"][0]) {
        case 0:
          code = "Direct"
          break
        case 1:
          code = "One stop"
          break
        case 2:
          code = "Two or more stops"
          break
        default:
          code = ""
      }

      ReactGA.event({
        category: "Flight Results",
        action: "Stops: " + code,
        label: "Matrix"
      })
    }
  }

  results = () => {
    const { clusters, matrix, flightType, total, isLoading, applications, errorMessage, t } = this.props
    const applicationsRewards = applications && applications.rewards ? applications.rewards : null
    const rewards = applicationsRewards ? applicationsRewards.response : null

    if (errorMessage) {
      if (typeof errorMessage === "string" || errorMessage instanceof String) {
        const res = errorMessage.match(/([A-Z_]+)\s{{(.+)}}/)
        let translatedErrorMessage
        if (res) {
          translatedErrorMessage = t(res[1], { param: res[2] })
        } else {
          translatedErrorMessage = t(errorMessage)
        }

        return (
          <ResultMessage
            title={t("Ups! algo salió mal")}
            text={t("Vuelva a realizar la búsqueda.", { errorMessage: translatedErrorMessage })}
            titleColor="#d0021b"
            bgColor="rgba(208, 2, 27, 0.1)"
            img={errorImg}
          />
        )
      }
    }

    if (total === 0) {
      return (
        <ResultMessage
          title={t("No se encontraron resultados.")}
          text={t(
            "Puede que no haya lugar en los vuelos o que no haya vuelos en esos días. Lamentablemente no encontramos alternativas disponibles para este destino. Prueba con aeropuertos cercanos u otro destinos."
          )}
          titleColor="#1842d8"
          bgColor="rgba(70, 128, 233, 0.12)"
          img={noResultsImg}
        />
      )
    }

    return (
      <div>
        {this.state.advertising.slider && <SliderAdvertising slider={this.state.advertising.slider} />}
        {!rewards && (
          <MediaQuery minWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MIN}>
            <PricesBy
              dataSource={fromMatrixToPricesBy(matrix, t)}
              isLoading={isLoading}
              onCellClick={this.handleClickMatrix}
            />
          </MediaQuery>
        )}

        <FlightsResults
          clusters={clusters}
          flightType={flightType}
          isLoading={isLoading}
          applications={applications}
          onPaginatedSearch={this.handlePaginatedSearch}
          financial={this.state.advertising.financial}
        />
      </div>
    )
  }

  render() {
    const { filters } = this.props
    return (
      <Grid className="main-container">
        <Row>
          <Col md={3} className="sidebar">
            <SearchBox onSubmitSearch={this.handleSubmitSearch} />
            <FlightsFilters availableFilters={filters} onSubmitFilters={this.handleSubmitFilters} />
          </Col>
          <Col md={9}>
            {this.paginationInfo()}
            {this.results()}
          </Col>
        </Row>
      </Grid>
    )
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}

const mapStateToProps = (state, ownProps) => {
  const { router, search, searchBox, filters, errorMessage } = state

  return {
    querystring: router.search,
    searchBoxContProps: searchBox,
    filtersContProps: filters,
    flightType: search.flightType,
    clusters: search.clusters,
    matrix: search.matrix,
    metadata: search.metadata,
    filters: search.filters,
    applications: search.applications,
    total: search.total,
    offset: search.offset,
    limit: search.limit,
    executeSearch: search.executeSearch,
    isLoading: search.isLoading,
    errorMessage: errorMessage
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchClusters: boundFetchClusters,
      clearAll: boundClearAll,
      clearAllButFilters: boundClearAllButFilters,
      resetErrorMessage: resetErrorMessage
    }
  )(withTranslation()(FlightsResultsContainer))
)

FlightsResultsContainer.propTypes = {
  querystring: PropTypes.string.isRequired,
  searchBoxContProps: PropTypes.object.isRequired,
  filtersContProps: PropTypes.object.isRequired,

  flightType: PropTypes.oneOf(constants.FLIGHT_TYPES),
  clusters: PropTypes.array.isRequired,
  matrix: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  applications: PropTypes.object,
  // pagination
  total: PropTypes.number,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  // loading state
  executeSearch: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // actions: main actions
  fetchClusters: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  clearAllButFilters: PropTypes.func.isRequired,
  resetErrorMessage: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
}
