import React from "react"
import PropTypes from "prop-types"
import muiThemeable from "material-ui/styles/muiThemeable"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import {
  boundToggleSearchBox,
  boundToggleAdvancedSearch,
  boundChangeFlightType,
  boundAddFlight,
  boundRemoveFlight,
  boundSelectOrigin,
  boundSelectDestination,
  boundSelectDateRange,
  boundChangeAdults,
  boundChangeSeniors,
  boundChangeDisabled,
  boundChangeChildren,
  boundChangeChildrenAge,
  boundChangeCabinType,
  boundChangeBaggageType,
  boundChangeStops
} from "actions"
import {
  boundFetchOrigins,
  boundFetchDestinations,
  boundClearLocations,
  boundShowLocationsPlaceholder,
  boundPreloadLocations
} from "actions/location"
import MediaQuery from "react-responsive"
import SearchBoxMobileBar from "components/mobile/search/SearchBoxMobileBar"
import { FormGroup, InputGroup, Clearfix, Tooltip, OverlayTrigger } from "react-bootstrap"
import FlightTakeoffIcon from "material-ui/svg-icons/action/flight-takeoff"
import FlightLandIcon from "material-ui/svg-icons/action/flight-land"
import PersonIcon from "material-ui/svg-icons/social/person"
import FaceIcon from "material-ui/svg-icons/action/face"
import CloseIcon from "material-ui/svg-icons/navigation/close"
import AddCircleIcon from "material-ui/svg-icons/content/add-circle"
import MenuItem from "material-ui/MenuItem"
import Checkbox from "material-ui/Checkbox"
import RaisedButton from "material-ui/RaisedButton"
import { RadioButton } from "material-ui/RadioButton"
import RadioButtonGroup from "components/commons/styled/RadioButtonGroup"
import InputGroupAddon from "components/commons/styled/InputGroupAddon"
import SelectField from "components/commons/styled/SelectField"
import StyledSearchBox from "components/commons/styled/SearchBox"
import LocationAutocomplete from "components/commons/LocationAutocomplete"
import SearchBoxAdvancedSearch from "components/flights/search/SearchBoxAdvancedSearch"
import SearchBoxDateRangePicker from "components/flights/search/SearchBoxDateRangePicker"
import SearchBoxNumberField from "components/flights/search/SearchBoxNumberField"
import { isMobile } from "utils/flightsHelper"
import { validateProps } from "utils/searchBoxHelper"
import { withTranslation } from "react-i18next"
import * as constants from "config/constants"
import _ from "underscore"
import ReactGA from "react-ga"

const personIcon = <PersonIcon />
const faceIcon = <FaceIcon />
const flightTakeoffIcon = <FlightTakeoffIcon />
const flightLandIcon = <FlightLandIcon />

const preloadLocations = props => {
  const { flights, preloadLocations, selectOrigin, selectDestination } = props

  var iataCodes = []
  flights.forEach((flight, i) => {
    ;["origin", "destination"].forEach(type => {
      if (flight[type].load) {
        const cachedLocation = localStorage.getItem(flight[type].iata_code)
        if (cachedLocation === null) {
          iataCodes.push(flight[type].iata_code)
        } else if (type === "origin") {
          selectOrigin(JSON.parse(cachedLocation), i)
        } else if (type === "destination") {
          selectDestination(JSON.parse(cachedLocation), i)
        }
      }
    })
  })

  if (iataCodes.length) {
    preloadLocations(_.uniq(iataCodes))
  }
}

class SearchBox extends React.PureComponent {
  state = {
    errors: {}
  }

  constructor(props) {
    super(props)
    preloadLocations(props)
  }

  componentWillReceiveProps(nextProps) {
    preloadLocations(nextProps)
  }

  handleToggleSearchBox = () => {
    this.props.toggleSearchBox()
  }

  handleToggleAdvancedSearch = () => {
    this.props.toggleAdvancedSearch()
  }

  handleChangeFlightType = (event, value) => {
    this.props.changeFlightType(value)
  }

  handleOriginUpdateInput = value => {
    if (value.trim().length >= 3) {
      this.props.fetchOrigins(value)
    } else if (value.length >= 1) {
      this.props.showLocationsPlaceholder("origins")
    } else {
      this.props.clearLocations("origins")
    }
  }

  handleDestinationUpdateInput = value => {
    if (value.trim().length >= 3) {
      this.props.fetchDestinations(value)
    } else if (value.length >= 1) {
      this.props.showLocationsPlaceholder("destinations")
    } else {
      this.props.clearLocations("destinations")
    }
  }

  handleSelectOrigin = (item, index) => {
    // clear error
    let errors = _.clone(this.state.errors)
    if (index in errors) {
      delete errors[index].origin
      this.updateErrors(errors)
    }

    this.props.selectOrigin(item, index)
  }

  handleSelectDestination = (item, index) => {
    // clear error
    let errors = _.clone(this.state.errors)
    if (index in errors) {
      delete errors[index].destination
      this.updateErrors(errors)
    }

    this.props.selectDestination(item, index)
  }

  handleClearLocations = where => {
    this.props.clearLocations(where)
  }

  handleSelectDateRange = (start, end, index) => {
    this.props.selectDateRange(start, end, index)
  }

  handleChangeAdults = adults => {
    this.props.changeAdults(adults)
  }

  handleChangeSeniors = seniors => {
    this.props.changeSeniors(seniors)
  }

  handleChangeDisabled = disabled => {
    this.props.changeDisabled(disabled)
  }

  handleChangeChildren = children => {
    this.props.changeChildren(children)
  }

  handleChangeChildrenAge = (value, index) => {
    this.props.changeChildrenAge(value, index)
  }

  handleChangeCabinType = cabinType => {
    this.props.changeCabinType(cabinType)
  }

  handleChangeBaggageType = baggageType => {
    if (baggageType === true) {
      this.props.changeBaggageType(constants.BAGGAGE_TYPE_CHECKED)
    } else {
      this.props.changeBaggageType(constants.BAGGAGE_TYPE_ANY)
    }
  }

  handleChangeStops = stops => {
    this.props.changeStops(stops)
  }

  handleAddFlight = event => {
    this.props.addFlight()
    event.preventDefault()
  }

  handleRemoveFlight = (event, index) => {
    this.props.removeFlight(index)
    event.preventDefault()
  }

  updateErrors = errors => {
    // update state if changed
    if (!_.isEqual(this.state.errors, errors)) {
      this.setState({ errors: errors })
    }
  }

  handleSearch = event => {
    const errors = validateProps(this.props)

    this.updateErrors(errors)

    if (_.isEmpty(errors)) {
      this.props.onSubmitSearch(this.props)
      ReactGA.event({
        category: "Flight Results",
        action: "Re search",
        label: "Searchbox"
      })
    }

    event.preventDefault()
  }

  addFlightButton = () => {
    const { flightType, flights, t } = this.props

    if (flightType === constants.MULTI_DESTINATION && flights.length < constants.MULTI_DESTINATION_MAX_FLIGHTS) {
      return (
        <FormGroup>
          <Link role="button" to={""} id="addFlight" onClick={this.handleAddFlight}>
            <AddCircleIcon />
            {t("Sumar vuelo")}
          </Link>
        </FormGroup>
      )
    }
    return null
  }

  hasError = (key, index = undefined) => {
    const { errors } = this.state

    return Boolean(index !== undefined ? index in errors && errors[index][key] : errors[key])
  }

  wrapWithErrorTooltip = (node, key, index = undefined) => {
    const { errors } = this.state

    if (this.hasError(key, index)) {
      return (
        <FormGroup validationState={"error"}>
          <OverlayTrigger
            trigger={[]}
            defaultOverlayShown={true}
            placement={isMobile() ? "top" : "right"}
            overlay={
              <Tooltip id={"searchBoxError" + (index ? index : "") + key} bsClass="error-tooltip">
                <div style={{ padding: 8 }}>{index !== undefined ? errors[index][key] : errors[key]}</div>
              </Tooltip>
            }
          >
            {node}
          </OverlayTrigger>
        </FormGroup>
      )
    }

    return <FormGroup>{node}</FormGroup>
  }

  render() {
    const {
      open,
      openAdvanced,
      flightType,
      flights,
      origins,
      destinations,
      adults,
      seniors,
      disabled,
      children,
      childrenAges,
      cabinType,
      baggageType,
      stops,
      t
    } = this.props

    const max = constants.PASSENGERS_MAX_VALUE
    const maxAdults = max - seniors - disabled - children
    const maxSeniors = max - adults - disabled - children
    const maxDisabled = max - adults - seniors - children
    const maxChildren = max - adults - seniors - disabled
    const highlightedDates = flights.map(flight => flight.dateFrom)

    let prevFlight
    return (
      <div>
        <MediaQuery maxWidth={constants.MEDIA_QUERY_BREAKPOINT_MOBILE_MAX}>
          <SearchBoxMobileBar
            flightType={flightType}
            flights={flights}
            adults={adults}
            seniors={seniors}
            disabled={disabled}
            children={children}
            onToggle={this.handleToggleSearchBox}
          />
        </MediaQuery>
        <StyledSearchBox open={open}>
          <form onSubmit={this.handleSearch}>
            <FormGroup>
              <RadioButtonGroup name="flightType" defaultSelected={flightType} onChange={this.handleChangeFlightType}>
                <RadioButton
                  value={constants.ROUND_TRIP}
                  label={t("Ida y vuelta")}
                  style={{ width: "auto", float: "left", whiteSpace: "nowrap", marginRight: 15 }}
                />
                <RadioButton
                  value={constants.ONE_WAY}
                  label={t("Solo ida")}
                  style={{ width: "auto", float: "left", whiteSpace: "nowrap", marginRight: 15 }}
                />
                <RadioButton
                  value={constants.MULTI_DESTINATION}
                  label={t("Multidestino")}
                  style={{ width: "auto", whiteSpace: "nowrap" }}
                />
              </RadioButtonGroup>
            </FormGroup>

            {flights.map((flight, index) => {
              const minDate = prevFlight && prevFlight.dateFrom ? prevFlight.dateFrom : undefined
              const multidestinationFlightLabel = (
                <label style={{ fontSize: 16, lineHeight: 1.5, marginBottom: 6 }}>
                  {t("Vuelo")} {index + 1}
                </label>
              )

              prevFlight = flight

              return (
                <div key={index}>
                  {index === 0 && flightType === constants.MULTI_DESTINATION && multidestinationFlightLabel}
                  {index > 0 && (
                    <div>
                      <hr />
                      {multidestinationFlightLabel}
                      <Link
                        role="button"
                        to={""}
                        onClick={event => {
                          this.handleRemoveFlight(event, index)
                        }}
                      >
                        <CloseIcon style={{ float: "right" }} />
                      </Link>
                    </div>
                  )}
                  {this.wrapWithErrorTooltip(
                    <LocationAutocomplete
                      icon={flightTakeoffIcon}
                      label={t("Orígen")}
                      placeholder={t("Ingresá desde dónde viajás")}
                      index={index}
                      selectedLocation={flight.origin}
                      error={this.hasError("origin", index)}
                      dataSource={origins}
                      onUpdateInput={this.handleOriginUpdateInput}
                      onSelectItem={this.handleSelectOrigin}
                      onClear={() => {
                        this.handleClearLocations("origins")
                      }}
                    />,
                    "origin",
                    index
                  )}
                  {this.wrapWithErrorTooltip(
                    <LocationAutocomplete
                      icon={flightLandIcon}
                      label={t("Destino")}
                      placeholder={t("Ingresá hacia dónde viajás")}
                      index={index}
                      selectedLocation={flight.destination}
                      error={this.hasError("destination", index)}
                      dataSource={destinations}
                      onUpdateInput={this.handleDestinationUpdateInput}
                      onSelectItem={this.handleSelectDestination}
                      onClear={() => {
                        this.handleClearLocations("destinations")
                      }}
                    />,
                    "destination",
                    index
                  )}
                  {flights.length === 1 && this.addFlightButton()}
                  {this.wrapWithErrorTooltip(
                    <SearchBoxDateRangePicker
                      flightType={flightType}
                      label={t("Fechas")}
                      index={index}
                      selectedDateFrom={flight.dateFrom}
                      selectedDateTo={flight.dateTo}
                      error={this.hasError("date", index)}
                      minDate={minDate}
                      highlightedDates={highlightedDates}
                      onSelectRange={this.handleSelectDateRange}
                      inputValueFormat={"DD MMM YYYY"}
                    />,
                    "date",
                    index
                  )}
                  <Clearfix />
                </div>
              )
            })}
            {flights.length > 1 && this.addFlightButton()}
            {false && flights.length === 1 && (
              <FormGroup>
                <Checkbox label={t("Fechas flexibles")} iconStyle={{ marginRight: 8 }} />
              </FormGroup>
            )}
            {this.wrapWithErrorTooltip(
              <SearchBoxNumberField
                icon={personIcon}
                label={t("Adultos")}
                placeholder={t("+ 12 años")}
                textFieldId="adults"
                selectedNumber={adults}
                error={this.hasError("adults")}
                minValue={seniors + disabled > 0 ? 0 : constants.ADULTS_MIN_VALUE} // when we have seniors or disabled we can have 0 adults
                maxValue={maxAdults}
                onChangeNumber={this.handleChangeAdults}
              />,
              "adults"
            )}
            <FormGroup>
              <SearchBoxNumberField
                icon={faceIcon}
                label={t("Menores")}
                placeholder={t("- 11 años")}
                textFieldId="children"
                selectedNumber={children}
                minValue={constants.CHILDREN_MIN_VALUE}
                maxValue={maxChildren}
                onChangeNumber={this.handleChangeChildren}
              />
            </FormGroup>
            {children > 0 && ( // showing chidlren age if the user adds children
              <div>
                <label style={{ marginBottom: 8 }}>{t("Edad de los menores al finalizar el viaje")}</label>

                {[...Array(Math.min(parseInt(children, 10), maxChildren))].map((
                  x,
                  i // showing chidlren age if the user adds children
                ) => (
                  <FormGroup key={i}>
                    <InputGroup>
                      <InputGroupAddon style={{ width: 48 }}>
                        <FaceIcon /> {i + 1}
                      </InputGroupAddon>
                      <SelectField
                        autoWidth={true}
                        dropDownMenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" } }}
                        value={i in childrenAges ? parseInt(childrenAges[i], 10) : constants.DEFAULT_CHILDREN_AGE}
                        onChange={(event, key, payload) => {
                          this.handleChangeChildrenAge(payload, i)
                        }}
                        id="childrenAgeAfterTrip"
                        fullWidth={true}
                      >
                        {constants.CHILDREN_AGES_OPTIONS.map((option, index) => (
                          <MenuItem value={index} primaryText={t(option)} key={index} style={{ fontSize: 14 }} />
                        ))}
                      </SelectField>
                    </InputGroup>
                  </FormGroup>
                ))}
              </div>
            )}
            <SearchBoxAdvancedSearch
              open={openAdvanced}
              seniors={seniors}
              disabled={disabled}
              adults={adults}
              cabinType={cabinType}
              baggageType={baggageType}
              stops={stops}
              maxSeniors={maxSeniors}
              maxDisabled={maxDisabled}
              onToggle={this.handleToggleAdvancedSearch}
              onChangeSeniors={this.handleChangeSeniors}
              onChangeDisabled={this.handleChangeDisabled}
              onChangeCabinType={this.handleChangeCabinType}
              onChangeBaggageType={this.handleChangeBaggageType}
              onChangeStops={this.handleChangeStops}
            />
            <FormGroup>
              <RaisedButton
                id="searchBoxSubmit"
                label={t("Buscar")}
                fullWidth={true}
                backgroundColor={this.props.muiTheme.palette.searchBoxClickableColor}
                onClick={this.handleSearch}
              />
            </FormGroup>
          </form>
        </StyledSearchBox>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, searchBox } = state

  return {
    open: searchBox.open,
    openAdvanced: searchBox.openAdvanced,
    // search vars
    flightType: searchBox.flightType,
    flights: searchBox.flights,
    adults: searchBox.adults,
    seniors: searchBox.seniors,
    disabled: searchBox.disabled,
    children: searchBox.children,
    childrenAges: searchBox.childrenAges,
    cabinType: searchBox.cabinType,
    baggageType: searchBox.baggageType,
    stops: searchBox.stops,
    // complementary
    origins: location.origins,
    destinations: location.destinations
  }
}

export const _SearchBox = muiThemeable()(SearchBox)

export default connect(
  mapStateToProps,
  {
    toggleSearchBox: boundToggleSearchBox,
    toggleAdvancedSearch: boundToggleAdvancedSearch,
    // search vars
    changeFlightType: boundChangeFlightType,
    addFlight: boundAddFlight,
    removeFlight: boundRemoveFlight,
    selectOrigin: boundSelectOrigin,
    selectDestination: boundSelectDestination,
    selectDateRange: boundSelectDateRange,
    changeAdults: boundChangeAdults,
    changeSeniors: boundChangeSeniors,
    changeDisabled: boundChangeDisabled,
    changeChildren: boundChangeChildren,
    changeChildrenAge: boundChangeChildrenAge,
    changeCabinType: boundChangeCabinType,
    changeBaggageType: boundChangeBaggageType,
    changeStops: boundChangeStops,
    // complementary
    fetchOrigins: boundFetchOrigins,
    fetchDestinations: boundFetchDestinations,
    clearLocations: boundClearLocations,
    showLocationsPlaceholder: boundShowLocationsPlaceholder,
    preloadLocations: boundPreloadLocations
  }
)(withTranslation()(_SearchBox))

SearchBox.propTypes = {
  open: PropTypes.bool.isRequired,
  openAdvanced: PropTypes.bool.isRequired,
  // search vars
  flightType: PropTypes.oneOf(constants.FLIGHT_TYPES),
  flights: PropTypes.array.isRequired,
  adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  seniors: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  childrenAges: PropTypes.object.isRequired,
  cabinType: PropTypes.string.isRequired,
  baggageType: PropTypes.string.isRequired,
  stops: PropTypes.number.isRequired,
  // complementary
  origins: PropTypes.array.isRequired,
  destinations: PropTypes.array.isRequired,

  // actions: main
  onSubmitSearch: PropTypes.func.isRequired,
  toggleSearchBox: PropTypes.func.isRequired,
  toggleAdvancedSearch: PropTypes.func.isRequired,
  // actions: search vars
  changeFlightType: PropTypes.func.isRequired,
  addFlight: PropTypes.func.isRequired,
  removeFlight: PropTypes.func.isRequired,
  selectOrigin: PropTypes.func.isRequired,
  selectDestination: PropTypes.func.isRequired,
  selectDateRange: PropTypes.func.isRequired,
  changeAdults: PropTypes.func.isRequired,
  changeSeniors: PropTypes.func.isRequired,
  changeDisabled: PropTypes.func.isRequired,
  changeChildren: PropTypes.func.isRequired,
  changeChildrenAge: PropTypes.func.isRequired,
  changeCabinType: PropTypes.func.isRequired,
  changeStops: PropTypes.func.isRequired,
  // actions: complementary
  fetchOrigins: PropTypes.func.isRequired,
  fetchDestinations: PropTypes.func.isRequired,
  clearLocations: PropTypes.func.isRequired,
  preloadLocations: PropTypes.func.isRequired
}
