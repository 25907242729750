import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import MuiSelectField from "material-ui/SelectField"
import muiThemeable from "material-ui/styles/muiThemeable"

const Wrapper = styled.div`
  padding: 0;
  height: ${props => props.wrapperHeight}px !important;
  background-color: #ffffff !important;
  border: 1px solid ${props => (props.error ? props.theme.errorColor : props.theme.borderColor)};
  border-radius: 3px;
  width: 100%;

  > div {
    height: ${props => props.wrapperHeight - 2}px !important;
    font-size: 14px !important;

    > div > div {
      button {
        padding: 4px !important;
        width: 32px !important;
        height: ${props => props.wrapperHeight - 2}px !important;
        svg {
          width: 24px !important;
          height: 24px !important;
          color: ${props => props.theme.clickableColor} !important;
          fill: ${props => props.theme.clickableColor} !important;
        }
      }
      > div:nth-child(2) {
        height: ${props => props.wrapperHeight - 2}px !important;
        top: 0 !important;
        line-height: ${props => props.wrapperHeight - 2}px !important;
        padding-left: 8px !important;
        padding-right: 32px !important;
        white-space: normal !important;
      }
    }
  }
  hr {
    display: none;
  }

  .input-group-addon + & + &:last-child,
  .input-group-addon + &:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .input-group-addon + & + &:last-child {
    border-left: 0;
  }
  .input-group-addon + &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
`

class SelectField extends React.PureComponent {
  render() {
    let props = { ...this.props }
    delete props.height
    delete props.error
    delete props.muiTheme

    return (
      <Wrapper error={this.props.error} theme={this.props.muiTheme.palette} wrapperHeight={this.props.height}>
        <MuiSelectField {...props} />
      </Wrapper>
    )
  }
}

export default muiThemeable()(SelectField)

SelectField.propTypes = {
  error: PropTypes.bool,
  height: PropTypes.number
}

SelectField.defaultProps = {
  error: false,
  height: 34
}
