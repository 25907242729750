import React from "react"
import Icon from "components/commons/Icon"
const icon = {
  p: [
    "M699.733 273.067v-93.867c0-52.096-41.771-93.867-93.867-93.867h-187.733c-52.096 0-93.867 41.771-93.867 93.867v93.867h-281.6v610.133c0 52.096 41.771 93.867 93.867 93.867h750.933c52.096 0 93.867-41.771 93.867-93.867v-610.133h-281.6zM418.133 179.2h187.733v93.867h-187.733v-93.867zM371.2 836.267v-422.4l352 187.733-352 234.667z"
  ],
  a: [{ fill: "rgb(74, 74, 74)" }]
}
export default class Entertainment extends React.PureComponent {
  render() {
    return <Icon icon={icon} {...this.props} />
  }
}
